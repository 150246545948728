import { AxiosError } from 'axios';

import { APICallStatus, RIOThunkAction } from 'Models/common/types';
import { updateROSBagJob } from 'Models/deployments/service';
import {
  DependencyGraphData,
  Deployment,
  DeploymentAction,
  DeploymentHistory,
  DeploymentPhase,
  GetDeploymentDetailsPayload,
  UpdateROSBagJobParams,
} from 'Models/deployments/types';
import { setNotificationFail, setNotificationSuccess } from 'RioRedux/notifications/action';
import { DeploymentSpecification } from 'Root/models/deployPackage/types';
import { GetDeletePackagePayload, PackageSpecification } from 'Root/models/packages/types';

import DeploymentActionTypes from './actionTypes';

export const setCollectRosbagsStatus = (
  payload: APICallStatus,
): DeploymentAction<APICallStatus> => ({
  type: DeploymentActionTypes.SET_COLLECT_ROSBAGS_STATUS,
  payload,
});

export const setUpdateRosbagJobStatus = (
  payload: APICallStatus,
): DeploymentAction<APICallStatus> => ({
  type: DeploymentActionTypes.SET_UPDATE_ROSBAG_JOB_STATUS,
  payload,
});

export const collectROSBags =
  (params: UpdateROSBagJobParams): RIOThunkAction =>
  async (dispatch) => {
    try {
      dispatch(setCollectRosbagsStatus(APICallStatus.LOADING));
      await updateROSBagJob(params);

      dispatch(
        setNotificationSuccess({
          message: 'Initiated collection of rosbags',
          description: 'Collected files will be available once they are uploaded.',
        }),
      );
      dispatch(setCollectRosbagsStatus(APICallStatus.LOADED));
    } catch (error) {
      dispatch(setCollectRosbagsStatus(APICallStatus.ERROR));
      dispatch(
        setNotificationFail({
          message: 'Failed to collect rosbags',
          description: (error as AxiosError).message,
        }),
      );
    }
  };

export const updateROSBagJobUploadMode =
  (params: UpdateROSBagJobParams): RIOThunkAction =>
  async (dispatch) => {
    try {
      dispatch(setUpdateRosbagJobStatus(APICallStatus.LOADING));
      await updateROSBagJob(params);

      dispatch(
        setNotificationSuccess({
          message: 'Successfully updated the upload mode',
        }),
      );
      dispatch(setUpdateRosbagJobStatus(APICallStatus.LOADED));
    } catch (error) {
      dispatch(setUpdateRosbagJobStatus(APICallStatus.ERROR));
      dispatch(
        setNotificationFail({
          message: 'Failed to update the upload mode',
          description: (error as AxiosError).message,
        }),
      );
    }
  };

export const getDeploymentList = (
  payload: DeploymentPhase[],
): DeploymentAction<DeploymentPhase[]> => ({
  type: DeploymentActionTypes.GET_DEPLOYMENT_LIST,
  payload,
});

export const setDeploymentList = (payload: Deployment[]): DeploymentAction<Deployment[]> => ({
  type: DeploymentActionTypes.SET_DEPLOYMENT_LIST,
  payload,
});

export const setDeploymentListAPICallStatus = (
  payload: APICallStatus,
): DeploymentAction<APICallStatus> => ({
  type: DeploymentActionTypes.SET_DEPLOYMENT_LIST_API_STATUS,
  payload,
});

export const deleteDeployment = (payload: string): DeploymentAction<string> => ({
  type: DeploymentActionTypes.DELETE_DEPLOYMENT,
  payload,
});

export const getDeploymentDetails = (
  payload: GetDeploymentDetailsPayload,
): DeploymentAction<GetDeploymentDetailsPayload> => ({
  type: DeploymentActionTypes.GET_DEPLOYMENT_DETAILS,
  payload,
});

export const setDeploymentDetails = (
  payload: DeploymentSpecification | null,
): DeploymentAction<DeploymentSpecification | null> => ({
  type: DeploymentActionTypes.SET_DEPLOYMENT_DETAILS,
  payload,
});

export const setDeploymentDetailsAPICallStatus = (
  payload: APICallStatus,
): DeploymentAction<APICallStatus> => ({
  type: DeploymentActionTypes.SET_DEPLOYMENT_DETAILS_API_STATUS,
  payload,
});

export const restartDeployment = (payload: string): DeploymentAction<string> => ({
  type: DeploymentActionTypes.RESTART_DEPLOYMENT,
  payload,
});

export const downloadDeploymentManifest = (
  payload: GetDeploymentDetailsPayload,
): DeploymentAction<GetDeploymentDetailsPayload> => ({
  type: DeploymentActionTypes.DOWNLOAD_DEPLOYMENT_MANIFEST,
  payload,
});

export const setDownloadDeploymentManifestAPICallStatus = (
  payload: APICallStatus,
): DeploymentAction<APICallStatus> => ({
  type: DeploymentActionTypes.SET_DOWNLOAD_DEPLOYMENT_MANIFEST_API_STATUS,
  payload,
});

export const getDeploymentHistory = (
  payload: GetDeploymentDetailsPayload,
): DeploymentAction<GetDeploymentDetailsPayload> => ({
  type: DeploymentActionTypes.GET_DEPLOYMENT_HISTORY,
  payload,
});

export const setDeploymentHistory = (
  payload: DeploymentHistory[],
): DeploymentAction<DeploymentHistory[]> => ({
  type: DeploymentActionTypes.SET_DEPLOYMENT_HISTORY,
  payload,
});

export const setDeploymentHistoryAPICallStatus = (
  payload: APICallStatus,
): DeploymentAction<APICallStatus> => ({
  type: DeploymentActionTypes.SET_DEPLOYMENT_HISTORY_API_STATUS,
  payload,
});

export const getPackageDetails = (
  payload: GetDeletePackagePayload,
): DeploymentAction<GetDeletePackagePayload> => ({
  type: DeploymentActionTypes.GET_PACKAGE_DETAILS,
  payload,
});

export const setPackageDetails = (
  payload: PackageSpecification | null,
): DeploymentAction<PackageSpecification | null> => ({
  type: DeploymentActionTypes.SET_PACKAGE_DETAILS,
  payload,
});

export const setPackageDetailsAPICallStatus = (
  payload: APICallStatus,
): DeploymentAction<APICallStatus> => ({
  type: DeploymentActionTypes.SET_PACKAGE_DETAILS_API_STATUS,
  payload,
});

export const getDependencyGraphData = (payload: string): DeploymentAction<string> => ({
  type: DeploymentActionTypes.GET_DEPENDENCY_GRAPH_DATA,
  payload,
});

export const setDependencyGraphData = (
  payload: DependencyGraphData | null,
): DeploymentAction<DependencyGraphData | null> => ({
  type: DeploymentActionTypes.SET_DEPENDENCY_GRAPH_DATA,
  payload,
});

export const setDependencyGraphDataAPICallStatus = (
  payload: APICallStatus,
): DeploymentAction<APICallStatus> => ({
  type: DeploymentActionTypes.SET_DEPENDENCY_GRAPH_DATA_API_STATUS,
  payload,
});
