import React, { Component, ErrorInfo, ReactNode } from 'react';
import Raven from 'raven-js';

import { IS_DEV_ENV, UI_VERSION } from 'Shared/config';

import {
  CHUNK_LOAD_ERROR,
  RESIZE_OBSERVER_LOOP_COMPLETED,
  RESIZE_OBSERVER_LOOP_LIMIT,
} from './SentryErrorBoundry.constants';

interface Props {
  children: ReactNode;
}

interface State {
  error: Error | null;
}

const resizeObserverErrors: string[] = [RESIZE_OBSERVER_LOOP_LIMIT, RESIZE_OBSERVER_LOOP_COMPLETED];

class SentryErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // To reload page when a new build is generated and chunk url might have changed
    if (error && error.name === CHUNK_LOAD_ERROR) {
      window.location.reload();
    } else if (error && resizeObserverErrors.includes(error.message)) {
      // ignore ResizeObserver errors
    } else {
      this.setState({ error });

      if (!IS_DEV_ENV) {
        Raven.captureException(error, {
          extra: errorInfo,
          tags: {
            version: UI_VERSION,
          },
        });
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    const { error } = this.state;

    if (error) {
      return (
        <div id="centeredErrorBlock">
          <p>We&apos;re sorry — something&apos;s gone wrong.</p>
          <p>
            Our team has been notified, but&nbsp;
            <button onClick={() => Raven.lastEventId() && Raven.showReportDialog()}>
              click here
            </button>{' '}
            to fill out a report.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default SentryErrorBoundary;
