import { createBrowserHistory } from 'history';
import qs from 'qs';

const getSearchString = (queryStr) => (queryStr?.startsWith('?') ? queryStr.slice(1) : queryStr);

const pickQueryParameters = (searchObject, queryParameters) =>
  queryParameters.reduce(
    (acc, param) => ({
      ...acc,
      ...(searchObject[param] && { [param]: searchObject[param] }),
    }),
    {},
  );

const preserveQueryParameters = (path, queryParameters) => {
  const searchObject = qs.parse(getSearchString(window.location.search));

  return typeof path === 'object'
    ? {
        ...path,
        search: `?${qs.stringify({
          ...qs.parse(getSearchString(path?.search)),
          ...pickQueryParameters(searchObject, queryParameters),
        })}`,
      }
    : {
        pathname: path,
        search: `?${qs.stringify({
          ...pickQueryParameters(searchObject, queryParameters),
        })}`,
      };
};

const preserveQueryHistory = (queryParameters) => {
  const browserHistory = createBrowserHistory();
  const { push: originalPush, replace: originalReplace } = browserHistory;
  browserHistory.push = (path, state) => {
    originalPush(preserveQueryParameters(path, queryParameters), state);
  };
  browserHistory.replace = (path, state) => {
    originalReplace(preserveQueryParameters(path, queryParameters), state);
  };
  return browserHistory;
};

const history = preserveQueryHistory(['org', 'p']);

export default history;
