import {
  APICallStatus,
  AuthTokenFormData,
  CommonAction,
  CopyToClipboardPayload,
  TrackFnParam,
  TrackTypes,
  UpdateProjectsAndSelectedProjectPayload,
  User,
  UserMap,
  UserUpdateProps,
} from 'Models/common/types';
import { ProjectUser } from 'Models/projects/types';
import { trackTiming } from 'Shared/utils/analytics';
import { subtractDates } from 'Shared/utils/core/date';

import ActionTypes from './actionTypes';

export const trackEvent = (
  category: string,
  event: string,
  startDate: Date,
  type: TrackTypes,
): void => {
  const delta = subtractDates(new Date(), startDate);
  trackTiming(category, event, delta, type);
};

export const trackSuccess = ({ category, event, startDate }: TrackFnParam): void => {
  trackEvent(category, event, startDate, TrackTypes.SUCCESS);
};

export const trackError = ({ category, event, startDate }: TrackFnParam): void => {
  trackEvent(category, event, startDate, TrackTypes.ERROR);
};

export const saveUserDetails = (payload: User): CommonAction<User> => ({
  type: ActionTypes.SAVE_USER_DETAILS,
  payload,
});

export const updateUserDetails = (payload: User): CommonAction<User> => ({
  type: ActionTypes.UPDATE_USER_DETAILS,
  payload,
});

export const setUserMap = (payload: ProjectUser[]): CommonAction<ProjectUser[]> => ({
  type: ActionTypes.SET_USER_MAP,
  payload,
});

export const updateUserMap = (payload: UserMap): CommonAction<UserMap> => ({
  type: ActionTypes.UPDATE_USER_MAP,
  payload,
});

export const setAPICallStatus = (payload: APICallStatus): CommonAction<APICallStatus> => ({
  type: ActionTypes.SET_COMMON_API_STATUS,
  payload,
});

export const setErrorMessage = (payload: string): CommonAction<string> => ({
  type: ActionTypes.SET_COMMON_ERROR_MESSAGE,
  payload,
});

export const setNewUserFlag = (payload: boolean): CommonAction<boolean> => ({
  type: ActionTypes.SET_NEW_USER_FLAG,
  payload,
});

export const setShowBanner = (payload: boolean): CommonAction<boolean> => ({
  type: ActionTypes.SET_SHOW_BANNER,
  payload,
});

export const getUserDetails = (): CommonAction => ({
  type: ActionTypes.GET_USER_DETAILS,
});

export const hideBanner = (): CommonAction => ({
  type: ActionTypes.HIDE_BANNER,
});

export const copyToClipboard = (
  content: string,
  title: string,
  showNotification = true,
): CommonAction<CopyToClipboardPayload> => ({
  type: ActionTypes.COPY_TEXT,
  payload: {
    content,
    title,
    showNotification,
  },
});

export const updateSelectedOrg = (payload: string): CommonAction<string> => ({
  type: ActionTypes.UPDATE_SELECTED_ORG,
  payload,
});

export const setUpdateUserApiStatus = (payload: APICallStatus): CommonAction<APICallStatus> => ({
  type: ActionTypes.SET_UPDATE_USER_API_STATUS,
  payload,
});

export const updateUser = (payload: UserUpdateProps): CommonAction<UserUpdateProps> => ({
  type: ActionTypes.UPDATE_USER_INFO,
  payload,
});

export const updateUserFulfilled = (payload: User): CommonAction<User> => ({
  type: ActionTypes.UPDATE_USER_DETAILS_FULFILLED,
  payload,
});

export const changeSelectedOrg = (payload: string): CommonAction<string> => ({
  type: ActionTypes.CHANGE_SELECTED_ORG,
  payload,
});

export const initPendo = (payload: string): CommonAction<string> => ({
  type: ActionTypes.INIT_PENDO,
  payload,
});

export const changeSelectedProject = (payload: string): CommonAction<string> => ({
  type: ActionTypes.CHANGE_SELECTED_PROJECT,
  payload,
});

export const updateSelectedProject = (payload: string): CommonAction<string> => ({
  type: ActionTypes.UPDATE_SELECTED_PROJECT,
  payload,
});

export const getAuthToken = (payload: AuthTokenFormData): CommonAction<AuthTokenFormData> => ({
  type: ActionTypes.GET_AUTH_TOKEN,
  payload,
});

export const setAuthTokenAPIStatus = (payload: APICallStatus): CommonAction<APICallStatus> => ({
  type: ActionTypes.SET_AUTH_TOKEN_API_STATUS,
  payload,
});

export const setAuthTokenAPIError = (payload: string): CommonAction<string> => ({
  type: ActionTypes.SET_AUTH_TOKEN_API_ERROR,
  payload,
});

export const updateAuthToken = (payload: string): CommonAction<string> => ({
  type: ActionTypes.UPDATE_AUTH_TOKEN,
  payload,
});

export const updateProjectsAndSelectedProject = (
  payload: UpdateProjectsAndSelectedProjectPayload,
): CommonAction<UpdateProjectsAndSelectedProjectPayload> => ({
  type: ActionTypes.UPDATE_PROJECTS_AND_SELECTED_PROJECT,
  payload,
});
