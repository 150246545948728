import { ForkEffect, spawn } from 'redux-saga/effects';

import cliManifestSaga from './cliManifest/saga';
import commonSaga from './common/saga';
import configurationsSaga from './configurations/saga';
import deploymentSaga from './deployments/saga';
import deployPackageSaga from './deployPackage/saga';
import marketplaceSaga from './marketplace/saga';
import metricsSaga from './metrics/saga';
import NetworSaga from './network/saga';
import notificationSaga from './notifications/saga';
import organizationSaga from './organization/saga';
import packagesSaga from './packages/saga';
import projectsSaga from './projects/saga';
import secretsSaga from './secrets/saga';
import staticRoutesSaga from './staticRoutes/saga';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* rootSaga(): IterableIterator<ForkEffect<any>> {
  yield spawn(commonSaga);
  yield spawn(configurationsSaga);
  yield spawn(marketplaceSaga);
  yield spawn(notificationSaga);
  yield spawn(organizationSaga);
  yield spawn(staticRoutesSaga);
  yield spawn(secretsSaga);
  yield spawn(metricsSaga);
  yield spawn(projectsSaga);
  yield spawn(cliManifestSaga);
  yield spawn(packagesSaga);
  yield spawn(NetworSaga);
  yield spawn(deployPackageSaga);
  yield spawn(deploymentSaga);
}
