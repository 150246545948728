import { APICallStatus } from 'Root/models/common/types';
import { DeviceCondensed } from 'Root/models/devices/types';
import { CreateNetworkPayload, Network, NetworkAction } from 'Root/models/networks/types';

import NetworkActionTypes from './actionTypes';

export const getNetworkList = (payload: string[]): NetworkAction<string[]> => ({
  type: NetworkActionTypes.GET_NETWORK_LIST,
  payload,
});

export const setNetworkList = (payload: Network[]): NetworkAction<Network[]> => ({
  type: NetworkActionTypes.SET_NETWORK_LIST,
  payload,
});

export const setNetworkListAPICallStatus = (
  payload: APICallStatus,
): NetworkAction<APICallStatus> => ({
  type: NetworkActionTypes.SET_NETWORK_LIST_API_STATUS,
  payload,
});

export const setAddNetworkVisibility = (payload: boolean): NetworkAction<boolean> => ({
  type: NetworkActionTypes.SET_ADD_NETWORK_VISIBILITY,
  payload,
});

export const setAddNetworkAPICallStatus = (
  payload: APICallStatus,
): NetworkAction<APICallStatus> => ({
  type: NetworkActionTypes.SET_ADD_NETWORK_API_STATUS,
  payload,
});

export const addNetwork = (payload: CreateNetworkPayload): NetworkAction<CreateNetworkPayload> => ({
  type: NetworkActionTypes.ADD_NETWORK,
  payload,
});

export const getDeviceList = (): NetworkAction => ({
  type: NetworkActionTypes.GET_DEVICES_LIST,
});

export const setDeviceList = (payload: DeviceCondensed[]): NetworkAction<DeviceCondensed[]> => ({
  type: NetworkActionTypes.SET_DEVICES_LIST,
  payload,
});

export const setDeviceListAPICallStatus = (
  payload: APICallStatus,
): NetworkAction<APICallStatus> => ({
  type: NetworkActionTypes.SET_DEVICES_LIST_API_STATUS,
  payload,
});

export const getNetworkDetails = (payload: string): NetworkAction<string> => ({
  type: NetworkActionTypes.GET_NETWORK_DETAILS,
  payload,
});

export const setNetworkDetails = (payload: Network | null): NetworkAction<Network | null> => ({
  type: NetworkActionTypes.SET_NETWORK_DETAILS,
  payload,
});

export const setNetworkDetailsAPICallStatus = (
  payload: APICallStatus,
): NetworkAction<APICallStatus> => ({
  type: NetworkActionTypes.SET_NETWORK_DETAILS_API_STATUS,
  payload,
});

export const deleteNetwork = (payload: string): NetworkAction<string> => ({
  type: NetworkActionTypes.DELETE_NETWORK,
  payload,
});

export const downloadNetworkManifest = (payload: string): NetworkAction<string> => ({
  type: NetworkActionTypes.DOWNLOAD_NETWORK_MANIFEST,
  payload,
});

export const setDownloadNetworkManifestAPICallStatus = (
  payload: APICallStatus,
): NetworkAction<APICallStatus> => ({
  type: NetworkActionTypes.SET_DOWNLOAD_NETWORK_MANIFEST_API_STATUS,
  payload,
});
