enum MetricsActionTypes {
  GET_DEVICE_METRICS = 'METRICS/GET_DEVICE_METRICS',
  UPDATE_DEVICE_METRICS = 'METRICS/UPDATE_DEVICE_METRICS',
  RESET_DEVICE_METRICS = 'METRICS/RESET_DEVICE_METRICS',

  GET_CLOUD_METRICS = 'METRICS/GET_CLOUD_METRICS',
  UPDATE_CLOUD_METRICS = 'METRICS/UPDATE_CLOUD_METRICS',
  RESET_CLOUD_METRICS = 'METRICS/RESET_CLOUD_METRICS',
}

export default MetricsActionTypes;
