import { RunTime } from '../common/types';
import { DeploymentSpecification } from '../deployPackage/types';

import {
  CreatePackageSpecification,
  ExecutableDocker,
  Executables,
  ExecutablesType,
  FormData,
  LivenessType,
  PackageDeploymentList,
  PackageList,
  PackageSpecification,
} from './types';

export const transformPackgesList = (packagesList: PackageSpecification[]): PackageList[] =>
  packagesList.map((packg) => ({
    name: packg.metadata.name,
    version: packg.metadata.version,
    createdAt: packg.metadata.createdAt,
    guid: packg.metadata.guid,
    isRos: packg.spec.ros?.enabled || false,
    packageDetails: packg,
  }));

const transformDockerData = (data: ExecutableDocker): ExecutableDocker => {
  const { privateImage, pullSecret, ...otherDockerData } = data;
  const transformedDockerData = { ...(privateImage && { pullSecret }), ...otherDockerData };
  return transformedDockerData;
};

const transformExecutables = (executables: Executables[]): Executables[] =>
  executables.map((executable) => {
    const { isLivenessProbe, livenessProbe, command, docker, runAsBash, limits, ...otherData } =
      executable;
    const transformedCommand = runAsBash
      ? (['/bin/bash', '-c', command] as string[])
      : ([command] as string[]);
    const isDockerType = executable.type === ExecutablesType.Docker;
    const transformedDockerData = transformDockerData(docker as ExecutableDocker);
    if (isLivenessProbe && livenessProbe) {
      const isExecCommandPresent =
        livenessProbe.livenessType === LivenessType.Exec && livenessProbe.exec?.command;
      const transformedExecCommand =
        typeof livenessProbe.exec?.command === 'string'
          ? livenessProbe.exec?.command.split(' ')
          : [];
      const { livenessType, httpGet, tcpSocket, exec, ...livenessData } = livenessProbe;
      return {
        livenessProbe: {
          ...livenessData,
          ...(isExecCommandPresent && {
            exec: {
              command: transformedExecCommand,
            },
          }),
          ...(livenessType === LivenessType.HttpGet && {
            httpGet,
          }),
          ...(livenessType === LivenessType.TcpSocket && {
            tcpSocket,
          }),
        },
        ...(isDockerType && {
          docker: transformedDockerData,
          limits,
        }),
        ...(command && {
          command: transformedCommand,
        }),

        ...otherData,
      };
    }
    return {
      ...(command && {
        command: transformedCommand,
      }),
      ...(isDockerType && {
        docker: transformedDockerData,
        limits,
      }),

      ...otherData,
    };
  });

export const transFormCreatePackageData = (data: FormData): CreatePackageSpecification => {
  const payload: CreatePackageSpecification = {
    metadata: data.metaData,
    spec: {
      runtime: data.spec.runtime,
      executables: transformExecutables(data.executables),
      ...(data.spec.runtime === RunTime.CLOUD && { cloud: { replicas: data.spec.replicas } }),
      ...(data.spec.runtime === RunTime.DEVICE && {
        device: {
          arch: data.spec.arch,
          restart: data.spec.restart,
        },
      }),
      ros: {
        enabled: data.spec.ros,
        ...(data.spec.ros && { version: data.spec.version }),
        ...(data.rosEndpoints && { rosEndpoints: data.rosEndpoints }),
      },
      ...(data.endpoints && { endpoints: data.endpoints }),
      ...(data.rosBagJobs && { rosBagJobs: data.rosBagJobs }),
      ...(data.environmentVars && { environmentVars: data.environmentVars }),
    },
  };
  return payload;
};

/**
 * Determines the type of liveness probe based on the provided liveness probe configuration.
 * @param livenessProbe The liveness probe configuration.
 * @returns The type of liveness probe.
 */
function getLivenessType(livenessProbe: Executables['livenessProbe']): LivenessType {
  if (livenessProbe?.httpGet) {
    return LivenessType.HttpGet;
  }
  if (livenessProbe?.exec) {
    return LivenessType.Exec;
  }
  if (livenessProbe?.tcpSocket) {
    return LivenessType.TcpSocket;
  }
  return LivenessType.HttpGet;
}

const transformDetailsExecutables = (executables: Executables[]): Executables[] =>
  executables.map((executable) => {
    const { livenessProbe, command, docker, ...otherData } = executable;
    const isLivenessProbe = livenessProbe && Object.keys(livenessProbe).length > 0;
    const isBash = Array.isArray(command) && command?.length > 2;
    const transformedCommand = isBash ? command[command.length - 1] : (command && command[0]) ?? '';
    const isDockerType = executable.type === ExecutablesType.Docker;
    const transformedDockerData = docker && {
      privateImage: Boolean(docker.pullSecret?.depends.nameOrGUID),
      ...docker,
    };
    if (isLivenessProbe && livenessProbe) {
      const isExecCommandPresent =
        livenessProbe.exec?.command && livenessProbe.exec?.command.length > 0;

      const transformedExecCommand = Array.isArray(livenessProbe.exec?.command)
        ? livenessProbe.exec?.command.join(' ')
        : '';
      const { exec, ...otherLivenessData } = livenessProbe;
      return {
        isLivenessProbe,
        livenessProbe: {
          livenessType: getLivenessType(livenessProbe),
          ...otherLivenessData,
          ...(isExecCommandPresent && {
            exec: {
              command: transformedExecCommand,
            },
          }),
        },

        command: transformedCommand,
        ...(isDockerType && { docker: transformedDockerData }),
        runAsBash: isBash,
        ...otherData,
      };
    }
    return {
      isLivenessProbe,
      ...(isDockerType && { docker: transformedDockerData }),
      command: transformedCommand,
      runAsBash: isBash,
      ...otherData,
    };
  });

export const transformPackageDetailToFormData = ({
  metadata,
  spec,
}: PackageSpecification): FormData => {
  const formData: FormData = {
    metaData: {
      name: metadata.name,
      version: metadata.version,
      description: metadata?.description || '',
    },
    spec: {
      runtime: spec.runtime,
      ros: spec.ros.enabled || false,
      replicas: spec.cloud?.replicas,
      arch: spec.device?.arch,
      restart: spec.device?.restart,
      version: spec.ros.version,
    },
    executables: transformDetailsExecutables(spec.executables),
    ...(spec.environmentVars && { environmentVars: spec.environmentVars }),
    ...(spec.endpoints && { endpoints: spec.endpoints }),
    ...(spec.ros.rosEndpoints && { rosEndpoints: spec.ros.rosEndpoints }),
    ...(spec.rosBagJobs && { rosBagJobs: spec.rosBagJobs }),
  };
  return formData;
};

export const transformPackgeDeploymentListData = (
  deploymentData: DeploymentSpecification[],
): PackageDeploymentList[] =>
  deploymentData.map((data) => ({
    name: data.metadata.name,
    started: data.metadata.createdAt,
    phase: data.status.phase,
    deploymentId: data.metadata.guid,
  }));
