import ActionTypes from 'RioRedux/notifications/actionTypes';

import { AppAction } from '../common/types';

export interface NotificationAction<NotificationPayload> {
  type: ActionTypes;
  payload?: NotificationPayload;
}

export enum NotificationType {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
}

export interface NotificationState {
  showNotification: boolean;
  type: NotificationType;
  message?: string;
  description?: string;
  key?: string;
  buttonText?: string;
  buttonAction?: () => AppAction;
}

export enum DefaultMessages {
  ERROR_HEADING = 'Something went wrong!',
  ERROR_DESCRIPTION = 'Please check the docs or contact support for more info.',
}

export type NotificationPayload = {
  message?: string;
  description?: string;
  key?: string;
  buttonText?: string;
  buttonAction?: () => AppAction;
};
