import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Select } from 'antd';
import classNames from 'classnames';

import { AppState } from 'Models/common/types';
import { Device } from 'Models/devices/types';
import { getDevices, setSelectedDeviceID } from 'RioRedux/devices/actions';

import './styles.scss';

interface Props {
  className?: string;
  devices: Device[];
  selectedDeviceID: string | null;
  getDevices: VoidFunction;
  setSelectedDeviceID: typeof setSelectedDeviceID;
}

const rootClassName = 'rio-embed-device-selector';

const EmbedDeviceSelector: React.FC<Props> = (props) => {
  const pathRef = useRef<string[]>([]);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!props.devices.length) {
      props.getDevices();
    }
  }, [location.pathname, props.devices]);

  useEffect(() => {
    if (props.selectedDeviceID) {
      pathRef.current = location.pathname.split(props.selectedDeviceID);
    }
  }, [props.selectedDeviceID]);

  const handleDeviceChange = (deviceID: string) => {
    if (deviceID) {
      props.setSelectedDeviceID(deviceID);
      history.push({
        pathname: pathRef.current.join(deviceID),
        search: location.search,
      });
    }
  };

  return (
    <div className={classNames(rootClassName, props.className)}>
      <Select
        className={`${rootClassName}__select`}
        value={props.selectedDeviceID ?? undefined}
        onChange={handleDeviceChange}
      >
        {props.devices.map((device) => (
          <Select.Option value={device.uuid}>
            <div>{device.name}</div>
            <div>{device.uuid}</div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

const mapStateToProps = ({ devices }: AppState) => ({
  devices: devices.list,
  selectedDeviceID: devices.selectedDeviceID,
});

const boundActionCreators = {
  getDevices,
  setSelectedDeviceID,
};

export default connect(mapStateToProps, boundActionCreators)(EmbedDeviceSelector);
