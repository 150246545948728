import { VPN } from 'Shared/types';

import { APIError, RIOError } from '../common/types';
import { mapAPIOrgToOrg } from '../organization/dataFormatters';

import {
  APIProject,
  CreateProjectAPIPayload,
  CreateProjectPayload,
  Project,
  UserAPIProject,
  UserProject,
} from './types';

export const getErrorMessage = (error: APIError): string =>
  error.response?.data?.error ?? error.message;

export const getErrorObj = (err: APIError): RIOError => ({
  status: err.response?.status,
  message: getErrorMessage(err),
});

export const getProjectCreationErrorMessage = (error: APIError): string => {
  const status = error.response?.status;
  if (status === 409) {
    return 'A project already exists with the given name. Please choose another name';
  }
  return getErrorMessage(error);
};

export const mapCreateProjectFormDataToAPI = (
  formData: CreateProjectPayload,
): CreateProjectAPIPayload => {
  const { name, organization, users, userGroups, vpn, subnets, labels } = formData;
  const subnetsArray = subnets?.map((subnet) => subnet.trim());
  const payload: CreateProjectAPIPayload = {
    metadata: {
      name,
      organizationGUID: organization.guid,
      labels,
    },
    spec: {
      features: {
        vpn: {
          enabled: vpn === VPN.ENABLED,
          subnets: subnetsArray,
        },
      },
    },
  };

  if (users?.[0]) {
    const usersData = users.map((user) => ({
      emailID: user.emailID,
      role: user.role,
    }));

    payload.spec = {
      ...payload.spec,
      users: usersData,
    };
  }

  if (userGroups?.[0]) {
    const usersGroupsData = userGroups.map((userGroup) => ({
      name: userGroup.name,
      role: userGroup.role,
    }));

    payload.spec = {
      ...payload.spec,
      userGroups: usersGroupsData,
    };
  }

  return payload;
};

export const mapUserAPIProjectDataToProject = (apiData: UserAPIProject): UserProject => ({
  id: apiData.ID,
  name: apiData.name,
  guid: apiData.guid,
  creator: apiData.creator,
  users: apiData.users,
  createdAt: apiData.CreatedAt,
  deletedAt: apiData.DeletedAt,
  updatedAt: apiData.UpdatedAt,
  organization: apiData?.organization && mapAPIOrgToOrg(apiData.organization),
  groups: apiData?.groups,
});

export const mapAPIProjectDataToProject = (apiData: APIProject): Project => ({
  name: apiData.metadata.name,
  guid: apiData.metadata.projectGUID,
  creator: apiData.metadata.creatorGUID,
  createdAt: apiData.metadata.createdAt,
  updatedAt: apiData.metadata.updatedAt,
  organization: apiData.metadata.organizationGUID,
  users: apiData.spec?.users ?? [],
  groups: apiData.spec?.userGroups ?? [],
  isVpnEnabled: apiData.spec?.features.vpn.enabled,
  projectStatus: apiData.status.status,
  vpnStatus: apiData.status.vpn,
  subnets: apiData.spec?.features.vpn.subnets,
  labels: apiData.metadata.labels,
});

export const mapAPIProjectsListToProjectsList = (apiList?: APIProject[]): Project[] =>
  apiList?.map(mapAPIProjectDataToProject) ?? [];

/* The format of project (object) returned from v2 APIs and user/ user group APIs
 is little different hence having different mappers */

export const mapUserAPIProjectsListToProjectsList = (apiList?: UserAPIProject[]): UserProject[] =>
  apiList?.map(mapUserAPIProjectDataToProject) ?? [];
