import Cookies from 'js-cookie';

import {
  APICallStatus,
  CommonAction,
  CommonActionPayload,
  CommonState,
  EntityState,
  UpdateProjectsAndSelectedProjectPayload,
  User,
  UserMap,
  UserRoles,
  UserType,
} from 'Models/common/types';
import { Project, ProjectUser } from 'Models/projects/types';
import { getUrlParameter } from 'Shared/utils/common';

import ActionTypes from './actionTypes';

const projectGUID = getUrlParameter('p') || Cookies.get('project') || '';
const orgGUID = getUrlParameter('org') || Cookies.get('organization') || '';

export const initialState: CommonState = {
  userToken: Cookies.get('token') || '',
  authToken: '',
  authTokenAPIStatus: APICallStatus.INITIAL,
  authTokenAPIError: '',
  isNewUser: false,
  apiStatus: APICallStatus.INITIAL,
  userType: UserType.PENDING,
  username: '',
  userDetails: {
    guid: '',
    firstName: '',
    lastName: '',
    emailID: '',
    state: EntityState.ACTIVATED,
    roleInOrganization: UserRoles.ADMIN,
    projects: [],
    featuresEnabled: [],
    userStateInOrgs: [],
  },
  userMap: {},
  selectedProjectGUID: projectGUID,
  selectedOrgGUID: orgGUID,
  featuresEnabled: [],
  errorMessage: '',
  showBanner: false,
  updateUserAPIStatus: APICallStatus.INITIAL,
};

const commonReducer = (
  state: CommonState = initialState,
  action: CommonAction<CommonActionPayload>,
): CommonState => {
  switch (action.type) {
    case ActionTypes.UPDATE_USER_DETAILS: {
      const payload = action.payload as User;

      const payloadUserMap: ProjectUser = {
        userGUID: payload.guid,
        emailID: payload.emailID,
        firstName: payload.firstName,
        lastName: payload.lastName,
      };

      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...payload,
        },
        username: `${payload.firstName} ${payload.firstName}`,
        userMap: {
          ...state.userMap,
          [payloadUserMap.userGUID]: payloadUserMap,
        },
        featuresEnabled: payload.featuresEnabled ?? [],
      };
    }
    case ActionTypes.SET_NEW_USER_FLAG: {
      const payload = action.payload as boolean;
      return {
        ...state,
        isNewUser: payload,
      };
    }

    case ActionTypes.UPDATE_USER_MAP: {
      return {
        ...state,
        userMap: action.payload as UserMap,
      };
    }

    case ActionTypes.UPDATE_SELECTED_ORG: {
      const payload = action.payload as string;
      return {
        ...state,
        selectedOrgGUID: payload as string,
      };
    }

    case ActionTypes.UPDATE_SELECTED_PROJECT: {
      const payload = action.payload as string;
      return {
        ...state,
        selectedProjectGUID: payload as string,
      };
    }

    case ActionTypes.PROJECTS_GET_FULFILLED: {
      const payload = action.payload as Project[];

      const tempUserMap = { ...state.userMap };

      payload.forEach((project) => {
        project.users?.forEach((user: ProjectUser) => {
          tempUserMap[user.userGUID] = user;
        });
      });

      return {
        ...state,
        userMap: tempUserMap,
      };
    }

    case ActionTypes.SET_SHOW_BANNER: {
      return {
        ...state,
        showBanner: action.payload as boolean,
      };
    }

    case ActionTypes.UPDATE_USER_DETAILS_FULFILLED: {
      const payload = action.payload as User;
      const { firstName, lastName, emailID } = payload;

      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          firstName,
          lastName,
          emailID,
        },
      };
    }

    case ActionTypes.SET_UPDATE_USER_API_STATUS: {
      return {
        ...state,
        updateUserAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.SET_AUTH_TOKEN_API_STATUS: {
      return {
        ...state,
        authTokenAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.SET_AUTH_TOKEN_API_ERROR: {
      return {
        ...state,
        authTokenAPIError: action.payload as string,
      };
    }

    case ActionTypes.UPDATE_AUTH_TOKEN: {
      return {
        ...state,
        authToken: action.payload as string,
      };
    }

    case ActionTypes.UPDATE_PROJECTS_AND_SELECTED_PROJECT: {
      return {
        ...state,
        selectedProjectGUID: (action.payload as UpdateProjectsAndSelectedProjectPayload)
          .selectedProjectGUID,
      };
    }

    default:
      return state;
  }
};

export default commonReducer;
