import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects';

import { APICallStatus, AppState } from 'Models/common/types';
import { NotificationPayload } from 'Models/notifications/types';
import {
  createPackage as createPackageAPI,
  deletePackage as deletePackageAPI,
  getPackageDeploymentList as getPackageDeploymentListAPI,
  getPackageDetails as getPackageDetailsAPI,
  getPackages as getPackagesAPI,
} from 'Models/packages/service';
import {
  CreatePackagePayload,
  GetDeletePackagePayload,
  GetPackageDeploymentListPayload,
  PackagesAction,
} from 'Models/packages/types';
import { downloadYAML } from 'RioRedux/cliManifest/actions';
import { getManifest } from 'Root/models/cliManifest/dataFormatters';
import { ManifestKinds } from 'Root/models/cliManifest/types';
import { getSecretsApi } from 'Root/models/secrets/service';
import { Secret } from 'Root/models/secrets/types';
import { getMessageFromError } from 'Shared/utils/common';
import { CallReturnType } from 'Types/saga';

import { changeSelectedProject } from '../common/actions';
import { selectCurrentProjectGUID } from '../common/selectors';
import { setNotificationFail, setNotificationSuccess } from '../notifications/action';

import {
  getPackagesList,
  setCreatePackageAPICallStatus,
  setCreatePackageModalVisibility,
  setDestinationSecretsList,
  setDestinationSecretsListAPICallStatus,
  setDownloadPackageManifestAPICallStatus,
  setImportPackageModalVisibility,
  setPackageDeploymentList,
  setPackageDeploymentListAPICallStatus,
  setPackageDetails,
  setPackageDetailsAPICallStatus,
  setPackageListAPICallStatus,
  setPackagesList,
  setSecretList,
  setSecretListAPICallStatus,
} from './actions';
import PackagesActionTypes from './actionTypes';

function* createPackage(action: PackagesAction<CreatePackagePayload>) {
  try {
    const selectedProjectGUID: string = yield select(selectCurrentProjectGUID);
    yield put(setCreatePackageAPICallStatus(APICallStatus.LOADING));
    yield call(createPackageAPI, action.payload);
    const { project } = action.payload;
    const successPayload: NotificationPayload = {
      message: 'Package Created Successfully!',
    };
    const createPackageVisibility: string = yield select(
      (state: AppState) => state.packages.createPackageModalVisibility,
    );

    yield put(setNotificationSuccess(successPayload));
    yield put(setCreatePackageAPICallStatus(APICallStatus.LOADED));
    if (createPackageVisibility) {
      yield put(setCreatePackageModalVisibility(false));
    } else {
      yield put(setImportPackageModalVisibility(false));
    }
    if (selectedProjectGUID === project) {
      yield put(getPackagesList());
    } else {
      yield put(changeSelectedProject(project));
    }
  } catch (error) {
    yield put(setCreatePackageAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Failed To Create The Package',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* deletePackage(action: PackagesAction<GetDeletePackagePayload>) {
  try {
    yield put(setPackageListAPICallStatus(APICallStatus.LOADING));
    yield call(deletePackageAPI, action.payload);

    const successPayload: NotificationPayload = {
      message: 'Package Deleted Successfully!',
    };
    yield put(setNotificationSuccess(successPayload));
    yield put(setPackageListAPICallStatus(APICallStatus.LOADED));
    yield put(getPackagesList());
  } catch (error) {
    yield put(setPackageListAPICallStatus(APICallStatus.LOADED));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Failed To Delete The Package',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* getPackageDetails(action: PackagesAction<GetDeletePackagePayload>) {
  try {
    yield put(setPackageDetailsAPICallStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getPackageDetailsAPI> = yield call(
      getPackageDetailsAPI,
      action.payload,
    );
    yield put(setPackageDetails(data));
    yield put(setPackageDetailsAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setPackageDetailsAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Failed To Get The Package Details',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
    yield put(push(`/packages/`));
  }
}

function* getPackages() {
  try {
    yield put(setPackageListAPICallStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getPackagesAPI> = yield call(getPackagesAPI);
    yield put(setPackagesList(data));

    yield put(setPackageListAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setPackageListAPICallStatus(APICallStatus.ERROR));

    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: err?.message,
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* fetchSecretsList(
  setAPICallStatus: (status: APICallStatus) => PackagesAction<APICallStatus>,
  setSecretsListAction: (data: Secret[] | null) => PackagesAction<Secret[] | null>,
  project: string,
) {
  try {
    yield put(setAPICallStatus(APICallStatus.LOADING));
    const { data: secrets }: CallReturnType<typeof getSecretsApi> = yield call(getSecretsApi, {
      headers: {
        project,
      },
    });
    yield put(setSecretsListAction(secrets));
    yield put(setAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Failed To Get The Secrets',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* getSecretsList() {
  const selectedProject: string = yield select(
    (state: AppState) => state.common.selectedProjectGUID,
  );
  yield call(fetchSecretsList, setSecretListAPICallStatus, setSecretList, selectedProject);
}

function* getDestinationSecretsList() {
  const destinationProject: string = yield select(
    (state: AppState) => state.packages.clonePackageDetails.destinationProject,
  );
  yield call(
    fetchSecretsList,
    setDestinationSecretsListAPICallStatus,
    setDestinationSecretsList,
    destinationProject,
  );
}

function* downloadPackageManifest(action: PackagesAction<GetDeletePackagePayload>) {
  try {
    yield put(setDownloadPackageManifestAPICallStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getPackageDetailsAPI> = yield call(
      getPackageDetailsAPI,
      action.payload,
    );
    const manifest = getManifest(ManifestKinds.PACKAGE, data.metadata, data.spec);

    yield put(downloadYAML(manifest));

    yield put(setDownloadPackageManifestAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setDownloadPackageManifestAPICallStatus(APICallStatus.ERROR));
  }
}

function* getPackageDeploymentList(action: PackagesAction<GetPackageDeploymentListPayload>) {
  try {
    yield put(setPackageDeploymentListAPICallStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getPackageDeploymentListAPI> = yield call(
      getPackageDeploymentListAPI,
      action.payload,
    );
    yield put(setPackageDeploymentList(data));
    yield put(setPackageDeploymentListAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setPackageDeploymentListAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Failed To Get The Deployment Details',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

export default function* packagesSaga(): IterableIterator<ForkEffect<never>> {
  yield takeLatest(PackagesActionTypes.GET_PACKAGES_LIST, getPackages);
  yield takeLatest(PackagesActionTypes.DELETE_PACKAGE, deletePackage);
  yield takeLatest(PackagesActionTypes.CREATE_PACKAGE, createPackage);
  yield takeLatest(PackagesActionTypes.GET_PACKAGE_DETAILS, getPackageDetails);
  yield takeLatest(PackagesActionTypes.GET_SECRETS_LIST, getSecretsList);
  yield takeLatest(PackagesActionTypes.GET_DESTINATION_SECRETS_LIST, getDestinationSecretsList);
  yield takeLatest(PackagesActionTypes.DOWNLOAD_PACKAGE_MANIFEST, downloadPackageManifest);
  yield takeLatest(PackagesActionTypes.GET_PACKAGE_DEPLOYMENT_LIST, getPackageDeploymentList);
}
