import { APICallStatus } from 'Models/common/types';
import {
  ConfigurationAction,
  ConfigurationActionPayload,
  ConfigurationState,
} from 'Models/configurations/types';
import ActionTypes from 'RioRedux/configurations/actionTypes';

const initialState: ConfigurationState = {
  list: [],
  listApiStatus: APICallStatus.INITIAL,

  detailsApiStatus: APICallStatus.INITIAL,
  createApiStatus: APICallStatus.INITIAL,
  renameApiStatus: APICallStatus.INITIAL,
  deleteApiStatus: APICallStatus.INITIAL,
  cloneApiStatus: APICallStatus.INITIAL,
};

const configurationReducer = (
  state: ConfigurationState = initialState,
  { type, payload }: ConfigurationAction<ConfigurationActionPayload>,
): ConfigurationState => {
  switch (type) {
    case ActionTypes.SET_LIST_API_STATUS: {
      return { ...state, listApiStatus: payload as APICallStatus };
    }

    case ActionTypes.SET_LIST_API_DATA: {
      return { ...state, list: payload as string[] };
    }

    case ActionTypes.SET_CREATE_API_STATUS: {
      return { ...state, createApiStatus: payload as APICallStatus };
    }

    case ActionTypes.SET_RENAME_API_STATUS: {
      return { ...state, renameApiStatus: payload as APICallStatus };
    }

    case ActionTypes.SET_DELETE_API_STATUS: {
      return { ...state, deleteApiStatus: payload as APICallStatus };
    }

    case ActionTypes.SET_CLONE_API_STATUS: {
      return { ...state, cloneApiStatus: payload as APICallStatus };
    }

    default:
      return state;
  }
};

export default configurationReducer;
