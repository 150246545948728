import {
  APICallStatus,
  LocalNotification,
  LocalNotificationPayload,
  RIOError,
  User,
} from 'Models/common/types';
import { UserGroup } from 'Models/userGroups/types';
import ActionTypes from 'RioRedux/projects/actionTypes';
import {
  CreateProjectUser,
  CreateProjectUserGroup,
} from 'Root/projects/CreateProject/CreateProject.types';
import { USERROLE } from 'Shared/types';

import { APIOrganization, Organization } from '../organization/types';

export interface Project {
  name: string;
  createdAt: string;
  updatedAt: string;
  guid: string;
  users?: ProjectUser[];
  creator: string;
  organization: string;
  groups?: ProjectGroup[];
  isVpnEnabled?: boolean;
  projectStatus: ProjectStatus;
  vpnStatus?: ProjectFeatureStatus;
  subnets?: string[];
  labels?: Record<string, string>;
}

export type ProjectWithRole = Project & { role: USERROLE };

export enum ProjectStatus {
  PENDING = 'Pending',
  ERROR = 'Error',
  SUCCESS = 'Success',
  DELETING = 'Deleting',
  UNKNOWN = 'Unknown',
}

export enum ProjectFeatureStatus {
  SUCCESS = 'Success',
  ERROR = 'Error',
  DISABLED = 'Disabled',
}

export interface UserProject {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  guid: string;
  users?: User[];
  creator: string;
  organization: Organization;
  groups?: UserGroup[];
}

export type UserProjectWithRole = UserProject & { role: USERROLE };

export interface ProjectUser {
  userGUID: string;
  emailID: string;
  firstName: string;
  lastName: string;
  role: USERROLE;
}

export interface ProjectGroup {
  userGroupGUID: string;
  name: string;
  role: USERROLE;
}

export interface ProjectFeatures {
  vpn: ProjectVPNConfig;
}

export interface ProjectVPNConfig {
  enabled: boolean;
  subnets?: string[];
}

export interface ProjectsState {
  list: Project[];
  listAPIStatus: APICallStatus;
  apiStatus: APICallStatus;
  data: Project | null;
  error: RIOError | null;
  localNotifications: ProjectLocalNotification[];
}

export interface ProjectRejectedPayload {
  projectID: string;
  error: RIOError;
}

export interface AssociateUserPayload {
  projectId: string;
  userGUID: string;
  name: string;
  email?: string;
}

export type ProjectsActionPayload =
  | Project[]
  | string
  | Project
  | APICallStatus
  | number
  | ProjectRejectedPayload
  | AssociateUserPayload
  | RIOError
  | AddUsersPayload;

export interface ProjectsAction<P = undefined> {
  type: ActionTypes;
  payload?: P;
}

export interface APIProject {
  kind: 'Project';
  apiVersion: 'api.rapyuta.io/v2';
  metadata: {
    name: string;
    guid: string;
    projectGUID: string;
    organizationGUID: string;
    creatorGUID: string;
    labels: Record<string, string>;
    createdAt: string;
    updatedAt: string;
  };
  spec?: {
    users: ProjectUser[];
    userGroups: ProjectGroup[];
    features: ProjectFeatures;
  };
  status: {
    status: ProjectStatus;
    vpn?: ProjectFeatureStatus;
  };
}

export interface UserAPIProject {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  name: string;
  guid: string;
  creator: string;
  users: User[];
  organization: APIOrganization;
  groups?: UserGroup[];
}

export interface CreateProjectPayload {
  name: string;
  organization: {
    guid: string;
  };
  users?: CreateProjectUser[];
  userGroups?: CreateProjectUserGroup[];
  vpn: string;
  subnets?: string[];
  hideNotification?: boolean;
  labels?: Record<string, string>;
}

export interface EditProjectPayload {
  projectId: string;
  data: CreateProjectPayload;
  hideNotification?: boolean;
}

interface CreateProjectAPISpec {
  users?: {
    userGUID?: string;
    emailID: string;
    firstName?: string;
    lastName?: string;
  }[];
  userGroups?: {
    name: string;
    UserGroupGUID?: string;
  }[];
  features?: ProjectFeatures;
}

export interface CreateProjectAPIPayload {
  metadata: {
    name: string;
    organizationGUID: string;
    labels?: Record<string, string>;
  };
  spec?: CreateProjectAPISpec;
}

export interface UpdateProjectOwnerData {
  metadata: {
    creatorGUID: string;
  };
}

export interface UpdateProjectOwnerPayload {
  data: UpdateProjectOwnerData;
  projectID: string;
}
export interface DeleteProjectPayload {
  name: string;
  id: string;
}

export interface AddUsersPayload {
  projectId: string;
  users: string[];
  showSuccessNotif?: boolean;
}

export enum ProjectNotifType {
  CREATE = 'create',
  DETAILS = 'details',
}

export type ProjectLocalNotification = LocalNotification & { projectNotifType?: ProjectNotifType };
export type ProjectLocalNotifPayload = LocalNotificationPayload & {
  projectNotifType?: ProjectNotifType;
};
