import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';

import { APICallStatus } from 'Models/common/types';
import { NotificationPayload } from 'Models/notifications/types';
import { createDeploymentAPI, getDeploymentNameList } from 'Root/models/deployPackage/service';
import { DeployPackageAction, DeployPackagePayloadData } from 'Root/models/deployPackage/types';
import { getDevicesApi } from 'Root/models/devices/service';
import { getDisksListApi } from 'Root/models/disks/service';
import { getNetworkListApi } from 'Root/models/networks/service';
import { getPackageDetails as getPackageDetailsAPI } from 'Root/models/packages/service';
import { GetDeletePackagePayload } from 'Root/models/packages/types';
import { getMessageFromError } from 'Shared/utils/common';
import { CallReturnType } from 'Types/saga';

import { setNotificationFail, setNotificationSuccess } from '../notifications/action';

import {
  createDeploymentAPICallStatus,
  setDeploymentNamesList,
  setDeploymentNamesListAPICallStatus,
  setDeployPackageDeviceList,
  setDeployPackageDeviceListAPICallStatus,
  setDeployPackageDiskList,
  setDeployPackageDiskListAPICallStatus,
  setDeployPackageModalVisibility,
  setDeployPackageNetworkList,
  setDeployPackageNetworkListAPICallStatus,
  setDeployPackagePackageDetails,
  setDeployPackagePackageDetailsAPICallStatus,
} from './actions';
import DeployPackageActionTypes from './actionTypes';

function* getDeploymentNamesList() {
  try {
    yield put(setDeploymentNamesListAPICallStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getDeploymentNameList> = yield call(getDeploymentNameList);
    yield put(setDeploymentNamesList(data));

    yield put(setDeploymentNamesListAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setDeploymentNamesListAPICallStatus(APICallStatus.ERROR));

    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Error Getting Deployment List',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* getPackageDetails(action: DeployPackageAction<GetDeletePackagePayload>) {
  try {
    yield put(setDeployPackagePackageDetailsAPICallStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getPackageDetailsAPI> = yield call(
      getPackageDetailsAPI,
      action.payload,
    );
    yield put(setDeployPackagePackageDetails(data));
    yield put(setDeployPackagePackageDetailsAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setDeployPackagePackageDetailsAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Failed To Get The Package Details',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* getDevicesList() {
  try {
    yield put(setDeployPackageDeviceListAPICallStatus(APICallStatus.LOADING));
    const { data }: CallReturnType<typeof getDevicesApi> = yield call(getDevicesApi);

    yield put(setDeployPackageDeviceList(data));
    yield put(setDeployPackageDeviceListAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setDeployPackageDeviceListAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Error Getting Devices List',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* getDiskList() {
  try {
    yield put(setDeployPackageDiskListAPICallStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getDisksListApi> = yield call(getDisksListApi);

    yield put(setDeployPackageDiskList(data));
    yield put(setDeployPackageDiskListAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setDeployPackageDiskListAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Error Getting Disk List',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* getNetworkList() {
  try {
    yield put(setDeployPackageNetworkListAPICallStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getNetworkListApi> = yield call(getNetworkListApi);

    yield put(setDeployPackageNetworkList(data));
    yield put(setDeployPackageNetworkListAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setDeployPackageNetworkListAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Error Getting Network List',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* createDeployment(action: DeployPackageAction<DeployPackagePayloadData>) {
  try {
    yield put(createDeploymentAPICallStatus(APICallStatus.LOADING));
    yield call(createDeploymentAPI, action.payload);

    yield put(createDeploymentAPICallStatus(APICallStatus.LOADED));
    yield put(setDeployPackageModalVisibility(false));
    yield put(push(`/deployments/${action.payload.metadata.name}`));
    const successPayload: NotificationPayload = {
      message: 'Package Deployed Successfully!',
    };

    yield put(setNotificationSuccess(successPayload));
  } catch (error) {
    yield put(createDeploymentAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Error in Package Deployment',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

export default function* deployPackageSaga(): IterableIterator<ForkEffect<never>> {
  yield takeLatest(DeployPackageActionTypes.GET_DEPLOYMENT_NAMES_LIST, getDeploymentNamesList);
  yield takeLatest(DeployPackageActionTypes.GET_DEPLOY_PACKAGE_PACKAGE_DETAILS, getPackageDetails);
  yield takeLatest(DeployPackageActionTypes.GET_DEPLOY_PACKAGE_DEVICES_LIST, getDevicesList);
  yield takeLatest(DeployPackageActionTypes.GET_DEPLOY_PACKAGE_DISK_LIST, getDiskList);
  yield takeLatest(DeployPackageActionTypes.GET_DEPLOY_PACKAGE_NETWORK_LIST, getNetworkList);
  yield takeLatest(DeployPackageActionTypes.CREATE_DEPLOYMENT, createDeployment);
}
