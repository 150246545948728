import {
  APIPlan,
  APIPlanComponents,
  APIPlanLimits,
  Plan,
  PlanComponents,
  PlanLimits,
} from './types';

export const mapAPIPlanLimitsToPlanLimits = ({
  CreatedAt,
  DeletedAt,
  ID,
  PlanComponentID,
  UpdatedAt,
  ...rest
}: APIPlanLimits): PlanLimits => ({
  createdAt: CreatedAt,
  deletedAt: DeletedAt,
  id: ID,
  planComponentID: PlanComponentID,
  updatedAt: UpdatedAt,
  ...rest,
});

export const mapAPIPlanComponentsToPlanComponent = ({
  CreatedAt,
  DeletedAt,
  ID,
  PlanId,
  UpdatedAt,
  planLimits,
  ...rest
}: APIPlanComponents): PlanComponents => ({
  createdAt: CreatedAt,
  deletedAt: DeletedAt,
  id: ID,
  planId: PlanId,
  updatedAt: UpdatedAt,
  planLimits: planLimits && mapAPIPlanLimitsToPlanLimits(planLimits),
  ...rest,
});

export const mapAPIPlanToPlan = ({
  CreatedAt,
  DeletedAt,
  ID,
  UpdatedAt,
  planComponents,
  ...rest
}: APIPlan): Plan => ({
  createdAt: CreatedAt,
  deletedAt: DeletedAt,
  id: ID,
  updatedAt: UpdatedAt,
  planComponents: planComponents.map(mapAPIPlanComponentsToPlanComponent),
  ...rest,
});
