import { AddNetworkFormData } from 'Root/networks/addNetwork/AddNetwork.types';
import { nativeNetworkCPUMemLimits, routedNetworkCPUMemLimitOptions } from 'Root/networks/utils';

import { Limits, ROSVersion, RunTime } from '../common/types';

import { CreateNetworkPayload, Network, NetworkSpecification, NetworkTypes } from './types';

export const mapAPINetworkDataToNetwork = ({
  metadata,
  spec,
  status,
}: NetworkSpecification): Network => ({
  name: metadata.name,
  createdAt: metadata.createdAt,
  updatedAt: metadata.updatedAt,
  region: metadata.region,
  guid: metadata.guid,
  ownerProject: metadata.projectGUID,
  creator: metadata.creatorGUID,
  runtime: spec.runtime,
  rosDistro: spec.rosDistro,
  restartPolicy: spec.restartPolicy,
  internalDeploymentStatus: {
    phase: status.phase,
    status: status.status,
  },
  limits: spec.resourceLimits,
  depends: {
    nameOrGUID: spec.depends?.nameOrGUID,
  },
  type: spec.type,
  networkInterface: spec.networkInterface,
});

export const mapAPINetworlListToNetworkList = (data: NetworkSpecification[]): Network[] =>
  data.map(mapAPINetworkDataToNetwork);

const getResourceLimits = (formData: AddNetworkFormData) => {
  const { type, resourceLimits } = formData;
  let limitsData: Limits = {
    cpu: 1,
    memory: 4,
  };

  if (type === NetworkTypes.ROUTED) {
    limitsData = routedNetworkCPUMemLimitOptions.find((data) => data.label === resourceLimits)
      ?.value as Limits;
  } else {
    limitsData = nativeNetworkCPUMemLimits.find((data) => data.label === resourceLimits)
      ?.value as Limits;
  }

  return limitsData;
};

export const getFormattedFormData = (formData: AddNetworkFormData): CreateNetworkPayload => {
  const {
    name,
    type,
    runtime,
    rosDistro,
    device,
    ipInterface,
    restartPolicy,
    serverId,
    serverPort,
    region,
  } = formData;
  const data = {
    metadata: {
      name,
      ...(runtime === RunTime.CLOUD && {
        region,
      }),
    },
    spec: {
      type,
      runtime,
      rosDistro,
      ...(runtime === RunTime.CLOUD && {
        resourceLimits: getResourceLimits(formData),
      }),
      ...(runtime === RunTime.DEVICE && {
        depends: {
          nameOrGUID: device,
        },
        networkInterface: ipInterface,
        restartPolicy,
      }),
      ...(rosDistro === ROSVersion.FOXY && {
        discoveryServer: {
          serverId,
          serverPort,
        },
      }),
    },
  };
  return data as CreateNetworkPayload;
};
