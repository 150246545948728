export const DEVICE_VOLUME_MOUNT_PATH_REGEX =
  /^([\\/][a-z0-9\s\-_@\-^!#$%&.]*)+(\.[a-z][a-z0-9]+)?$/i;
export const DEVICE_VOLUME_SUB_PATH_REGEX =
  /^([\\/][a-z0-9\s\-_@\-^!#$%&.]*)+(\.[a-z][a-z0-9]+)?$/i;

export const CLOUD_VOLUME_MOUNT_PATH_REGEX = /^(\/)?([^/]+(\/)?)+$/;
export const CLOUD_VOLUME_SUB_PATH_REGEX = /^([^/]+(\/)?)+$/;

export const EMAIL_REGEX =
  '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

export const DISALLOW_PLUS_EMAIL_REGEX =
  '^(([^<>()[\\]\\.,;:\\s@+"]+(\\.[^<>()[\\]\\.,;:\\s@+"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

export const CLOUD_PARAMS_DIR_REGEX = /^(\/[^\\/\\x00]*)+\/?$/;
