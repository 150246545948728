enum SecretActionTypes {
  SOURCE_SECRETS_GET_FULFILLED = 'SOURCE_SECRETS_GET_FULFILLED',
  SOURCE_SECRETS_GET_REJECTED = 'SOURCE_SECRETS_GET_REJECTED',
  SOURCE_SECRETS_GET_PENDING = 'SOURCE_SECRETS_GET_PENDING',
  SOURCE_SECRET_GET = 'SOURCE_SECRET_GET',

  SOURCE_SECRETS_CREATE_PENDING = 'SOURCE_SECRETS_CREATE_PENDING',
  SOURCE_SECRETS_CREATE_FULFILLED = 'SOURCE_SECRETS_CREATE_FULFILLED',
  SOURCE_SECRETS_CREATE_REJECTED = 'SOURCE_SECRETS_CREATE_REJECTED',
  SOURCE_SECRET_CREATE = 'SOURCE_SECRET_CREATE',

  SOURCE_SECRET_DELETE_PENDING = 'SOURCE_SECRET_DELETE_PENDING',
  SOURCE_SECRET_DELETE_FULFILLED = 'SOURCE_SECRET_DELETE_FULFILLED',
  SOURCE_SECRET_DELETE_REJECTED = 'SOURCE_SECRET_DELETE_REJECTED',
  SOURCE_SECRET_DELETE = 'SOURCE_SECRET_DELETE',

  SOURCE_SECRETS_ADD_MODAL_TOGGLE = 'SOURCE_SECRETS_ADD_MODAL_TOGGLE',

  SOURCE_SECRET_EDIT = 'SOURCE_SECRET_EDIT',
  SOURCE_SECRET_EDIT_API_STATUS = 'SOURCE_SECRET_EDIT_API_STATUS',
}

export default SecretActionTypes;
