import { AxiosPromise } from 'axios';

import { PROJECTS_ROOT_URL } from 'Shared/config';
import { authAxios } from 'Shared/utils/user';

import { mapAPIProjectDataToProject, mapAPIProjectsListToProjectsList } from './dataFormatters';
import { CreateProjectAPIPayload, Project, UpdateProjectOwnerPayload } from './types';

export const getProjectsList = (selectedOrgGUID: string): Promise<Project[]> =>
  authAxios(
    {
      url: `${PROJECTS_ROOT_URL}`,
      params: { organizations: selectedOrgGUID },
    },
    false,
    true,
  ).then((response) => mapAPIProjectsListToProjectsList(response.data.items));

export const createProject = (data: CreateProjectAPIPayload): Promise<Project> =>
  authAxios(
    {
      method: 'POST',
      url: PROJECTS_ROOT_URL,
      data,
    },
    false,
    true,
  ).then((response) => mapAPIProjectDataToProject(response.data));

export const editProject = (data: CreateProjectAPIPayload, projectID: string): Promise<Project> =>
  authAxios(
    {
      method: 'PUT',
      url: `${PROJECTS_ROOT_URL}${projectID}/`,
      data,
    },
    false,
    true,
  ).then((response) => mapAPIProjectDataToProject(response.data));

export const deleteProject = (projectID: string): AxiosPromise<void> =>
  authAxios(
    {
      method: 'DELETE',
      url: `${PROJECTS_ROOT_URL}${projectID}/`,
    },
    false,
    true,
  );

export const getProjectDetails = (projectID: string): Promise<Project> =>
  authAxios({ url: `${PROJECTS_ROOT_URL}${projectID}/` }, false, true).then((response) =>
    mapAPIProjectDataToProject(response.data),
  );

export const updateProjectOwner = ({
  data,
  projectID,
}: UpdateProjectOwnerPayload): AxiosPromise<void> =>
  authAxios(
    {
      method: 'PUT',
      url: `${PROJECTS_ROOT_URL}${projectID}/owner/`,
      data,
    },
    false,
    true,
  );
