import { AxiosError } from 'axios';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';

import { APICallStatus } from 'Models/common/types';
import { NotificationPayload } from 'Models/notifications/types';
import { getErrorMessageFromResponse, mapSecretToAPISecret } from 'Models/secrets/dataFormatters';
import {
  addSecretApi,
  deleteSecretApi,
  editSecretApi,
  getSecretsApi,
} from 'Models/secrets/service';
import { SecretAction, SecretFormData } from 'Models/secrets/types';
import { NOTIF_MSG } from 'Shared/constants/secrets';
import { CallReturnType } from 'Types/saga';

import { setNotificationFail, setNotificationSuccess } from '../notifications/action';

import {
  getSecrets as getSecretsAction,
  setCreateSecretFail,
  setCreateSecretInit,
  setCreateSecretSuccess,
  setDeleteSecretSuccess,
  setSecretEditAPIStatus,
  setSecretList,
  setSecretListInit,
  setSecretsListFail,
  toggleSecretAddModal,
} from './actions';
import ActionTypes from './actionTypes';

function* getSecrets() {
  try {
    yield put(setSecretListInit());
    const { data: secrets }: CallReturnType<typeof getSecretsApi> = yield call(getSecretsApi);

    yield put(setSecretList(secrets));
  } catch (err) {
    const error = err as AxiosError;
    const errorMessage: string = getErrorMessageFromResponse(error);

    yield put(setSecretsListFail(errorMessage, error.response?.status as number));
    const errorPayload: NotificationPayload = {
      message: NOTIF_MSG.GET.FAIL,
      description: errorMessage,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* createSecret(action: SecretAction<SecretFormData>) {
  try {
    yield put(setCreateSecretInit());

    const mappedPayload = mapSecretToAPISecret(action.payload as SecretFormData);

    const { data }: CallReturnType<typeof addSecretApi> = yield call(addSecretApi, mappedPayload);

    yield put(setCreateSecretSuccess(data));
    yield put(
      setNotificationSuccess({
        message: NOTIF_MSG.CREATE.SUCCESS,
      }),
    );
    yield put(getSecretsAction());
  } catch (err) {
    const error = err as AxiosError;

    yield put(setCreateSecretFail());
    const errorMessage = getErrorMessageFromResponse(error);
    const errorPayload: NotificationPayload = {
      message: NOTIF_MSG.CREATE.FAIL,
      description: errorMessage,
    };

    yield put(setNotificationFail(errorPayload));
  }
}

function* deleteSecret(action: SecretAction<string>) {
  try {
    const name = action.payload as string;
    yield call(deleteSecretApi, name);

    yield put(setDeleteSecretSuccess(name));
    yield put(
      setNotificationSuccess({
        message: NOTIF_MSG.DELETE.SUCCESS,
      }),
    );
    yield put(getSecretsAction());
  } catch (err) {
    const error = err as AxiosError;
    const errorPayload: NotificationPayload = {
      message: NOTIF_MSG.DELETE.FAIL,
      description: getErrorMessageFromResponse(error),
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* editSecret(action: SecretAction<SecretFormData>) {
  try {
    const { name } = action.payload as SecretFormData;
    yield put(setSecretEditAPIStatus(APICallStatus.LOADING));

    const mappedPayload = mapSecretToAPISecret(action.payload as SecretFormData);

    yield call(editSecretApi, mappedPayload, name);
    yield put(setSecretEditAPIStatus(APICallStatus.LOADED));

    yield put(getSecretsAction());
    yield put(
      setNotificationSuccess({
        message: NOTIF_MSG.EDIT.SUCCESS,
      }),
    );
  } catch (err) {
    const error = err as AxiosError;
    yield put(
      setNotificationFail({
        message: NOTIF_MSG.EDIT.FAIL,
        description: getErrorMessageFromResponse(error),
      }),
    );
  } finally {
    yield put(toggleSecretAddModal());
  }
}

export default function* secretsSaga(): IterableIterator<ForkEffect<never>> {
  yield takeLatest(ActionTypes.SOURCE_SECRET_GET, getSecrets);
  yield takeLatest(ActionTypes.SOURCE_SECRET_CREATE, createSecret);
  yield takeLatest(ActionTypes.SOURCE_SECRET_DELETE, deleteSecret);
  yield takeLatest(ActionTypes.SOURCE_SECRET_EDIT, editSecret);
}
