import { APICallStatus } from 'Root/models/common/types';
import {
  DeployPackageAction,
  DeployPackagePayload,
  DeployPackageState,
} from 'Root/models/deployPackage/types';
import { Device } from 'Root/models/devices/types';
import { Disk } from 'Root/models/disks/types';
import { Network } from 'Root/models/networks/types';
import { PackageSpecification } from 'Root/models/packages/types';

import DeployPackageActionTypes from './actionTypes';

const initialState: DeployPackageState = {
  deployPackageModalVisibility: false,
  deploymentNameList: [],
  deploymentNameListAPICallStatus: APICallStatus.INITIAL,
  packageDetails: null,
  packageDetailsAPIStatus: APICallStatus.INITIAL,
  deviceList: [],
  deviceListAPIStatus: APICallStatus.INITIAL,
  diskList: [],
  diskListAPIStatus: APICallStatus.INITIAL,
  networkList: [],
  networkListAPIStatus: APICallStatus.INITIAL,
  createDeploymentAPICallStatus: APICallStatus.INITIAL,
};

const deployPackageReducer = (
  state: DeployPackageState = initialState,
  action: DeployPackageAction<DeployPackagePayload>,
): DeployPackageState => {
  switch (action.type) {
    case DeployPackageActionTypes.SET_DEPLOY_PACKAGE_MODAL_VISIBILITY:
      return {
        ...state,
        deployPackageModalVisibility: action.payload as boolean,
      };
    case DeployPackageActionTypes.SET_DEPLOYMENT_NAMES_LIST:
      return {
        ...state,
        deploymentNameList: action.payload as string[],
      };
    case DeployPackageActionTypes.SET_DEPLOYMENT_NAMES_LIST_API_STATUS:
      return {
        ...state,
        deploymentNameListAPICallStatus: action.payload as APICallStatus,
      };
    case DeployPackageActionTypes.SET_DEPLOY_PACKAGE_PACKAGE_DETAILS:
      return {
        ...state,
        packageDetails: action.payload as PackageSpecification,
      };
    case DeployPackageActionTypes.SET_DEPLOY_PACKAGE_PACKAGE_DETAILS_API_STATUS:
      return {
        ...state,
        packageDetailsAPIStatus: action.payload as APICallStatus,
      };
    case DeployPackageActionTypes.SET_DEPLOY_PACKAGE_DEVICES_LIST:
      return {
        ...state,
        deviceList: action.payload as Device[],
      };
    case DeployPackageActionTypes.SET_DEPLOY_PACKAGE_DEVICES_LIST_API_STATUS:
      return {
        ...state,
        deviceListAPIStatus: action.payload as APICallStatus,
      };
    case DeployPackageActionTypes.SET_DEPLOY_PACKAGE_DISK_LIST:
      return {
        ...state,
        diskList: action.payload as Disk[],
      };
    case DeployPackageActionTypes.SET_DEPLOY_PACKAGE_DISK_LIST_API_STATUS:
      return {
        ...state,
        diskListAPIStatus: action.payload as APICallStatus,
      };
    case DeployPackageActionTypes.SET_DEPLOY_PACKAGE_NETWORK_LIST:
      return {
        ...state,
        networkList: action.payload as Network[],
      };
    case DeployPackageActionTypes.SET_DEPLOY_PACKAGE_NETWORK_LIST_API_STATUS:
      return {
        ...state,
        networkListAPIStatus: action.payload as APICallStatus,
      };
    case DeployPackageActionTypes.CREATE_DEPLOYMENT_API_STATUS:
      return {
        ...state,
        createDeploymentAPICallStatus: action.payload as APICallStatus,
      };
    default:
      return state;
  }
};

export default deployPackageReducer;
