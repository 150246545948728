import { AddRoutePayload, APIStaticRoute, StaticRoute } from './types';

export const mapAPIStaticRouteDataToStaticRoute = (apiData: APIStaticRoute): StaticRoute => ({
  createdAt: apiData.metadata.createdAt,
  updatedAt: apiData.metadata.updatedAt,
  creator: apiData.metadata.creatorGUID,
  guid: apiData.metadata.guid,
  projectGUID: apiData.metadata.projectGUID,
  urlPrefix: apiData.metadata.name,
  urlString: apiData.spec.url,
  metadata: {
    status: apiData.status.status,
    deploymentId: apiData.status.deploymentID,
    packageId: apiData.status.packageID,
  },
  region: apiData.metadata?.region || 'jp',
  sourceIPRange: apiData.spec.sourceIPRange || [],
  labels: apiData.metadata.labels || {},
});

export const mapAPIStaticRouteToStaticRoute = (apiList: APIStaticRoute[]): StaticRoute[] =>
  apiList.map(mapAPIStaticRouteDataToStaticRoute);

export const mapStaticRouteToAPIStaticRoute = (data: AddRoutePayload): AddRoutePayload => ({
  ...data,
});
