enum StaticRouteActionTypes {
  GET_LIST = 'SROUTES/GET_LIST',
  DELETE_ROUTE = 'SROUTES/DELETE_ROUTE',
  CREATE_ROUTE = 'SROUTES/CREATE_ROUTE',

  UPDATE_ROUTES = 'SROUTES/UPDATE_ROUTES',
  SET_ADD_ROUTE_DRAWER_VISIBILITY = 'SROUTES/SET_ADD_ROUTE_DRAWER_VISIBILITY',
  UPDATE_ROUTE_DETAILS = 'SROUTES/UPDATE_ROUTE_DETAILS',
  SET_API_STATUS = 'SROUTES/SET_API_STATUS',

  SET_CREATE_API_STATUS = 'SROUTES/SET_CREATE_API_STATUS',
  SET_CREATE_ERROR = 'SROUTES/SET_CREATE_ERROR',
}

export default StaticRouteActionTypes;
