export const TRIAL_BANNER_SKIP_USER_LIST = 'trialBannerSkipUserList';

export const LSKEY_USERGROUPS_VIEWTYPE = 'rioUserGroupViewType';

export const LSKEY_TABLE_SIZE = 'rioTableSize';

export const PLATFORM_ALERT = 'rioAlert';

export const SHOW_DASHBOARD_OVERVIEW = 'showDashboardOverview';

export const VIEW_TYPE = 'viewType';
