import { APICallStatus, RIOError, RunTime } from 'Models/common/types';
import { RecordOptions, UploadOptions, UploadType } from 'Models/devices/types';
import DeploymentActionTypes from 'RioRedux/deployments/actionTypes';

import { DeploymentSpecification } from '../deployPackage/types';
import { PackageSpecification } from '../packages/types';

export enum DeploymentPhase {
  IN_PROGRESS = 'InProgress',
  PROVISIONING = 'Provisioning',
  SUCCEEDED = 'Succeeded',
  START_FAILED = 'FailedToStart',
  UPDATE_FAILED = 'FailedToUpdate',
  STOPPED = 'Stopped',
}
export enum DeploymentStatus {
  RUNNING = 'Running',
  PENDING = 'Pending',
  ERROR = 'Error',
  UNKNOWN = 'Unknown',
  STOPPED = 'Stopped',
}

export type DeploymentState = {
  deploymentList: Deployment[];
  deploymentListApiStatus: APICallStatus;

  collectRosbagsStatus: APICallStatus;
  updateRosbagJobStatus: APICallStatus;

  deploymentDetails: DeploymentSpecification | null;
  deploymentDetailsApiStatus: APICallStatus;
  downloadDeploymentManifestApiStatus: APICallStatus;
  deploymentHistory: DeploymentHistory[];
  deploymentHistoryApiStatus: APICallStatus;

  packageDetails: PackageSpecification | null;
  packageDetailsDetailsApiStatus: APICallStatus;

  dependencyGraphData: DependencyGraphData | null;
};

export type ContainerState = {
  message: string;
  reason: string;
};

export type ContainerLastStateInfo = {
  containerID: string;
  exitCode: number;
  finishedAt: string;
  startedAt: string;
} & ContainerState;

export interface ContainerDetail {
  containerId: string;
  image: string;
  imageID: string;
  lastState: Record<string, ContainerLastStateInfo>;
  name: string;
  ready: boolean;
  restartCount: number;
  state: Record<string, ContainerState>;
}
export interface ContainerAndPodDetail {
  containerDetail: ContainerDetail[];
  podName: string;
  podStatus: string;
}
export type MetaData = ContainerAndPodDetail[];

export interface NetworkInfo {
  networkGUID: string;
  deploymentGUID: SVGStringList;
  networkType: string;
}
export interface ExecutablesStatusInfo {
  id: string;
  status: string;
  metadata: MetaData | null;
  reason?: string;
  networkInfo?: NetworkInfo;
}

export interface RosbagJob {
  name: string;
  recordOptions: RecordOptions;
  uploadOptions: UploadOptions;
}

export interface APICompOverride {
  // eslint-disable-next-line camelcase
  restart_policy: string;
}
export interface CompOverride {
  restartPolicy: string;
}

/* eslint-disable camelcase */
export interface APIDepComponentContext {
  ros_bag_job_defs: RosbagJob[];
  component_override: APICompOverride | null;
  static_route_config?: string[];
}
/* eslint-enable camelcase */
export interface DepComponentContext {
  rosBagJobDefs: RosbagJob[];
  componentOverride: CompOverride | null;
  staticRouteConfig?: string[];
}

export interface NetworkProvisionContext {
  guid: string;
  bindParameters?: {
    ROS_DOMAIN_ID: number;
  };
}
export interface APIProvisionContext {
  // eslint-disable-next-line camelcase
  component_context: Record<string, APIDepComponentContext>;
  dependentDeployments: DependentDeployments[];
  labels: string[];
  name: string;
  nativeNetworks: NetworkProvisionContext[];
  routedNetworks: NetworkProvisionContext[];
  // eslint-disable-next-line camelcase
  network_topics_map: Record<string, string[]>;
  managedServices?: Array<{ instance: string; name: string }> | null;
}
export type ProvisionContext = Omit<
  APIProvisionContext,
  'component_context' | 'network_topics_map' | 'managedServices'
> & {
  componentContext: Record<string, DepComponentContext>;
  networkTopicsMap: Record<string, string[]>;
  isVpnEnabled: boolean;
};

// TODO: Duplicate exists in the catalog changes merge it later on
export interface APIListDeployment {
  CreatedAt: string;
  DeletedAt: string | null;
  ID: number;
  UpdatedAt: string;
  bindable: boolean;
  creator: string;
  deploymentId: string;
  errorCode: string[];
  inUse: boolean;
  lastStatusUpdateTime: string;
  name: string;
  ownerProject: string;
  packageVersion: string;
  packageAPIVersion: string;
  packageId: string;
  packageName: string;
  phase: DeploymentPhase;
  planId: string;
  status: string;
  usedBy: string;
}

export interface Topic {
  compression: string;
  name: string;
  qos: string;
  scoped: boolean;
  targeted: boolean;
}

export interface DependentDeployments {
  dependentDeploymentId: string;
}

// Capitalised Runtime
export enum RunTimeCapital {
  DEVICE = 'Device',
  CLOUD = 'Cloud',
}
export interface APIRosbag {
  CreatedAt: string;
  DeletedAt: string | null;
  ID: number;
  UpdatedAt: string;
  componentID: string;
  componentInstanceID: string;
  componentType: RunTimeCapital;
  creator: string;
  deploymentID: string;
  deprovisioned: boolean;
  guid: string;
  name: string;
  packageID: string;
  project: string;
  recordOptions: RecordOptions;
  status: string;
  uploadOptions?: UploadOptions;
  overrideOptions: OverrideOptions;
  errorMessage?: string;
}

export interface StopRosbagRequest {
  deploymentID: string;
  jobGuids: string[];
}

export interface MessageType {
  md5: string;
  type: string;
}
export interface TopicBag {
  frequency: number;
  messageCount: number;
  messageType: string;
  name: string;
}
export interface BlobInfo {
  bagVersion: string;
  compressedSize: number;
  compression: string;
  duration: number;
  endTime: number;
  indexed: boolean;
  messageCount: number;
  messageTypes: MessageType[] | null;
  size: number;
  startTime: number;
  topics: TopicBag[] | null;
  uncompressedSize: number;
}
export interface APIRosbagBlob {
  CreatedAt: string;
  DeletedAt: string | null;
  ID: number;
  UpdatedAt: string;
  blobRefID: number;
  componentType: RunTimeCapital;
  creator: string;
  errorMessage: string;
  filename: string;
  guid: string;
  info: BlobInfo;
  job: APIRosbag;
  jobID: number;
  project: string;
  status: string;
}

export interface DownloadBlob {
  url: string;
}

export interface CreateRosbagRequest {
  deploymentID: string;
  data: RosbagData;
}

export interface RecordOptionsRequest {
  compression?: string;
  maxMessageCount?: number;
  maxSplitSize?: number;
  maxSplits?: number;
  node?: string;
  topicExcludeRegex?: string;
  topicInludeRegex?: string[];
  topics: string[];
}

export interface TopicInfo {
  topicName: string;
  recordFrequency?: number;
  latched: boolean;
}

export interface OverrideOptions {
  topicOverrideInfo: TopicInfo[];
  excludeTopics?: string[];
}

export interface RosbagData {
  componentInstanceID: string;
  name: string;
  recordOptions: RecordOptionsRequest;
  overrideOptions?: OverrideOptions;
}

export interface Graph {
  destination: string;
  edgeType: string;
  name: string;
}

/* *** Logs and Historical logs *** */
export interface LiveLogsRequestOptions {
  dataCallback: (streamData: string, response: Response) => void;
  endCallback: (response: Response) => void;
  executable: string;
  replica: string;
  deploymentName: string;
}

/* *** Action *** */
interface BaseDeploymentAction {
  type: DeploymentActionTypes;
}
export type DeploymentAction<P = undefined> = P extends undefined
  ? BaseDeploymentAction
  : BaseDeploymentAction & { payload: P };

export type DeploymentActionPayload =
  | string
  | RIOError
  | Deployment
  | boolean
  | Deployment[]
  | DeploymentSpecification
  | DeploymentHistory[]
  | PackageSpecification
  | DependencyGraphData
  | APICallStatus;

export enum CombinedDeploymentStatus {
  RUNNING = 'running',
  ERROR = 'error',
  STOPPED = 'stopped',
  DISCONNECTED = 'disconnected',
  UNKNOWN = 'unknown',
  IN_PROGRESS = 'in-progress',
}

export enum DeploymentSortOption {
  NAME = 'name',
  STARTED = 'createdAt',
  STATUS = 'status',
}
export interface UpdateROSBagJobParams {
  deploymentID: string;
  componentInstanceID: string;
  jobID: string;
  uploadType?: UploadType;
  timeRange?: {
    from: number;
    to: number;
  };
}

export type UpdateROSBagJob = (params: UpdateROSBagJobParams) => void;

export interface Deployment {
  name: string;
  createdAt: string;
  packageName: string;
  packageVersion: string;
  status: DeploymentStatus;
  phase: DeploymentPhase;
  errorCodes: string[];
  runtime: RunTime;
  deploymentId: string;
  deploymentDetails: DeploymentSpecification;
}

export interface GetDeploymentDetailsPayload {
  name: string;
  guid: string;
}

export interface DeploymentHistorySpecification {
  metadata: {
    creatorGUID: string;
    createdAt: string;
    updatedAt: string;
    generation: number;
  };
  status: {
    phase: DeploymentPhase;
    status: DeploymentStatus;
    // eslint-disable-next-line camelcase
    error_codes?: string[];
  };
}

export interface DeploymentHistory {
  creatorGUID: string;
  createdAt: string;
  updatedAt: string;
  phase: DeploymentPhase;
  status: DeploymentStatus;
  generation: number;
  errorCodes?: string[];
}

export enum NodeType {
  DEPLOYMENT = 'Deployment',
  NETWORK = 'Network',
  DISK = 'Disk',
}

export enum EdgeType {
  DEPLOYMENT = 'Deployment',
  NETWORK = 'Network',
  DISK = 'Disk',
  ROS_TOPIC = 'ROSTopic',
  ROS_SERVICE = 'ROSService',
  ROS_ACTION = 'ROSAction',
}
export interface Node {
  Name: string;
  Type: NodeType;
}

export interface Edge {
  From: Node;
  To: Node;
  Text: string;
  Type: EdgeType;
}

export interface DependencyGraphData {
  Nodes: Node[];
  Lines: Edge[];
}

export interface EdgeLegend {
  img: string;
  alt: string;
  title: string;
  show: boolean;
}

export interface NodeLegend {
  title: string;
  show: boolean;
  classname: string;
}
