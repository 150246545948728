import { DeploymentPhase } from 'Root/models/deployments/types';

export const DEPLOYMENT_LIST_CLASSNAME = 'rio-deployment-list';

export const DEFAULT_PHASE_VALUES = [
  DeploymentPhase.IN_PROGRESS,
  DeploymentPhase.PROVISIONING,
  DeploymentPhase.SUCCEEDED,
  DeploymentPhase.START_FAILED,
  DeploymentPhase.UPDATE_FAILED,
];
