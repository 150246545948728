import axios from 'axios';

import { CORE_ROOT_URL, USER_DETAILS_URL } from 'Shared/config';
import { authAxios } from 'Shared/utils/user';

import { mapAPIUserToUser } from './dataFormatters';
import { AuthTokenFormData, UserAPIReturn, UserUpdateProps } from './types';

export const getUserDetails = async (): Promise<UserAPIReturn> =>
  authAxios({
    method: 'GET',
    url: USER_DETAILS_URL,
  }).then((response) => mapAPIUserToUser(response.data));

export const updateUserDeatilsAPI = async (
  guid: string,
  data: UserUpdateProps,
): Promise<UserAPIReturn> =>
  authAxios({
    method: 'PUT',
    url: `${CORE_ROOT_URL}api/user/${guid}/update`,
    data,
  }).then((response) => mapAPIUserToUser(response.data));

export const getAuthToken = async (data: AuthTokenFormData): Promise<string> =>
  axios({
    method: 'POST',
    url: `${window.CONFIG.RIP_ENDPOINT}user/login`,
    params: {
      type: 'high',
    },
    data,
  }).then((response) => response.data.data.token);
