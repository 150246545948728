import { Dispatch } from 'redux';

import { getUsageApi } from 'Models/usage/services';
import { Usage, UsageAction } from 'Models/usage/types';

import UsageActionTypes from './actionTypes';

export const setUsageLoad = (): UsageAction => ({
  type: UsageActionTypes.FETCHING_USAGE,
});

export const setUsageDetails = (payload: Partial<Usage>): UsageAction<Partial<Usage>> => ({
  type: UsageActionTypes.SET_USAGE,
  payload,
});

export const setUsageError = (): UsageAction => ({
  type: UsageActionTypes.ERROR_USAGE,
});

export const getUsageDetails =
  (orgGuid: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setUsageLoad());
    try {
      const {
        data: { data },
      } = await getUsageApi(orgGuid);
      dispatch(setUsageDetails(data));
    } catch {
      dispatch(setUsageError());
    }
  };

export default {};
