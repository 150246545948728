import { APICallStatus } from 'Root/models/common/types';
import { DeviceCondensed } from 'Root/models/devices/types';
import { Network, NetworkAction, NetworkPayload, NetworkState } from 'Root/models/networks/types';

import NetworkActionTypes from './actionTypes';

const initialState: NetworkState = {
  networkList: [],
  networkListAPIStatus: APICallStatus.INITIAL,
  addNetworkVisibility: false,
  deviceList: [],
  deviceListAPIStatus: APICallStatus.INITIAL,
  addNetworkAPIStatus: APICallStatus.INITIAL,
  networkDetails: null,
  networkDetailsAPIStatus: APICallStatus.INITIAL,
  downloadNetworkManifestAPIStatus: APICallStatus.INITIAL,
};

const networkReducer = (
  state: NetworkState = initialState,
  action: NetworkAction<NetworkPayload>,
): NetworkState => {
  switch (action.type) {
    case NetworkActionTypes.SET_NETWORK_LIST: {
      return {
        ...state,
        networkList: action.payload as Network[],
      };
    }
    case NetworkActionTypes.SET_NETWORK_LIST_API_STATUS: {
      return {
        ...state,
        networkListAPIStatus: action.payload as APICallStatus,
      };
    }
    case NetworkActionTypes.SET_ADD_NETWORK_VISIBILITY: {
      return {
        ...state,
        addNetworkVisibility: action.payload as boolean,
      };
    }
    case NetworkActionTypes.SET_ADD_NETWORK_API_STATUS: {
      return {
        ...state,
        addNetworkAPIStatus: action.payload as APICallStatus,
      };
    }
    case NetworkActionTypes.SET_DEVICES_LIST: {
      return {
        ...state,
        deviceList: action.payload as DeviceCondensed[],
      };
    }
    case NetworkActionTypes.SET_DEVICES_LIST_API_STATUS: {
      return {
        ...state,
        deviceListAPIStatus: action.payload as APICallStatus,
      };
    }
    case NetworkActionTypes.SET_NETWORK_DETAILS: {
      return {
        ...state,
        networkDetails: action.payload as Network,
      };
    }
    case NetworkActionTypes.SET_NETWORK_DETAILS_API_STATUS: {
      return {
        ...state,
        networkDetailsAPIStatus: action.payload as APICallStatus,
      };
    }
    case NetworkActionTypes.SET_DOWNLOAD_NETWORK_MANIFEST_API_STATUS: {
      return {
        ...state,
        downloadNetworkManifestAPIStatus: action.payload as APICallStatus,
      };
    }
    default:
      return state;
  }
};

export default networkReducer;
