import { nanoid } from 'nanoid';

import {
  APICallStatus,
  CommonAction,
  RIOError,
  UpdateProjectsAndSelectedProjectPayload,
} from 'Models/common/types';
import {
  Project,
  ProjectLocalNotifPayload,
  ProjectNotifType,
  ProjectRejectedPayload,
  ProjectsAction,
  ProjectsActionPayload,
  ProjectsState,
} from 'Models/projects/types';

import CommonActionTypes from '../common/actionTypes';

import ActionTypes from './actionTypes';

export const initialState: ProjectsState = {
  list: [],
  listAPIStatus: APICallStatus.INITIAL,
  apiStatus: APICallStatus.INITIAL,
  data: null,
  error: null,
  localNotifications: [],
};

const projectsReducer = (
  state: ProjectsState = initialState,
  action:
    | ProjectsAction<ProjectsActionPayload>
    | CommonAction<UpdateProjectsAndSelectedProjectPayload>,
): ProjectsState => {
  switch (action.type) {
    case ActionTypes.PROJECTS_GET_PENDING:
      return {
        ...state,
        listAPIStatus: APICallStatus.LOADING,
      };

    case ActionTypes.SET_PROJECTS_API_STATUS:
      return {
        ...state,
        apiStatus: action.payload as APICallStatus,
      };

    case ActionTypes.PROJECTS_GET_FULFILLED: {
      const payload = action.payload as Project[];

      return {
        ...state,
        list: payload,
        listAPIStatus: APICallStatus.LOADED,
      };
    }

    case ActionTypes.PROJECTS_GET_REJECTED:
      return {
        ...state,
        list: [],
        listAPIStatus: APICallStatus.ERROR,
      };

    case ActionTypes.DELETE_PROJECT_FULFILLED:
      return {
        ...state,
        apiStatus: APICallStatus.LOADED,
        list: state.list.filter((item) => item.name !== (action.payload as string)),
      };

    case ActionTypes.CREATE_PROJECT_FULFILLED:
      return {
        ...state,
        list: state.data
          ? [...state.list, state.data].sort((former, latter) =>
              former.name.toLowerCase().localeCompare(latter.name.toLowerCase()),
            )
          : [...state.list],
        apiStatus: APICallStatus.INITIAL,
      };

    case ActionTypes.CREATE_PROJECT_REJECTED: {
      const payload = action.payload as RIOError;
      return {
        ...state,
        apiStatus: APICallStatus.ERROR,
        error: payload,
      };
    }

    case ActionTypes.EDIT_PROJECT_FULFILLED: {
      const projects = state.list.filter((project: Project) => project.guid !== action.payload);
      return {
        ...state,
        list: state.data
          ? [...projects, state.data].sort((former, latter) =>
              former.name.toLowerCase().localeCompare(latter.name.toLowerCase()),
            )
          : [...state.list],
      };
    }

    case ActionTypes.EDIT_PROJECT_REJECTED: {
      const payload = action.payload as RIOError;
      return {
        ...state,
        error: payload,
      };
    }
    case ActionTypes.PROJECT_DETAILS_GET_PENDING:
      return {
        ...state,
        apiStatus: APICallStatus.LOADING,
        error: null,
      };

    case ActionTypes.PROJECT_DETAILS_GET_FULFILLED:
      return {
        ...state,
        apiStatus: APICallStatus.LOADED,
        error: null,
        data: action.payload as Project,
      };

    case ActionTypes.PROJECT_DETAILS_GET_REJECTED: {
      const payload = action.payload as ProjectRejectedPayload;

      return {
        ...state,
        apiStatus: APICallStatus.ERROR,
        error: payload.error,
      };
    }

    case ActionTypes.CLEAR_DETAILS: {
      return {
        ...state,
        data: null,
      };
    }

    case ActionTypes.SET_LOCAL_NOTIFICATION: {
      const notification = action.payload as ProjectLocalNotifPayload;

      return {
        ...state,
        localNotifications: [
          ...state.localNotifications,
          {
            ...notification,
            id: nanoid(),
            projectNotifType: notification.projectNotifType ?? ProjectNotifType.DETAILS,
          },
        ],
      };
    }

    case ActionTypes.CLEAR_LOCAL_NOTIFICATION: {
      return {
        ...state,
        localNotifications: state.localNotifications.filter(
          (notification) => notification.id !== (action.payload as string),
        ),
      };
    }

    case ActionTypes.RESET_API_STATUS: {
      return {
        ...state,
        apiStatus: APICallStatus.INITIAL,
        error: null,
      };
    }

    case CommonActionTypes.UPDATE_PROJECTS_AND_SELECTED_PROJECT: {
      return {
        ...state,
        list: (action.payload as UpdateProjectsAndSelectedProjectPayload).projects,
        listAPIStatus: APICallStatus.LOADED,
      };
    }

    default:
      return state;
  }
};

export default projectsReducer;
