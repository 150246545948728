import { AxiosPromise } from 'axios';

import { STATIC_ROUTES_ROOT_URL } from 'Shared/config';
import { authAxios } from 'Shared/utils/user';

import {
  mapAPIStaticRouteDataToStaticRoute,
  mapAPIStaticRouteToStaticRoute,
} from './dataFormatters';
import { AddRoutePayload, StaticRoute, UpdateRoutePayload } from './types';

export const getStaticRoutes = (): Promise<StaticRoute[]> =>
  authAxios(
    {
      method: 'GET',
      url: STATIC_ROUTES_ROOT_URL,
    },
    true,
    true,
  ).then((response) => mapAPIStaticRouteToStaticRoute(response.data.items || []));

export const createStaticRoute = (data: AddRoutePayload): Promise<StaticRoute> =>
  authAxios(
    {
      method: 'POST',
      url: STATIC_ROUTES_ROOT_URL,
      data,
    },
    true,
    true,
  ).then((response) => mapAPIStaticRouteDataToStaticRoute(response.data));

export const updateRouteDetails = (data: UpdateRoutePayload) =>
  authAxios(
    {
      method: 'PUT',
      url: `${STATIC_ROUTES_ROOT_URL}${data.urlParam}/`,
      data: data.payload,
    },
    true,
    true,
  );

export const deleteStaticRoute = (name: string): AxiosPromise<void> =>
  authAxios(
    {
      method: 'DELETE',
      url: `${STATIC_ROUTES_ROOT_URL}${name}/`,
    },
    true,
    true,
  );
