enum BillingActionTypes {
  SET_PLANS = 'SET_PLANS',
  SET_USAGE = 'SET_USAGE',
  FETCHING_USAGE = 'FETCHING_USAGE',
  ERROR_USAGE = 'ERROR_USAGE',
  FETCHING_PLAN_DETAILS = 'FETCHING_PLAN_DETAILS',
  PLAN_DETAILS_ERROR = 'PLAN_DETAILS_ERROR',
  PLAN_DETAILS_LOADED = 'PLAN_DETAILS_LOADED',
}

export default BillingActionTypes;
