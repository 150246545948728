import {
  APIMplacePkgCategory,
  APIMplacePkgDetails,
  APIMplacePublisher,
  APISKU,
  MplacePkgCategory,
  MplacePkgDetails,
  MplacePublisher,
  SKU,
} from './types';

export const mapAPIPkgCategoryToPkgCategory = (
  apiCategory: APIMplacePkgCategory,
): MplacePkgCategory => ({
  categoryName: apiCategory.category_name,
  categoryIcon: apiCategory.category_icon,
});

export const mapAPIMplacePublisherToMplacePublisher = (
  apiPublisher: APIMplacePublisher,
): MplacePublisher => ({
  name: apiPublisher.name,
  rrnPrefix: apiPublisher.rrn_prefix,
  publisherSlug: apiPublisher.publisher_slug,
});

/* eslint-disable camelcase */
export const mapAPISKUToSKU = ({
  changelog_url,
  is_latest,
  last_updated,
  ...rest
}: APISKU): SKU => ({
  ...rest,
  changelogURL: changelog_url,
  isLatest: is_latest,
  lastUpdated: last_updated,
});

/* eslint-enable */

export const mapAPIMplacePkgDetailsToMplacePkgDetails = (
  apiDetails: APIMplacePkgDetails,
): MplacePkgDetails => ({
  categories: apiDetails.categories.map(mapAPIPkgCategoryToPkgCategory),
  publisher: mapAPIMplacePublisherToMplacePublisher(apiDetails.publisher),
  images: apiDetails.images,
  name: apiDetails.name,
  rrn: apiDetails.rrn,
  slug: apiDetails.slug,
  description: apiDetails.description,
  website: apiDetails.website,
  supportEmail: apiDetails.support_email,
  documentationURL: apiDetails.documentation_url,
  licenseURL: apiDetails.license_url,
  supportURL: apiDetails.support_url,
  pricingURL: apiDetails.pricing_url,
  brochureURL: apiDetails.brochure_url,
  tosURL: apiDetails.tos_url,
  imageURL: apiDetails.image_url,
  skus: apiDetails.skus.map(mapAPISKUToSKU),
  readme: apiDetails.readme,
  rioRosVersion: apiDetails.rio_ros_version,
});
