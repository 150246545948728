import { APICallStatus } from 'Models/common/types';
import {
  StaticRoute,
  StaticRoutesAction,
  StaticRoutesPayload,
  StaticRouteState,
} from 'Models/staticRoutes/types';

import StaticRouteActionTypes from './actionTypes';

export const initialState: StaticRouteState = {
  list: null,
  error: null,
  apiStatus: APICallStatus.INITIAL,
  addRouteDrawerVisibility: false,
  createRouteStatus: APICallStatus.INITIAL,
  createRouteError: null,
};

export const staticRoutesReducer = (
  state: StaticRouteState = initialState,
  action: StaticRoutesAction<StaticRoutesPayload>,
): StaticRouteState => {
  switch (action.type) {
    case StaticRouteActionTypes.UPDATE_ROUTES:
      return {
        ...state,
        list: action.payload as StaticRoute[],
      };

    case StaticRouteActionTypes.SET_API_STATUS:
      return {
        ...state,
        apiStatus: action.payload as APICallStatus,
      };

    case StaticRouteActionTypes.SET_ADD_ROUTE_DRAWER_VISIBILITY:
      return {
        ...state,
        addRouteDrawerVisibility: action.payload as boolean,
      };

    case StaticRouteActionTypes.SET_CREATE_API_STATUS:
      return {
        ...state,
        createRouteStatus: action.payload as APICallStatus,
      };

    case StaticRouteActionTypes.SET_CREATE_ERROR:
      return {
        ...state,
        createRouteError: action.payload as string,
      };

    default:
      return state;
  }
};

export default staticRoutesReducer;
