import { stringify } from 'yaml';

export function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event?.target?.result as string);
    };

    reader.onerror = () => {
      reject(reader.error);
      reader.abort();
    };

    reader.readAsDataURL(blob);
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createYAMLFileFromObj = (obj: Record<string, any>): Blob => {
  const yamlStr = stringify(obj);
  const bytes = new TextEncoder().encode(yamlStr);
  const blob = new Blob([bytes], {
    type: 'application/x-yaml',
  });

  return blob;
};

export const downloadBlob = (blob: Blob, fileName: string): void => {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};
