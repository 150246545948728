import { cloneDeep } from '../utils/core/object';

const initialState = {
  notifications: {},
};

export default function (state = initialState, action) {
  let nextState = cloneDeep(state);
  switch (action.type) {
    case 'ADD_PERSISTENT_NOTIFICATION': {
      if (!nextState.notifications[action.payload.targetId]) {
        nextState.notifications[action.payload.targetId] = action.payload;
      }
      break;
    }
    case 'REPLACE_PERSISTENT_NOTIFICATION': {
      nextState.notifications[action.payload.targetId] = action.payload;
      break;
    }
    case 'REMOVE_PERSISTENT_NOTIFICATION': {
      delete nextState.notifications[action.payload.targetId];
      break;
    }
    case 'REMOVE_ALL_NOTIFICATIONS': {
      nextState = { notifications: {} };
      break;
    }
    case 'CHANGE_PERSISTENT_NOTIFICATION_STATUS': {
      if (nextState.notifications[action.payload.targetId]) {
        nextState.notifications[action.payload.targetId].isRead = action.payload.isRead;
      }
      break;
    }
    case 'CHANGE_ALL_PERSISTENT_NOTIFICATIONS_STATUS': {
      const targetIds = Object.keys(nextState.notifications);
      targetIds.forEach((id) => {
        nextState.notifications[id].isRead = action.payload.isRead;
      });
      break;
    }
    default:
      break;
  }
  return nextState;
}
