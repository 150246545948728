import { APICallStatus } from 'Models/common/types';
import {
  AddRoutePayload,
  StaticRoute,
  StaticRoutesAction,
  UpdateRoutePayload,
} from 'Models/staticRoutes/types';

import ActionTypes from './actionTypes';

export const getStaticRoutesList = (): StaticRoutesAction => ({
  type: ActionTypes.GET_LIST,
});

export const deleteStaticRoute = (payload: string): StaticRoutesAction<string> => ({
  type: ActionTypes.DELETE_ROUTE,
  payload,
});

export const createStaticRoute = (
  payload: AddRoutePayload,
): StaticRoutesAction<AddRoutePayload> => ({
  type: ActionTypes.CREATE_ROUTE,
  payload,
});

export const updateStaticRoutes = (payload: StaticRoute[]): StaticRoutesAction<StaticRoute[]> => ({
  type: ActionTypes.UPDATE_ROUTES,
  payload,
});

export const setAPICallStatus = (payload: APICallStatus): StaticRoutesAction<APICallStatus> => ({
  type: ActionTypes.SET_API_STATUS,
  payload,
});

export const setCreateRouteAPIStatus = (
  payload: APICallStatus,
): StaticRoutesAction<APICallStatus> => ({
  type: ActionTypes.SET_CREATE_API_STATUS,
  payload,
});

export const setCreateRouteError = (payload: string): StaticRoutesAction<string> => ({
  type: ActionTypes.SET_CREATE_ERROR,
  payload,
});

export const setAddRouteDrawerVisibility = (payload: boolean): StaticRoutesAction<boolean> => ({
  type: ActionTypes.SET_ADD_ROUTE_DRAWER_VISIBILITY,
  payload,
});

export const updateRouteDetails = (
  payload: UpdateRoutePayload,
): StaticRoutesAction<UpdateRoutePayload> => ({
  type: ActionTypes.UPDATE_ROUTE_DETAILS,
  payload,
});
