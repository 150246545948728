import {
  ClonePackageDetails,
  CreatePackagePayload,
  GetDeletePackagePayload,
  GetPackageDeploymentListPayload,
  PackageDeploymentList,
  PackageList,
  PackagesAction,
  PackageSpecification,
} from 'Models/packages/types';
import { APICallStatus } from 'Root/models/common/types';
import { Secret } from 'Root/models/secrets/types';

import PackagesActionTypes from './actionTypes';

export const getPackagesList = (): PackagesAction => ({
  type: PackagesActionTypes.GET_PACKAGES_LIST,
});

export const setPackagesList = (payload: PackageList[]): PackagesAction<PackageList[]> => ({
  type: PackagesActionTypes.SET_PACKAGES_LIST,
  payload,
});

export const setPackageListAPICallStatus = (
  payload: APICallStatus,
): PackagesAction<APICallStatus> => ({
  type: PackagesActionTypes.SET_PACKAGES_LIST_API_STATUS,
  payload,
});

export const deletePackage = (
  payload: GetDeletePackagePayload,
): PackagesAction<GetDeletePackagePayload> => ({
  type: PackagesActionTypes.DELETE_PACKAGE,
  payload,
});

export const setImportPackageModalVisibility = (payload: boolean): PackagesAction<boolean> => ({
  type: PackagesActionTypes.IMPORT_PACKAGE_MODAL_VISIBILITY,
  payload,
});

export const setCreatePackageAPICallStatus = (
  payload: APICallStatus,
): PackagesAction<APICallStatus> => ({
  type: PackagesActionTypes.SET_CREATE_PACKAGE_API_STATUS,
  payload,
});

export const creatPackage = (
  payload: CreatePackagePayload,
): PackagesAction<CreatePackagePayload> => ({
  type: PackagesActionTypes.CREATE_PACKAGE,
  payload,
});

export const setCreatePackageModalVisibility = (payload: boolean): PackagesAction<boolean> => ({
  type: PackagesActionTypes.CREATE_PACKAGE_MODAL_VISIBILITY,
  payload,
});

export const getPackageDetails = (
  payload: GetDeletePackagePayload,
): PackagesAction<GetDeletePackagePayload> => ({
  type: PackagesActionTypes.GET_PACKAGE_DETAILS,
  payload,
});

export const setPackageDetails = (
  payload: PackageSpecification | null,
): PackagesAction<PackageSpecification | null> => ({
  type: PackagesActionTypes.SET_PACKAGE_DETAILS,
  payload,
});

export const setPackageDetailsAPICallStatus = (
  payload: APICallStatus,
): PackagesAction<APICallStatus> => ({
  type: PackagesActionTypes.SET_PACKAGE_DETAILS_API_STATUS,
  payload,
});

export const setClonePackageDetails = (
  payload: ClonePackageDetails,
): PackagesAction<ClonePackageDetails> => ({
  type: PackagesActionTypes.SET_CLONE_PACKAGE_DETAILS,
  payload,
});

export const getSecretsList = (): PackagesAction => ({
  type: PackagesActionTypes.GET_SECRETS_LIST,
});

export const setSecretList = (data: Secret[] | null): PackagesAction<Secret[] | null> => ({
  type: PackagesActionTypes.SET_SECRETS_LIST,
  payload: data,
});

export const setSecretListAPICallStatus = (
  payload: APICallStatus,
): PackagesAction<APICallStatus> => ({
  type: PackagesActionTypes.SET_SECRETS_LIST_API_STATUS,
  payload,
});

export const getDestinationSecretsList = (): PackagesAction => ({
  type: PackagesActionTypes.GET_DESTINATION_SECRETS_LIST,
});

export const setDestinationSecretsList = (
  data: Secret[] | null,
): PackagesAction<Secret[] | null> => ({
  type: PackagesActionTypes.SET_DESTINATION_SECRETS_LIST,
  payload: data,
});

export const setDestinationSecretsListAPICallStatus = (
  payload: APICallStatus,
): PackagesAction<APICallStatus> => ({
  type: PackagesActionTypes.SET_DESTINATION_SECRETS_LIST_API_STATUS,
  payload,
});

export const downloadPackageManifest = (
  payload: GetDeletePackagePayload,
): PackagesAction<GetDeletePackagePayload> => ({
  type: PackagesActionTypes.DOWNLOAD_PACKAGE_MANIFEST,
  payload,
});

export const setDownloadPackageManifestAPICallStatus = (
  payload: APICallStatus,
): PackagesAction<APICallStatus> => ({
  type: PackagesActionTypes.DOWNLOAD_PACKAGE_MANIFEST_API_STATUS,
  payload,
});

export const getPackageDeploymentList = (
  payload: GetPackageDeploymentListPayload,
): PackagesAction<GetPackageDeploymentListPayload> => ({
  type: PackagesActionTypes.GET_PACKAGE_DEPLOYMENT_LIST,
  payload,
});

export const setPackageDeploymentList = (
  payload: PackageDeploymentList[],
): PackagesAction<PackageDeploymentList[]> => ({
  type: PackagesActionTypes.SET_PACKAGE_DEPLOYMENT_LIST,
  payload,
});

export const setPackageDeploymentListAPICallStatus = (
  payload: APICallStatus,
): PackagesAction<APICallStatus> => ({
  type: PackagesActionTypes.SET_PACKAGE_DEPLOYMENT_LIST_API_STATUS,
  payload,
});
