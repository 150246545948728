enum DeployPackageActionTypes {
  SET_DEPLOY_PACKAGE_MODAL_VISIBILITY = 'SET_DEPLOY_PACKAGE_MODAL_VISIBILITY',
  GET_DEPLOYMENT_NAMES_LIST = 'GET_DEPLOYMENT_NAMES_LIST',
  SET_DEPLOYMENT_NAMES_LIST = 'SET_DEPLOYMENT_NAMES_LIST',
  SET_DEPLOYMENT_NAMES_LIST_API_STATUS = 'SET_DEPLOYMENT_NAMES_LIST_API_STATUS',
  GET_DEPLOY_PACKAGE_PACKAGE_DETAILS = 'GET_DEPLOY_PACKAGE_PACKAGE_DETAILS',
  SET_DEPLOY_PACKAGE_PACKAGE_DETAILS = 'SET_DEPLOY_PACKAGE_PACKAGE_DETAILS',
  SET_DEPLOY_PACKAGE_PACKAGE_DETAILS_API_STATUS = 'SET_DEPLOY_PACKAGE_PACKAGE_DETAILS_API_STATUS',
  GET_DEPLOY_PACKAGE_DEVICES_LIST = 'GET_DEPLOY_PACKAGE_DEVICES_LIST',
  SET_DEPLOY_PACKAGE_DEVICES_LIST = 'SET_DEPLOY_PACKAGE_DEVICES_LIST',
  SET_DEPLOY_PACKAGE_DEVICES_LIST_API_STATUS = 'SET_DEPLOY_PACKAGE_DEVICES_LIST_API_STATUS',
  GET_DEPLOY_PACKAGE_DISK_LIST = 'GET_DEPLOY_PACKAGE_DISK_LIST',
  SET_DEPLOY_PACKAGE_DISK_LIST = 'SET_DEPLOY_PACKAGE_DISK_LIST',
  SET_DEPLOY_PACKAGE_DISK_LIST_API_STATUS = 'SET_DEPLOY_PACKAGE_DISK_LIST_API_STATUS',
  GET_DEPLOY_PACKAGE_NETWORK_LIST = 'GET_DEPLOY_PACKAGE_NETWORK_LIST',
  SET_DEPLOY_PACKAGE_NETWORK_LIST = 'SET_DEPLOY_PACKAGE_NETWORK_LIST',
  SET_DEPLOY_PACKAGE_NETWORK_LIST_API_STATUS = 'SET_DEPLOY_PACKAGE_NETWORK_LIST_API_STATUS',
  CREATE_DEPLOYMENT = 'CREATE_DEPLOYMENT',
  CREATE_DEPLOYMENT_API_STATUS = 'CREATE_DEPLOYMENT_API_STATUS',
}

export default DeployPackageActionTypes;
