export enum SecretFormField {
  NAME = 'name',
  REGISTRY = 'registryType',
  REGISTRY_URL = 'url',
  USERNAME = 'username',
  PASSWORD = 'password',
  EMAIL = 'email',
}

export default {};
