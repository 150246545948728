import {
  APICallStatus,
  APIPlanMetaData,
  EntityState,
  LocalNotification,
  LocalNotificationPayload,
  PlanMetaData,
  SelectOption,
  User,
} from 'Models/common/types';
import {
  CreateUserGroupData,
  UpdateUserGroupData,
  UserGroup,
  UserGroupWithProjectRole,
} from 'Models/userGroups/types';
import ActionTypes from 'RioRedux/organization/actionTypes';

import { Project } from '../projects/types';

export interface OrgState {
  apiStatus: APICallStatus;
  error: string;
  details: Organization;
  countries: Country[];
  userGroups: UserGroup[];
  userGroupDetails: UserGroupWithProjectRole | null;
  userGroupsAPIStatus: APICallStatus;
  createUserGroupAPIStatus: APICallStatus;
  updateUserGroupAPIStatus: APICallStatus;
  deleteUserGroupAPIStatus: APICallStatus;
  leaveUserGroupAPIStatus: APICallStatus;
  userGroupDetailsAPIStatus: APICallStatus;
  countryAPIStatus: APICallStatus;
  toggleStateAPIStatus: APICallStatus;
  registrationStatus: OrgRegistrationStatus;
  provinceAPIStatus: APICallStatus;
  provinceMap: ProvinceMap;
  inviteUserEmail: string;
  localNotifications: LocalNotification[];
}

export type Organization = Omit<
  APIOrganization,
  'ID' | 'CreatedAt' | 'UpdatedAt' | 'DeletedAt' | 'country' | 'planMetadata'
> & {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
  country?: Country;
  planMetaData?: PlanMetaData;
};

export interface Country {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  name: string;
  code: string;
  hasProvinces?: boolean;
}

export interface UpdateProvinceMapPayload {
  countryID: number;
  options: ProvinceOption[];
}

export type OrgActionPayload =
  | Organization
  | APICallStatus
  | Country[]
  | OrgRegistrationStatus
  | UpdateProvinceMapPayload
  | CreateUserGroupData
  | UpdateUserGroupData
  | UserGroup
  | UserGroup[]
  | LocalNotificationPayload;

interface BaseOrgAction {
  type: ActionTypes;
}

export type OrgAction<P = undefined> = P extends undefined
  ? BaseOrgAction
  : BaseOrgAction & { payload: P };

export type APICountry = Omit<Country, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt'> & {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
};

export interface APIOrganization {
  ID?: number;
  guid: string;
  name: string;
  countryID?: number;
  country?: APICountry;
  province?: string;
  postalCode?: string;
  url: string;
  planID: number | null;
  state: EntityState | null;
  users: User[];
  projects: Project[] | null;
  creator: string;
  shortGUID?: string;
  planMetadata?: APIPlanMetaData;
  CreatedAt?: string;
  UpdatedAt?: string;
  DeletedAt?: string | null;
}

export enum OrgRegistrationStatus {
  INITIAL = 'INITIAL',
  REGISTER_ORG_PENDING = 'REGISTER_ORG_PENDING',
  REGISTER_ORG_SUCCESS = 'REGISTER_ORG_SUCCESS',
  REGISTER_ORG_FAILURE = 'REGISTER_ORG_FAILURE',
}

export type ProvinceOption = SelectOption;

export type ProvinceMap = Record<number, ProvinceOption[]>;

export interface PaymentInfoParams {
  orgGUID: string;
  paymentReferenceID?: string;
  planID?: number;
}

export interface ToggleUserStatePayload {
  userGUID: string;
  organizationGUID: string;
  state: EntityState;
}
