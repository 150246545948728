import { AxiosPromise } from 'axios';

import { USAGE_DETAILS_URL } from 'Shared/config';
import { authAxios } from 'Shared/utils/user';

import { APIUsageResponse } from './types';

export const getUsageApi = (
  orgGUID: string,
  isBillingInfo = true,
): AxiosPromise<APIUsageResponse> =>
  authAxios({
    method: 'GET',
    url: `${USAGE_DETAILS_URL}${orgGUID}`,
    params: {
      billInfo: isBillingInfo,
    },
  });

export default {};
