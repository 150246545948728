import { AxiosError } from 'axios';
import Raven from 'raven-js';
import { END, EventChannel, eventChannel } from 'redux-saga';
import { call, ForkEffect, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  getAuthToken as getAuthTokenAPI,
  getUserDetails as getUserDetailsAPI,
  updateUserDeatilsAPI,
} from 'Models/common/service';
import {
  APICallStatus,
  AuthTokenFormData,
  CommonAction,
  CopyToClipboardPayload,
  EntityState,
  User,
  UserMap,
  UserUpdateProps,
} from 'Models/common/types';
import { DefaultMessages } from 'Models/notifications/types';
import { getOrganizationDetails } from 'Models/organization/service';
import { ProjectUser } from 'Models/projects/types';
import { createUserApi } from 'Shared/api';
import { IS_DEV_ENV, UI_VERSION } from 'Shared/config';
import { customSetCookie, getMessageFromError, replaceUrlParameters } from 'Shared/utils/common';
import storeUtilsAdapterInst from 'Shared/utils/storeUtilsAdapter';
import {
  getTrialPeriodBannerSkipList,
  logoutUser,
  setTrialPeriodBannerSkipList,
} from 'Shared/utils/user';
import { CallReturnType } from 'Types/saga';

import { setNotificationFail, setNotificationSuccess } from '../notifications/action';
import { saveOrgDetails } from '../organization/actions';
import { listProjects } from '../projects/actions';

import {
  saveUserDetails,
  setAPICallStatus,
  setAuthTokenAPIError,
  setAuthTokenAPIStatus,
  setErrorMessage,
  setNewUserFlag,
  setShowBanner,
  setUpdateUserApiStatus,
  updateAuthToken,
  updateSelectedOrg,
  updateSelectedProject,
  updateUserDetails,
  updateUserFulfilled,
  updateUserMap,
} from './actions';
import ActionTypes from './actionTypes';
import { selectCurrentOrgGUID, selectUserDetails, selectUserMap } from './selectors';

export const changeSelectedProjectMisc = (projectGUID: string): void => {
  replaceUrlParameters({ p: projectGUID });
  customSetCookie('project', projectGUID);
  storeUtilsAdapterInst.setSelectedProjectId(projectGUID); // to update in the singleton
};

function* updateSelectedOrgData(orgID: string) {
  replaceUrlParameters({ org: orgID });
  customSetCookie('organization', orgID);
  yield put(updateSelectedOrg(orgID));
}

function* getUserDetails() {
  try {
    yield put(setAPICallStatus(APICallStatus.LOADING));
    const userDetails: CallReturnType<typeof getUserDetailsAPI> = yield call(getUserDetailsAPI);

    const selectedOrgGUID: ReturnType<typeof selectCurrentOrgGUID> = yield select(
      selectCurrentOrgGUID,
    );
    const shouldSelectedOrgUpdate =
      !selectedOrgGUID || userDetails?.organizations?.every((org) => selectedOrgGUID !== org.guid);
    if (shouldSelectedOrgUpdate) {
      const newOrgGUID = userDetails?.organizations?.[0]?.guid ?? '';
      yield call(updateSelectedOrgData, newOrgGUID);
    }

    if (userDetails.state === EntityState.INVITED) {
      yield call(createUserApi);
      window.location.reload();
    }

    yield put(saveUserDetails(userDetails));
    yield put(setAPICallStatus(APICallStatus.LOADED));
  } catch (error: unknown) {
    const err = error as AxiosError;
    yield put(setAPICallStatus(APICallStatus.ERROR));

    const errorMessage = err.message;

    if (!err.response) {
      yield put(setErrorMessage(err.message));
    }

    switch (err.response?.status) {
      case 401:
        logoutUser();
        break;

      case 403:
        yield put(setNewUserFlag(true));
        break;

      default: {
        const message = err.response?.data?.error || errorMessage;
        yield put(setErrorMessage(message));
        break;
      }
    }
  }
}

function* saveUserDetailsSaga(action: CommonAction<User>) {
  storeUtilsAdapterInst.setUserDetails(action.payload);
  yield put(updateUserDetails(action.payload));
}

function* setUserMapSaga(action: CommonAction<ProjectUser[]>) {
  const currentUserMap: ReturnType<typeof selectUserMap> = yield select(selectUserMap);

  const userMap = {
    ...currentUserMap,
    ...action.payload.reduce((userMapObj: UserMap, user: ProjectUser) => {
      userMapObj[user.userGUID] = user; // eslint-disable-line no-param-reassign

      return userMapObj;
    }, {}),
  };

  storeUtilsAdapterInst.setUserMap(userMap);
  yield put(updateUserMap(userMap));
}

function* hideBanner() {
  const userDetails: ReturnType<typeof selectUserDetails> = yield select(selectUserDetails);
  let skipUsersList = getTrialPeriodBannerSkipList();
  skipUsersList = [...skipUsersList, userDetails.guid];

  setTrialPeriodBannerSkipList(skipUsersList);

  yield put(setShowBanner(false));
}

function* handleCopyText(action: CommonAction<CopyToClipboardPayload>) {
  const { payload } = action;

  try {
    yield navigator.clipboard.writeText(payload.content);
    if (payload.showNotification) {
      yield put(
        setNotificationSuccess({
          message: `${payload.title} Copied To Clipboard`,
        }),
      );
    }
  } catch (e) {
    if (payload.showNotification) {
      yield put(
        setNotificationFail({
          message: "Error - Couldn't Copy To Clipboard",
        }),
      );
    }
  }
}

function* updateUser(action: CommonAction<UserUpdateProps>) {
  try {
    yield put(setUpdateUserApiStatus(APICallStatus.LOADING));
    const userDetails: ReturnType<typeof selectUserDetails> = yield select(selectUserDetails);
    const { guid } = userDetails;

    const updatedUserDetails: CallReturnType<typeof updateUserDeatilsAPI> = yield call(
      updateUserDeatilsAPI,
      guid,
      action.payload,
    );

    yield put(
      setNotificationSuccess({
        message: 'Details updated successfully',
      }),
    );

    if (Object.keys(action.payload).includes('emailID')) {
      logoutUser();
    } else {
      yield put(updateUserFulfilled(updatedUserDetails));
    }

    yield put(setUpdateUserApiStatus(APICallStatus.LOADED));
  } catch (err) {
    const error = err as AxiosError;

    yield put(setUpdateUserApiStatus(APICallStatus.ERROR));
    yield put(
      setNotificationFail({
        message: 'Failed to Update User Details',
        description: getMessageFromError(error),
      }),
    );
  }
}

function* updateSelectedOrgDataAndGetProjects(orgGUID: string, userGUID: string) {
  yield call(updateSelectedOrgData, orgGUID);
  yield put(listProjects(userGUID));
}

function* changeSelectedOrg(action: CommonAction<string>) {
  const userDetails: ReturnType<typeof selectUserDetails> = yield select(selectUserDetails);

  try {
    const orgDetails: CallReturnType<typeof getOrganizationDetails> = yield call(
      getOrganizationDetails,
      action.payload,
    );

    yield call(updateSelectedOrgDataAndGetProjects, action.payload, userDetails.guid);
    yield put(saveOrgDetails(orgDetails));
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 403) {
      yield call(updateSelectedOrgDataAndGetProjects, action.payload, userDetails.guid);
    } else {
      yield put(
        setNotificationFail({
          message: 'Cannot Switch Organization at the Moment',
          description: 'Error in fetching organization details',
        }),
      );
    }
  }
}

/* 
  Since Pendo is an async script loaded through GTM, and we don't have any events
  fired once Pendo is initialized, the window object is periodically checked to
  see if the pendo object is present.
*/
function checkPendo(): EventChannel<boolean> {
  let counter = 0;
  return eventChannel((emitter) => {
    const intervalID = setInterval(() => {
      if ('pendo' in window) {
        emitter(true);
        emitter(END);
      }

      counter += 1;

      if (counter >= 10) {
        emitter(END);
      }
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  });
}

function* initPendo(action: CommonAction<string>) {
  try {
    const channel: CallReturnType<typeof checkPendo> = yield call(checkPendo);
    yield takeEvery(channel, () => {
      window.pendo.initialize({
        visitor: {
          id: action.payload,
        },
      });
    });
  } catch (error) {
    if (!IS_DEV_ENV) {
      Raven.captureException(error, {
        tags: {
          version: UI_VERSION,
        },
      });
    }
  }
}

function* changeSelectedProject(action: CommonAction<string>) {
  changeSelectedProjectMisc(action.payload);
  yield put(updateSelectedProject(action.payload)); // to update in redux
}

function* getAuthToken(action: CommonAction<AuthTokenFormData>) {
  try {
    yield put(setAuthTokenAPIStatus(APICallStatus.LOADING));
    yield put(setAuthTokenAPIError(''));

    const token: CallReturnType<typeof getAuthTokenAPI> = yield call(
      getAuthTokenAPI,
      action.payload,
    );

    yield put(updateAuthToken(token));
    yield put(setAuthTokenAPIStatus(APICallStatus.LOADED));
  } catch (error) {
    const err = error as AxiosError;
    let errorMessage: string = DefaultMessages.ERROR_HEADING;

    if (err.response?.status === 401) {
      errorMessage = 'Invalid credentials';
    }

    yield put(setAuthTokenAPIStatus(APICallStatus.ERROR));
    yield put(setAuthTokenAPIError(errorMessage));
  }
}

export default function* commonSaga(): IterableIterator<ForkEffect<never>> {
  yield takeLatest(ActionTypes.GET_USER_DETAILS, getUserDetails);
  yield takeLatest(ActionTypes.SAVE_USER_DETAILS, saveUserDetailsSaga);
  yield takeLatest(ActionTypes.SET_USER_MAP, setUserMapSaga);
  yield takeLatest(ActionTypes.HIDE_BANNER, hideBanner);
  yield takeLatest(ActionTypes.COPY_TEXT, handleCopyText);
  yield takeLatest(ActionTypes.UPDATE_USER_INFO, updateUser);
  yield takeLatest(ActionTypes.CHANGE_SELECTED_ORG, changeSelectedOrg);
  yield takeLatest(ActionTypes.INIT_PENDO, initPendo);
  yield takeLatest(ActionTypes.CHANGE_SELECTED_PROJECT, changeSelectedProject);
  yield takeLatest(ActionTypes.GET_AUTH_TOKEN, getAuthToken);
}
