import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, compose, Dispatch } from 'redux';
import { SagaMiddleware } from 'redux-saga';
import thunk from 'redux-thunk';

import { AppAction } from 'Models/common/types';

const logger = () => (next: Dispatch<AppAction>) => (action: AppAction) => {
  next(action);
};

const error = () => (next: Dispatch<AppAction>) => (action: AppAction) => {
  try {
    next(action);
  } catch (err) {
    console.log(err);
  }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = (
  history: History,
  sagaMiddleware: SagaMiddleware<Record<string, unknown>>,
): any =>
  composeEnhancers(
    applyMiddleware(routerMiddleware(history), error, thunk, logger, sagaMiddleware),
  );

export default middleware;
