import { APICallStatus } from 'Models/common/types';
import {
  InstallPkgActionPaylod,
  MarketplaceAction,
  MplacePkgDetails,
} from 'Models/marketplace/types';

import ActionTypes from './actionTypes';

export const setAPICallStatus = (payload: APICallStatus): MarketplaceAction<APICallStatus> => ({
  type: ActionTypes.SET_API_STATUS,
  payload,
});

export const getPackageDetails = (payload: string): MarketplaceAction<string> => ({
  type: ActionTypes.GET_PACKAGE_DETAILS,
  payload,
});

export const updatePackageDetails = (
  payload: MplacePkgDetails,
): MarketplaceAction<MplacePkgDetails> => ({
  type: ActionTypes.UPDATE_PACKAGE_DETAILS,
  payload,
});

export const installPackage = (
  payload: InstallPkgActionPaylod,
): MarketplaceAction<InstallPkgActionPaylod> => ({
  type: ActionTypes.INSTALL_PACKAGE,
  payload,
});

export const clearPackageDetails = (): MarketplaceAction => ({
  type: ActionTypes.UPDATE_PACKAGE_DETAILS,
});

export const setShowProjectSelectModal = (payload: boolean): MarketplaceAction<boolean> => ({
  type: ActionTypes.SET_SHOW_PROJECT_SELECT_MODAL,
  payload,
});
