export enum DeploymentActionTypes {
  GET_DEPLOYMENT_LIST = 'GET_DEPLOYMENT_LIST',
  SET_DEPLOYMENT_LIST = 'SET_DEPLOYMENT_LIST',
  SET_DEPLOYMENT_LIST_API_STATUS = 'SET_DEPLOYMENT_LIST_API_STATUS',
  DELETE_DEPLOYMENT = 'DELETE_DEPLOYMENT',
  GET_DEPLOYMENT_DETAILS = 'GET_DEPLOYMENT_DETAILS',
  SET_DEPLOYMENT_DETAILS = 'SET_DEPLOYMENT_DETAILS',
  SET_DEPLOYMENT_DETAILS_API_STATUS = 'SET_DEPLOYMENT_DETAILS_API_STATUS',
  RESTART_DEPLOYMENT = 'RESTART_DEPLOYMENT',
  GET_DEPLOYMENT_HISTORY = 'GET_DEPLOYMENT_HISTORY',
  SET_DEPLOYMENT_HISTORY = 'SET_DEPLOYMENT_HISTORY',
  SET_DEPLOYMENT_HISTORY_API_STATUS = 'SET_DEPLOYMENT_HISTORY_API_STATUS',
  GET_PACKAGE_DETAILS = 'GET_PACKAGE_DETAILS',
  SET_PACKAGE_DETAILS = 'SET_PACKAGE_DETAILS',
  SET_PACKAGE_DETAILS_API_STATUS = 'SET_PACKAGE_DETAILS_API_STATUS',
  DOWNLOAD_DEPLOYMENT_MANIFEST = 'DOWNLOAD_DEPLOYMENT_MANIFEST',
  GET_DEPENDENCY_GRAPH_DATA = 'GET_DEPENDENCY_GRAPH_DATA',
  SET_DEPENDENCY_GRAPH_DATA = 'SET_DEPENDENCY_GRAPH_DATA',
  SET_DEPENDENCY_GRAPH_DATA_API_STATUS = 'SET_DEPENDENCY_GRAPH_DATA_API_STATUS',
  SET_DOWNLOAD_DEPLOYMENT_MANIFEST_API_STATUS = 'SET_DOWNLOAD_DEPLOYMENT_MANIFEST_API_STATUS',
  SET_COLLECT_ROSBAGS_STATUS = 'SET_COLLECT_ROSBAGS_STATUS',
  SET_UPDATE_ROSBAG_JOB_STATUS = 'SET_UPDATE_ROSBAG_JOB_STATUS',
}

export default DeploymentActionTypes;
