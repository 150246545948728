import { AxiosError } from 'axios';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';

import { APICallStatus } from 'Models/common/types';
import {
  addConfigurationApi,
  cloneConfigurationApi,
  deleteConfigurationNodeApi,
  getConfigurationsApi,
  renameConfigurationApi,
} from 'Models/configurations/service';
import {
  CloneConfigPayload,
  ConfigurationAction,
  RenameConfigPayload,
} from 'Models/configurations/types';
import { changeSelectedProject } from 'RioRedux/common/actions';
import {
  setCloneAPIStatus,
  setCreateAPIStatus,
  setDeleteAPIStatus,
  setListAPIStatus,
  setRenameAPIStatus,
  updateConfigList,
} from 'RioRedux/configurations/actions';
import ActionTypes from 'RioRedux/configurations/actionTypes';
import { setNotificationFail, setNotificationSuccess } from 'RioRedux/notifications/action';
import { getMessageFromError } from 'Shared/utils/common';
import { CallReturnType } from 'Types/saga';

function* getConfigurations() {
  try {
    yield put(setListAPIStatus(APICallStatus.LOADING));

    const configs: CallReturnType<typeof getConfigurationsApi> = yield call(getConfigurationsApi);

    yield put(updateConfigList(configs.data.data));
    yield put(setListAPIStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setListAPIStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    yield put(
      setNotificationFail({
        message: err?.message,
        description: getMessageFromError(err),
      }),
    );
  }
}

function* createConfig({ payload }: ConfigurationAction<string>) {
  try {
    yield put(setCreateAPIStatus(APICallStatus.LOADING));

    yield call(addConfigurationApi, payload);

    yield put(setCreateAPIStatus(APICallStatus.LOADED));
    yield put(
      setNotificationSuccess({
        message: `${payload} Successfully Created!`,
      }),
    );
    yield call(getConfigurations);
  } catch (error) {
    yield put(setCreateAPIStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    yield put(
      setNotificationFail({
        message: err?.message,
        description: getMessageFromError(err),
      }),
    );
  }
}

function* renameConfig({ payload }: ConfigurationAction<RenameConfigPayload>) {
  try {
    const { treeName, newTreeName } = payload;
    yield put(setRenameAPIStatus(APICallStatus.LOADING));

    yield call(renameConfigurationApi, treeName, newTreeName);

    yield put(setRenameAPIStatus(APICallStatus.LOADED));
    yield put(
      setNotificationSuccess({
        message: `${newTreeName} Successfully Renamed!`,
      }),
    );
    yield call(getConfigurations);
  } catch (error) {
    yield put(setRenameAPIStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    yield put(
      setNotificationFail({
        message: err?.message,
        description: getMessageFromError(err),
      }),
    );
  }
}

function* deleteConfig({ payload }: ConfigurationAction<string>) {
  try {
    yield put(setDeleteAPIStatus(APICallStatus.LOADING));

    yield call(deleteConfigurationNodeApi, `tree/${payload}`);

    yield put(setDeleteAPIStatus(APICallStatus.LOADED));
    yield put(
      setNotificationSuccess({
        message: `${payload} Successfully Deleted!`,
      }),
    );
    yield call(getConfigurations);
  } catch (error) {
    yield put(setDeleteAPIStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    yield put(
      setNotificationFail({
        message: err?.message,
        description: getMessageFromError(err),
      }),
    );
  }
}

function* cloneConfig({ payload }: ConfigurationAction<CloneConfigPayload>) {
  try {
    const { treeName, newTreeName, projectGuid } = payload;
    yield put(setCloneAPIStatus(APICallStatus.LOADING));

    yield call(cloneConfigurationApi, treeName, newTreeName, projectGuid);
    yield put(
      setNotificationSuccess({
        message: `${newTreeName} Successfully Cloned!`,
      }),
    );
    yield put(setCloneAPIStatus(APICallStatus.LOADED));
    yield put(changeSelectedProject(projectGuid));
  } catch (error) {
    yield put(setCloneAPIStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    yield put(
      setNotificationFail({
        message: 'Failed to Clone Configuration',
        description: getMessageFromError(err),
      }),
    );
  }
}

export default function* configuratonSaga(): IterableIterator<ForkEffect<never>> {
  yield takeLatest(ActionTypes.GET_LIST, getConfigurations);
  yield takeLatest(ActionTypes.CREATE_CONFIG, createConfig);
  yield takeLatest(ActionTypes.RENAME_CONFIG, renameConfig);
  yield takeLatest(ActionTypes.DELETE_CONFIG, deleteConfig);
  yield takeLatest(ActionTypes.CLONE_CONFIG, cloneConfig);
}
