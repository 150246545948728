import { Deployment, ExecutablesStatusInfo } from '../deployments/types';

import { GroupByRanges, LimitOption, MetricData, MetricQuery, QueryJoiners } from './types';

const mapDatesAndGetGroupBy = (lower: number, upper: number) => {
  const epochToNanoMult = 10_00_000;

  const oneMinute = 60 * 1_000;
  const fifteenMinutes = 15 * oneMinute;
  const oneHour = 60 * oneMinute;
  const twelveHours = 12 * oneHour;
  const oneDay = 24 * oneHour;
  const twoDays = 2 * oneDay;
  const oneWeek = 7 * oneDay;

  const diff: number = upper - lower;

  let groupBy: GroupByRanges = GroupByRanges.THIRTY_SECONDS;

  if (diff >= fifteenMinutes && diff < twelveHours) {
    groupBy = GroupByRanges.ONE_MINUTE;
  } else if (diff >= twelveHours && diff < oneDay) {
    groupBy = GroupByRanges.FIVE_MINUTES;
  } else if (diff >= oneDay && diff < twoDays) {
    groupBy = GroupByRanges.TEN_MINUTES;
  } else if (diff >= twoDays && diff < oneWeek) {
    groupBy = GroupByRanges.THIRTY_MINUTES;
  } else if (diff >= oneWeek) {
    groupBy = GroupByRanges.ONE_HOUR;
  }

  return {
    lowerBound: lower * epochToNanoMult,
    upperBound: upper * epochToNanoMult,
    groupBy,
  };
};

export const mapMetricOptionsToQuery = (
  queryOption: MetricQuery,
  limitOption: LimitOption,
): string => {
  let query = `SELECT ${queryOption.select.join(QueryJoiners.SELECT)}`;
  let groupBy = limitOption.defaultGroupBy;

  query += ` FROM ${queryOption.from}`;

  const whereQuery = queryOption.where ?? [];

  if (limitOption.lowerBound && limitOption.upperBound) {
    const {
      lowerBound,
      upperBound,
      groupBy: mappedGroupBy,
    } = mapDatesAndGetGroupBy(limitOption.lowerBound as number, limitOption.upperBound as number);
    groupBy = mappedGroupBy;
    whereQuery.push(`time >= ${lowerBound}`, `time <= ${upperBound}`);
  } else {
    whereQuery.push(`time > ${limitOption.lowerBound}`);
  }

  query += ` WHERE ${whereQuery.join(QueryJoiners.WHERE)}`;

  const groupByQuery = [`time(${groupBy})`].concat(queryOption.groupBy ?? []);
  query += ` GROUP BY ${groupByQuery.join(QueryJoiners.GROUP_BY)}`;

  return `${query};`;
};

const CB_PREFIX = 'cloud-bridge';

const mapCloudBridgeToRoutedNet = (cbID: string, deployment: Deployment): string => {
  let cloudBridgeInfo: ExecutablesStatusInfo | undefined;

  deployment.componentInfo.every((component) => {
    cloudBridgeInfo = component.executablesStatusInfo.find((exec) => exec.id === cbID);

    if (cloudBridgeInfo) {
      return false;
    }

    return true;
  });

  if (!cloudBridgeInfo) {
    return CB_PREFIX;
  }

  const networkInfo = deployment.coreNetworks?.find(
    (network) => network.networkGUID === cloudBridgeInfo?.networkInfo?.networkGUID,
  );

  if (!networkInfo) {
    return CB_PREFIX;
  }

  const name = networkInfo.RoutedNetwork?.name ?? '';

  if (!name) {
    return CB_PREFIX;
  }

  return `${CB_PREFIX}-${name}`;
};

const mapExecIDToExecName = (execID: string, deployment: Deployment): string | null => {
  let execName = null;
  const truncatedId = execID.replace('container-', '');

  if (truncatedId.includes(CB_PREFIX)) {
    return mapCloudBridgeToRoutedNet(truncatedId, deployment);
  }

  deployment.componentInfo.every((component) => {
    const executable = component.executableMetaData?.find((exec) => exec.id === truncatedId);

    if (executable) {
      execName = executable.name;

      return false;
    }

    return true;
  });

  return execName;
};

const mapCompIDToCompName = (compID: string, deployment: Deployment): string | null => {
  const component = deployment.componentInfo.find((comp) => comp.componentID === compID);

  return component?.name ?? null;
};

export const mapResourceIDsToNames = (
  data: MetricData,
  deployment: Deployment | null,
): MetricData => {
  if (!deployment) {
    return data;
  }

  const mappedData = { ...data };

  mappedData.columns = mappedData.columns.map((column) => {
    const mappedColumnStr = column.replace(/{.*}/, (matchedStr) => {
      const instanceInfo = matchedStr.substring(1, matchedStr.length - 1);
      const instances = instanceInfo.split(',');

      const instanceInfoArr = instances.map((instance) => {
        /* eslint-disable-next-line prefer-const */
        let [instanceType, instanceID] = instance.split('=');

        let instanceName = instanceID;
        switch (instanceType) {
          case 'executable_id': {
            instanceType = 'executable';
            instanceName = mapExecIDToExecName(instanceID, deployment) ?? instanceID;

            break;
          }

          case 'component_id': {
            instanceType = 'component';
            instanceName = mapCompIDToCompName(instanceID, deployment) ?? instanceID;

            break;
          }

          default:
            instanceName = instanceID;
        }

        return `${instanceType}=${instanceName}`;
      });

      return `{${instanceInfoArr.join(',')}}`;
    });

    return mappedColumnStr.replace('rr_io_', '');
  });

  return mappedData;
};
