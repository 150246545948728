import {
  ClonePackageDetails,
  PackageDeploymentList,
  PackageList,
  PackagesAction,
  PackagesPayload,
  PackageSpecification,
  PackagesState,
} from 'Models/packages/types';
import { APICallStatus } from 'Root/models/common/types';
import { Secret } from 'Root/models/secrets/types';

import PackagesActionTypes from './actionTypes';

const initialState: PackagesState = {
  packagesList: [],
  listAPIStatus: APICallStatus.INITIAL,
  apiStatus: APICallStatus.INITIAL,
  importPackageModalVisibility: false,
  createPackageModalVisibility: false,
  packageDetailsAPIStatus: APICallStatus.INITIAL,
  packageDetails: null,
  clonePackageDetails: {
    name: '',
    version: '',
    isClone: false,
    destinationProject: '',
    showSecretCloneModal: false,
  },
  secretsList: null,
  secretsListAPICallStatus: APICallStatus.INITIAL,
  destinationSecretsList: null,
  destinationSecretsListAPICallStatus: APICallStatus.INITIAL,
  downloadManifestAPICallStatus: APICallStatus.INITIAL,
  packageDeploymentList: [],
  packageDeploymentListAPICallStatus: APICallStatus.INITIAL,
};

const packagesReducer = (
  state: PackagesState = initialState,
  action: PackagesAction<PackagesPayload>,
): PackagesState => {
  switch (action.type) {
    case PackagesActionTypes.SET_PACKAGES_LIST:
      return {
        ...state,
        packagesList: action.payload as PackageList[],
      };
    case PackagesActionTypes.SET_PACKAGES_LIST_API_STATUS:
      return {
        ...state,
        listAPIStatus: action.payload as APICallStatus,
      };
    case PackagesActionTypes.IMPORT_PACKAGE_MODAL_VISIBILITY:
      return {
        ...state,
        importPackageModalVisibility: action.payload as boolean,
      };
    case PackagesActionTypes.SET_CREATE_PACKAGE_API_STATUS:
      return {
        ...state,
        apiStatus: action.payload as APICallStatus,
      };
    case PackagesActionTypes.CREATE_PACKAGE_MODAL_VISIBILITY:
      return {
        ...state,
        createPackageModalVisibility: action.payload as boolean,
      };
    case PackagesActionTypes.SET_PACKAGE_DETAILS:
      return {
        ...state,
        packageDetails: action.payload as PackageSpecification,
      };
    case PackagesActionTypes.SET_PACKAGE_DETAILS_API_STATUS:
      return {
        ...state,
        packageDetailsAPIStatus: action.payload as APICallStatus,
      };
    case PackagesActionTypes.SET_CLONE_PACKAGE_DETAILS: {
      return {
        ...state,
        clonePackageDetails: action.payload as ClonePackageDetails,
      };
    }
    case PackagesActionTypes.SET_SECRETS_LIST: {
      return {
        ...state,
        secretsList: action.payload as Secret[],
      };
    }
    case PackagesActionTypes.SET_SECRETS_LIST_API_STATUS: {
      return {
        ...state,
        secretsListAPICallStatus: action.payload as APICallStatus,
      };
    }
    case PackagesActionTypes.SET_DESTINATION_SECRETS_LIST: {
      return {
        ...state,
        destinationSecretsList: action.payload as Secret[],
      };
    }
    case PackagesActionTypes.SET_DESTINATION_SECRETS_LIST_API_STATUS: {
      return {
        ...state,
        secretsListAPICallStatus: action.payload as APICallStatus,
      };
    }
    case PackagesActionTypes.DOWNLOAD_PACKAGE_MANIFEST_API_STATUS: {
      return {
        ...state,
        downloadManifestAPICallStatus: action.payload as APICallStatus,
      };
    }
    case PackagesActionTypes.SET_PACKAGE_DEPLOYMENT_LIST: {
      return {
        ...state,
        packageDeploymentList: action.payload as PackageDeploymentList[],
      };
    }
    case PackagesActionTypes.SET_PACKAGE_DEPLOYMENT_LIST_API_STATUS: {
      return {
        ...state,
        packageDeploymentListAPICallStatus: action.payload as APICallStatus,
      };
    }
    default:
      return state;
  }
};

export default packagesReducer;
