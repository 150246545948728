export const CONFIGURATION_NODE_NAMES = {
  AttributeNode: 'attribute',
  FileNode: 'file',
  ValueNode: 'value',
  FolderNode: 'folder',
};

export const CONFIGURATION_NODE_ORDERING = {
  FileNode: 0,
  ValueNode: 1,
  AttributeNode: 2,
  FolderNode: 3,
};

export const CONFIGURATION_NODE_TYPES = {
  attribute: 'AttributeNode',
  file: 'FileNode',
  value: 'ValueNode',
  folder: 'FolderNode',
};

export const configurationNameRegex = /^[a-zA-Z0-9]([a-zA-Z0-9_-]*)+$/;

export const configurationNameValidationMessage =
  'Name must consist of alphanumeric characters, hyphen or underscore and must begin with an alphanumeric character';

export const NODE_COLORS = [
  '#3cb44b',
  '#4363d8',
  '#ffe119',
  '#f58231',
  '#e6194b',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#ffffff',
  '#000000',
];

export const YAML_TYPES = {
  MAP: {
    label: 'Map',
    tag: 'tag:yaml.org,2002:map',
  },
  STR: {
    label: 'String',
    tag: 'tag:yaml.org,2002:str',
  },
  SEQ: {
    label: 'Sequence',
    tag: 'tag:yaml.org,2002:seq',
  },
  INT: {
    label: 'Int',
    tag: 'tag:yaml.org,2002:int',
  },
  FLOAT: {
    label: 'Float',
    tag: 'tag:yaml.org,2002:float',
  },
  BOOL: {
    label: 'Bool',
    tag: 'tag:yaml.org,2002:bool',
  },
  BINARY: {
    label: 'Binary',
    tag: 'tag:yaml.org,2002:binary',
  },
  TIMESTAMP: {
    label: 'Timestamp',
    tag: 'tag:yaml.org,2002:timestamp',
  },
  NULL: {
    label: 'Null',
    tag: 'tag:yaml.org,2002:null',
  },
};

export const NODE_FILE_TYPES = {
  YAML: 'YAML',
  BINARY: 'Binary',
  JSON: 'JSON',
};

export const FILE_EXT_MAP = {
  [NODE_FILE_TYPES.YAML]: '.yaml',
  [NODE_FILE_TYPES.JSON]: '.json',
};

export const FILE_CONTENT_TYPE_MAP = {
  [NODE_FILE_TYPES.YAML]: 'text/yaml',
  [NODE_FILE_TYPES.JSON]: 'application/json',
};
