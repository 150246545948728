import { APICallStatus } from 'Root/models/common/types';
import { DeployPackageAction, DeployPackagePayloadData } from 'Root/models/deployPackage/types';
import { Device } from 'Root/models/devices/types';
import { Disk } from 'Root/models/disks/types';
import { Network } from 'Root/models/networks/types';
import { GetDeletePackagePayload, PackageSpecification } from 'Root/models/packages/types';

import DeployPackageActionTypes from './actionTypes';

export const setDeployPackageModalVisibility = (
  payload: boolean,
): DeployPackageAction<boolean> => ({
  type: DeployPackageActionTypes.SET_DEPLOY_PACKAGE_MODAL_VISIBILITY,
  payload,
});

export const getDeploymentNamesList = (): DeployPackageAction => ({
  type: DeployPackageActionTypes.GET_DEPLOYMENT_NAMES_LIST,
});

export const setDeploymentNamesList = (payload: string[]): DeployPackageAction<string[]> => ({
  type: DeployPackageActionTypes.SET_DEPLOYMENT_NAMES_LIST,
  payload,
});

export const setDeploymentNamesListAPICallStatus = (
  payload: APICallStatus,
): DeployPackageAction<APICallStatus> => ({
  type: DeployPackageActionTypes.SET_DEPLOYMENT_NAMES_LIST_API_STATUS,
  payload,
});

export const getDeployPackagePackageDetails = (
  payload: GetDeletePackagePayload,
): DeployPackageAction<GetDeletePackagePayload> => ({
  type: DeployPackageActionTypes.GET_DEPLOY_PACKAGE_PACKAGE_DETAILS,
  payload,
});

export const setDeployPackagePackageDetails = (
  payload: PackageSpecification | null,
): DeployPackageAction<PackageSpecification | null> => ({
  type: DeployPackageActionTypes.SET_DEPLOY_PACKAGE_PACKAGE_DETAILS,
  payload,
});

export const setDeployPackagePackageDetailsAPICallStatus = (
  payload: APICallStatus,
): DeployPackageAction<APICallStatus> => ({
  type: DeployPackageActionTypes.SET_DEPLOY_PACKAGE_PACKAGE_DETAILS_API_STATUS,
  payload,
});

export const getDeployPackageDeviceList = (): DeployPackageAction => ({
  type: DeployPackageActionTypes.GET_DEPLOY_PACKAGE_DEVICES_LIST,
});

export const setDeployPackageDeviceList = (payload: Device[]): DeployPackageAction<Device[]> => ({
  type: DeployPackageActionTypes.SET_DEPLOY_PACKAGE_DEVICES_LIST,
  payload,
});

export const setDeployPackageDeviceListAPICallStatus = (
  payload: APICallStatus,
): DeployPackageAction<APICallStatus> => ({
  type: DeployPackageActionTypes.SET_DEPLOY_PACKAGE_DEVICES_LIST_API_STATUS,
  payload,
});

export const getDeployPackageDiskList = (): DeployPackageAction => ({
  type: DeployPackageActionTypes.GET_DEPLOY_PACKAGE_DISK_LIST,
});

export const setDeployPackageDiskList = (payload: Disk[]): DeployPackageAction<Disk[]> => ({
  type: DeployPackageActionTypes.SET_DEPLOY_PACKAGE_DISK_LIST,
  payload,
});

export const setDeployPackageDiskListAPICallStatus = (
  payload: APICallStatus,
): DeployPackageAction<APICallStatus> => ({
  type: DeployPackageActionTypes.SET_DEPLOY_PACKAGE_DISK_LIST_API_STATUS,
  payload,
});

export const getDeployPackageNetworkList = (): DeployPackageAction => ({
  type: DeployPackageActionTypes.GET_DEPLOY_PACKAGE_NETWORK_LIST,
});

export const setDeployPackageNetworkList = (
  payload: Network[],
): DeployPackageAction<Network[]> => ({
  type: DeployPackageActionTypes.SET_DEPLOY_PACKAGE_NETWORK_LIST,
  payload,
});

export const setDeployPackageNetworkListAPICallStatus = (
  payload: APICallStatus,
): DeployPackageAction<APICallStatus> => ({
  type: DeployPackageActionTypes.SET_DEPLOY_PACKAGE_NETWORK_LIST_API_STATUS,
  payload,
});

export const createDeployment = (
  payload: DeployPackagePayloadData,
): DeployPackageAction<DeployPackagePayloadData> => ({
  type: DeployPackageActionTypes.CREATE_DEPLOYMENT,
  payload,
});

export const createDeploymentAPICallStatus = (
  payload: APICallStatus,
): DeployPackageAction<APICallStatus> => ({
  type: DeployPackageActionTypes.CREATE_DEPLOYMENT_API_STATUS,
  payload,
});
