import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';

import { APICallStatus } from 'Models/common/types';
import { getMplacePkgDetails, installMplacePackage } from 'Models/marketplace/service';
import { ErrorMessages, InstallPkgActionPaylod, MarketplaceAction } from 'Models/marketplace/types';
import { NotificationPayload } from 'Models/notifications/types';
import { changeSelectedProject } from 'RioRedux/common/actions';
import { CallReturnType } from 'Types/saga';

import { setNotificationFail } from '../notifications/action';

import { setAPICallStatus, updatePackageDetails } from './actions';
import MarketplaceActionTypes from './actionTypes';

function* getPackageDetails(action: MarketplaceAction<string>) {
  try {
    yield put(setAPICallStatus(APICallStatus.LOADING));

    const packageDetails: CallReturnType<typeof getMplacePkgDetails> = yield call(
      getMplacePkgDetails,
      action.payload,
    );

    yield put(updatePackageDetails(packageDetails));
    yield put(setAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorPayload: NotificationPayload = {
      message: err?.message,
      description: err.response?.data?.error ?? ErrorMessages.PACKAGE_DETAILS,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* installPackage(action: MarketplaceAction<InstallPkgActionPaylod>) {
  try {
    yield put(setAPICallStatus(APICallStatus.LOADING));

    const { payload } = action;

    yield call(installMplacePackage, payload.slug, payload.version, payload.projectID);

    yield put(setAPICallStatus(APICallStatus.LOADED));
    yield put(push('/packages'));
    if (payload.projectID) {
      yield put(changeSelectedProject(payload.projectID));
    }
  } catch (error) {
    yield put(setAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorPayload: NotificationPayload = {
      message: err?.message,
      description: err.response?.data?.error ?? ErrorMessages.PACKAGE_INSTALL,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

export default function* marketplaceSaga(): IterableIterator<ForkEffect<never>> {
  yield takeLatest(MarketplaceActionTypes.GET_PACKAGE_DETAILS, getPackageDetails);
  yield takeLatest(MarketplaceActionTypes.INSTALL_PACKAGE, installPackage);
}
