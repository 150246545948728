import { APICallStatus, RIOError } from 'Models/common/types';
import { Secret, SecretAction, SecretActionPayload, SecretState } from 'Models/secrets/types';

import SecretActionTypes from './actionTypes';

const initialState: SecretState = {
  list: null,
  error: null,
  addModalOpen: false,
  createPending: false,
  apiStatus: APICallStatus.INITIAL,
  editAPIStatus: APICallStatus.INITIAL,
};

export default (
  state: SecretState = initialState,
  action: SecretAction<SecretActionPayload>,
): SecretState => {
  switch (action.type) {
    case SecretActionTypes.SOURCE_SECRETS_ADD_MODAL_TOGGLE:
      return {
        ...state,
        addModalOpen: !state.addModalOpen,
      };

    case SecretActionTypes.SOURCE_SECRETS_CREATE_PENDING:
      return {
        ...state,
        createPending: true,
      };
    case SecretActionTypes.SOURCE_SECRETS_CREATE_FULFILLED:
      return {
        ...state,
        addModalOpen: false,
        createPending: false,
      };
    case SecretActionTypes.SOURCE_SECRETS_CREATE_REJECTED:
      return {
        ...state,
        createPending: false,
      };
    case SecretActionTypes.SOURCE_SECRETS_GET_PENDING:
      return {
        ...state,
        error: null,
        apiStatus: APICallStatus.LOADING,
      };
    case SecretActionTypes.SOURCE_SECRETS_GET_FULFILLED:
      return {
        ...state,
        list: action.payload as Secret[],
        error: null,
        apiStatus: APICallStatus.LOADED,
      };
    case SecretActionTypes.SOURCE_SECRETS_GET_REJECTED:
      return {
        ...state,
        apiStatus: APICallStatus.LOADED,
        error: action.payload as RIOError,
      };
    case SecretActionTypes.SOURCE_SECRET_DELETE_FULFILLED:
      return {
        ...state,
        list: state.list?.filter((i: Secret) => i.name !== action.payload) as Secret[],
      };
    case SecretActionTypes.SOURCE_SECRET_EDIT_API_STATUS:
      return {
        ...state,
        editAPIStatus: action.payload as APICallStatus,
      };
    default:
      return state;
  }
};
