import { AppAction } from 'Models/common/types';
import {
  NotificationAction,
  NotificationPayload,
  NotificationState,
  NotificationType,
} from 'Models/notifications/types';

import ActionTypes from './actionTypes';

export const setNotificationFail = (
  payload: NotificationPayload,
): NotificationAction<NotificationState> => ({
  type: ActionTypes.ERROR,
  payload: { ...payload, showNotification: true, type: NotificationType.ERROR },
});

export const setNotificationSuccess = (
  payload: NotificationPayload,
): NotificationAction<NotificationState> => ({
  type: ActionTypes.SUCCESS,
  payload: { ...payload, showNotification: true, type: NotificationType.SUCCESS },
});

export const setNotificationInfo = (
  payload: NotificationPayload,
): NotificationAction<NotificationState> => ({
  type: ActionTypes.INFO,
  payload: { ...payload, showNotification: true, type: NotificationType.INFO },
});

export const setNotificationWarning = (
  payload: NotificationPayload,
): NotificationAction<NotificationState> => ({
  type: ActionTypes.WARNING,
  payload: { ...payload, showNotification: true, type: NotificationType.WARNING },
});

export const hideNotification = (): AppAction => ({
  type: ActionTypes.HIDE,
});

export const dismissNotification = (payload: string): NotificationAction<string> => ({
  type: ActionTypes.DISMISS,
  payload,
});
