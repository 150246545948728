import { APICallStatus } from 'Models/common/types';
import {
  CloneConfigPayload,
  ConfigurationAction,
  RenameConfigPayload,
} from 'Models/configurations/types';

import ActionTypes from './actionTypes';

export const updateConfigList = (payload: string[]): ConfigurationAction<string[]> => ({
  type: ActionTypes.SET_LIST_API_DATA,
  payload,
});

export const setListAPIStatus = (payload: APICallStatus): ConfigurationAction<APICallStatus> => ({
  type: ActionTypes.SET_LIST_API_STATUS,
  payload,
});

export const getConfigList = (): ConfigurationAction => ({
  type: ActionTypes.GET_LIST,
});

export const renameConfig = (
  payload: RenameConfigPayload,
): ConfigurationAction<RenameConfigPayload> => ({
  type: ActionTypes.RENAME_CONFIG,
  payload,
});

export const setRenameAPIStatus = (payload: APICallStatus): ConfigurationAction<APICallStatus> => ({
  type: ActionTypes.SET_LIST_API_STATUS,
  payload,
});

export const createConfig = (payload: string): ConfigurationAction<string> => ({
  type: ActionTypes.CREATE_CONFIG,
  payload,
});

export const setCreateAPIStatus = (payload: APICallStatus): ConfigurationAction<APICallStatus> => ({
  type: ActionTypes.SET_LIST_API_STATUS,
  payload,
});

export const deleteConfig = (payload: string): ConfigurationAction<string> => ({
  type: ActionTypes.DELETE_CONFIG,
  payload,
});

export const setDeleteAPIStatus = (payload: APICallStatus): ConfigurationAction<APICallStatus> => ({
  type: ActionTypes.SET_LIST_API_STATUS,
  payload,
});

export const cloneConfig = (
  payload: CloneConfigPayload,
): ConfigurationAction<CloneConfigPayload> => ({
  type: ActionTypes.CLONE_CONFIG,
  payload,
});

export const setCloneAPIStatus = (payload: APICallStatus): ConfigurationAction<APICallStatus> => ({
  type: ActionTypes.SET_LIST_API_STATUS,
  payload,
});
