import { AxiosPromise } from 'axios';

import { CATALOG_ROOT_URL, DEPLOYMENT_URL } from 'Shared/config';
import storeUtilAdapterInst from 'Shared/utils/storeUtilsAdapter';
import { authAxios, getTokenOrLogout } from 'Shared/utils/user';

import { DeploymentSpecification } from '../deployPackage/types';

import { transformDeploymentHistory, transformDeploymentList } from './dataFormatter';
import {
  APIRosbag,
  APIRosbagBlob,
  CreateRosbagRequest,
  DependencyGraphData,
  Deployment,
  DeploymentHistory,
  DeploymentPhase,
  DownloadBlob,
  GetDeploymentDetailsPayload,
  LiveLogsRequestOptions,
  StopRosbagRequest,
  UpdateROSBagJobParams,
} from './types';

const rosBagJobURL = `${CATALOG_ROOT_URL}rosbag-jobs`;
const rosBagBlobURL = `${CATALOG_ROOT_URL}rosbag-blobs`;

export const getDeploymentLogsStreamApi = async ({
  dataCallback,
  endCallback,
  executable,
  replica,
  deploymentName,
}: LiveLogsRequestOptions): Promise<void> => {
  const userToken = getTokenOrLogout();
  const project = storeUtilAdapterInst.getSelectedProjectGUID();
  const queryString = new URLSearchParams({
    executable,
    replica,
  }).toString();

  const response = await fetch(`${DEPLOYMENT_URL}${deploymentName}/logs/?${queryString}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${userToken}`,
      ...(project ? { project } : {}),
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const reader = response.body?.getReader();
  const decoder = new TextDecoder();

  if (reader) {
    let done = false;

    while (!done) {
      // eslint-disable-next-line no-await-in-loop
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      if (value) {
        const chunk = decoder.decode(value, { stream: !done });
        dataCallback(chunk, response);
      }
    }

    endCallback(response);
  } else {
    endCallback(response);
  }
};

export const getRosbagJobList = (deploymentId: string): AxiosPromise<APIRosbag[]> =>
  authAxios({
    method: 'GET',
    url: `${rosBagJobURL}/${deploymentId}`,
  });

export const getRosbagBlobs = (deploymentID: string): AxiosPromise<APIRosbagBlob[]> =>
  authAxios({
    method: 'GET',
    url: `${rosBagBlobURL}`,
    params: {
      deploymentID,
    },
  });

export const stopRosbagJob = ({
  deploymentID,
  jobGuids,
}: StopRosbagRequest): AxiosPromise<void> => {
  const params = new URLSearchParams();
  jobGuids.forEach((guid) => params.append('guid', guid));
  return authAxios({
    method: 'PATCH',
    url: `${rosBagJobURL}/${deploymentID}`,
    params,
  });
};

export const downloadRosbagBlob = (blobGuid: string): AxiosPromise<DownloadBlob> =>
  authAxios({
    method: 'GET',
    url: `${rosBagBlobURL}/${blobGuid}/file`,
  });

export const deleteRosbagBlob = (blobGuid: string): AxiosPromise<void> =>
  authAxios({
    method: 'DELETE',
    url: `${rosBagBlobURL}/${blobGuid}`,
  });

export const createRosbagJob = ({
  deploymentID,
  data,
}: CreateRosbagRequest): AxiosPromise<APIRosbag> =>
  authAxios({
    method: 'POST',
    url: `${rosBagJobURL}/${deploymentID}`,
    data,
  });

export const updateROSBagJob = (params: UpdateROSBagJobParams): AxiosPromise<void> => {
  const url = `${rosBagJobURL}/${params.deploymentID}/job/${params.jobID}`;
  const data: Record<string, any> = {
    componentInstanceID: params.componentInstanceID,
  };

  if (params.uploadType) {
    data.uploadOptions = {};
    data.uploadOptions.uploadType = params.uploadType;
  }

  if (params.timeRange) {
    if (!data.uploadOptions) {
      data.uploadOptions = {};
    }

    data.uploadOptions.onDemandOpts = {
      timeRange: params.timeRange,
    };
  }

  return authAxios({
    method: 'PATCH',
    url,
    data,
  });
};

export const getDeploymentList = (
  phases: DeploymentPhase[],
  deviceName = '',
): Promise<Deployment[]> => {
  const params = new URLSearchParams();
  phases?.forEach((phase) => params.append('phases', phase));
  if (deviceName) {
    params.append('deviceName', deviceName);
  }
  return authAxios(
    {
      method: 'GET',
      url: DEPLOYMENT_URL,
      params,
    },
    true,
    true,
  ).then((response) => transformDeploymentList(response.data?.items || []));
};

export const deleteDeployment = (name: string): AxiosPromise<void> =>
  authAxios(
    {
      method: 'DELETE',
      url: `${DEPLOYMENT_URL}${name}/`,
    },
    true,
    true,
  );

export const getDeploymentDetails = ({
  name,
  guid,
}: GetDeploymentDetailsPayload): Promise<DeploymentSpecification> => {
  const params = new URLSearchParams();
  params.append('guid', guid);
  return authAxios(
    {
      method: 'GET',
      url: `${DEPLOYMENT_URL}${name}/`,
      params,
    },
    true,
    true,
  ).then((response) => response.data);
};

export const restartDeployment = (name: string): AxiosPromise<void> =>
  authAxios(
    {
      method: 'PATCH',
      url: `${DEPLOYMENT_URL}${name}/`,
      data: {
        metadata: {
          name,
        },
      },
    },
    true,
    true,
  );

export const getDeploymentHistory = ({
  name,
  guid,
}: GetDeploymentDetailsPayload): Promise<DeploymentHistory[]> => {
  const params = new URLSearchParams();
  params.append('guid', guid);
  return authAxios(
    {
      method: 'GET',
      url: `${DEPLOYMENT_URL}${name}/history/`,
      params,
    },
    true,
    true,
  ).then((response) => transformDeploymentHistory(response.data.items));
};

export const getDependencyGraph = (name: string): Promise<DependencyGraphData> =>
  authAxios(
    {
      method: 'GET',
      url: `${DEPLOYMENT_URL}${name}/graph/`,
    },
    true,
    true,
  ).then((response) => response.data);
