import { mapAPIPlanMetaToPlanMeta } from '../common/dataFormatters';

import { APICountry, APIOrganization, Country, Organization, ProvinceOption } from './types';

export const mapAPICountryToCountry = (apiCountry: APICountry): Country => ({
  id: apiCountry.ID,
  createdAt: apiCountry.CreatedAt,
  updatedAt: apiCountry.UpdatedAt,
  deletedAt: apiCountry.DeletedAt,
  name: apiCountry.name,
  code: apiCountry.code,
  hasProvinces: apiCountry.hasProvinces,
});

export const mapAPIOrgToOrg = ({
  country,
  planMetadata,
  ID,
  CreatedAt,
  UpdatedAt,
  DeletedAt,
  ...rest
}: APIOrganization): Organization => ({
  ...rest,
  id: ID,
  createdAt: CreatedAt,
  deletedAt: DeletedAt,
  updatedAt: UpdatedAt,
  country: country && mapAPICountryToCountry(country),
  planMetaData: planMetadata && mapAPIPlanMetaToPlanMeta(planMetadata),
});

export const mapProvincesToProvinceOption = (provinces: { name: string }[]): ProvinceOption[] =>
  provinces
    .sort((former, latter) => former.name.localeCompare(latter.name))
    .map(({ name }) => ({ label: name, value: name }));
