import BillingActionTypes from 'RioRedux/billing/actionTypes';

import { APICallStatus } from '../common/types';

export enum ChargeType {
  OVERAGE = 'overage',
  ADD_ON = 'add-on',
}

export interface APIPlanDetail {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  name: string;
  metadata: string;
  subscriptionCharge: number;
  planType: string;
  currency: string;
  isActive: boolean;
  displayName: string;
  trialPeriodMonths: number;
}

export type BillingActionPayload = Plan[];

export interface BillingState {
  plans: Plan[];
  planDetailsApiStatus: APICallStatus;
}

export interface BillingAction<P = undefined> {
  type: BillingActionTypes;
  payload?: P;
}

export enum PlanDisplayName {
  ENTERPRISE = 'Enterprise',
  COMMUNITY = 'Community',
  PROFESSIONAL = 'Professional',
}

export enum PlanType {
  CUSTOM = 'Custom',
  PUBLIC = 'Public',
}

export enum PlanName {
  CUSTOM = 'CustomPlan-1V2',
  PROFESSIONAL = 'ProfessionalV2',
  COMMUNITY = 'CommunityV2',
}

export interface PlanLimits {
  createdAt: string;
  deletedAt: null | string;
  id: number;
  planComponentID: number;
  updatedAt: string;
  maxLimit: number;
  name: string;
}
export interface APIPlanLimits {
  CreatedAt: string;
  DeletedAt: null | string;
  ID: number;
  PlanComponentID: number;
  UpdatedAt: string;
  maxLimit: number;
  name: string;
}

export interface PlanComponents {
  createdAt: string;
  deletedAt: null | string;
  id: number;
  planId: number;
  updatedAt: string;
  charge?: number;
  chargeType: ChargeType;
  currency?: string;
  displayPriority: number;
  includedUnits?: number;
  isDisplay: boolean;
  metadata: string;
  name: string;
  unit: string;
  uomName: string;
  planLimits: PlanLimits | undefined;
}
export interface APIPlanComponents {
  CreatedAt: string;
  DeletedAt: null | string;
  ID: number;
  PlanId: number;
  UpdatedAt: string;
  charge?: number;
  chargeType: ChargeType;
  currency?: string;
  displayPriority: number;
  includedUnits?: number;
  isDisplay: boolean;
  metadata: string;
  name: string;
  unit: string;
  uomName: string;
  planLimits?: APIPlanLimits;
}

export interface Plan {
  createdAt: string;
  deletedAt: string | null;
  id: number;
  updatedAt: string;
  currency: string;
  displayName: PlanDisplayName;
  isActive: boolean;
  metadata: string;
  name: PlanName;
  planComponents: PlanComponents[];
  planType: PlanType;
  subscriptionCharge: number;
}
export interface APIPlan {
  CreatedAt: string;
  DeletedAt: string | null;
  ID: number;
  UpdatedAt: string;
  currency: string;
  displayName: PlanDisplayName;
  isActive: boolean;
  metadata: string;
  name: PlanName;
  planComponents: APIPlanComponents[];
  planType: PlanType;
  subscriptionCharge: number;
}
