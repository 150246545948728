import { METRICS_ENDPOINT } from 'Shared/config';
import { authAxios } from 'Shared/utils/user';

import { mapMetricOptionsToQuery } from './dataFormatters';
import { GetMetricParams, MetricData, MetricQuery, MetricType } from './types';

export const getMetrics = (
  type: MetricType,
  metricParams: GetMetricParams,
): Promise<MetricData> => {
  let resourceFilterPrefix = '';

  switch (type) {
    case MetricType.CLOUD: {
      resourceFilterPrefix = 'deployment_id';

      break;
    }

    case MetricType.DEVICE: {
      resourceFilterPrefix = 'device_id';

      break;
    }

    default:
      break;
  }

  const params = new URLSearchParams();
  const queryOption: MetricQuery = {
    ...metricParams.queryOption,
    where: [
      ...(metricParams.queryOption.where ?? []),
      `${resourceFilterPrefix} = '${metricParams.resourceID}'`,
    ],
  };
  params.append('q', mapMetricOptionsToQuery(queryOption, metricParams.limitOption));

  return authAxios({
    method: 'GET',
    url: `${METRICS_ENDPOINT}/v0/influx_proxy/query_formatted`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  }).then((response) => response.data.response.data);
};

export default {};
