/* eslint-disable camelcase */
import ActionTypes from 'RioRedux/disks/actionTypes';

import { APICallStatus, RIOError, RunTime } from '../common/types';
import { RegionType } from '../deployPackage/types';

export type CreateDiskFormData = {
  name: string;
  runtime: RunTime;
  capacity: number;
  labels: Array<{ key: string; value: string }>;
  region: RegionType;
};

export interface DiskSpecification {
  metadata: {
    name: string;
    labels: Record<string, string>;
    guid: string;
    createdAt: string;
    updatedAt: string;
    creatorGUID: string;
    region: RegionType;
  };
  spec: {
    runtime: string;
    capacity: number;
  };
  status: {
    status?: DiskStatus;
    capacityUsed?: number;
    capacityAvailable?: number;
    errorCode?: string;
    diskBound?: {
      deployment_guid?: string;
      deployment_name?: string;
    };
  };
}
export type CreateDiskReq = {
  metaData: Partial<DiskSpecification['metadata']>;
  spec: DiskSpecification['spec'];
};

export enum DiskStatus {
  PENDING = 'Pending',
  AVAILABLE = 'Available',
  BOUND = 'Bound',
  RELEASED = 'Released',
  FAILED = 'Failed',
}

export interface Disk {
  name: string;
  guid: string;
  region: RegionType;
  capacity: number;
  status: DiskStatus;
  internalDeploymentGUID?: string;
  usedByDeploymentName?: string;
  labels: Record<string, string>;
  createdAt: string;
  capacityUsed?: number;
  capacityAvailable?: number;
  createdBy: string;
}

export interface DisksState {
  list: Disk[];
  listApiStatus: APICallStatus;
  createDiskApiStatus: APICallStatus;
  deleteDiskApiStatus: APICallStatus;
  diskDetails: Disk;
  detailsApiStatus: APICallStatus;
  error: RIOError | null;
  downloadDiskManifestApiStatus: APICallStatus;
}

export type DisksActionPayload = Disk[] | Disk | APICallStatus;

export interface DisksAction<P = undefined> {
  type: ActionTypes;
  payload?: P;
}
