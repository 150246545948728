import React from 'react';
import { useLocation } from 'react-router-dom';

import { EmbedParameters, URLParamsEnum } from 'Types/index';

const useEmbedParams = (): EmbedParameters => {
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  return {
    [URLParamsEnum.EMBED]: urlParams.get(URLParamsEnum.EMBED),
    [URLParamsEnum.SELECTOR]: urlParams.getAll(URLParamsEnum.SELECTOR),
  };
};

export default useEmbedParams;
