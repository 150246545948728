import {
  CloudMetrics,
  CloudMetricsData,
  DeviceMetrics,
  DeviceMetricsData,
  GetMetricParams,
  GetMetricsPayload,
  MetricsAction,
} from 'Models/metrics/types';

import ActionTypes from './actionTypes';

export const getDeviceMetrics = (
  key: DeviceMetrics,
  params: GetMetricParams,
): MetricsAction<GetMetricsPayload> => ({
  type: ActionTypes.GET_DEVICE_METRICS,
  payload: {
    key,
    params,
  },
});

export const getCloudMetrics = (
  key: CloudMetrics,
  params: GetMetricParams,
): MetricsAction<GetMetricsPayload> => ({
  type: ActionTypes.GET_CLOUD_METRICS,
  payload: {
    key,
    params,
  },
});

export const updateDeviceMetricsData = (
  payload: DeviceMetricsData,
): MetricsAction<DeviceMetricsData> => ({
  type: ActionTypes.UPDATE_DEVICE_METRICS,
  payload,
});

export const updateCloudMetricsData = (
  payload: CloudMetricsData,
): MetricsAction<CloudMetricsData> => ({
  type: ActionTypes.UPDATE_CLOUD_METRICS,
  payload,
});

export const resetDeviceMetrics = (): MetricsAction => ({
  type: ActionTypes.RESET_DEVICE_METRICS,
});

export const resetCloudMetrics = (): MetricsAction => ({
  type: ActionTypes.RESET_CLOUD_METRICS,
});
