enum OrgActionTypes {
  GET_DETAILS = 'ORG/GET_DETAILS',
  SET_DETAILS_LOADING = 'ORG/SET_DETAILS_LOADING',
  UPDATE_DETAILS = 'ORG/UPDATE_DETAILS',
  SET_DETAILS_ERROR = 'ORG/SET_DETAILS_ERROR',
  GET_COUNTRY_LIST = 'ORG/GET_COUNTRY_LIST',
  SET_COUNTRY_API_STATUS = 'ORG/SET_COUNTRY_API_STATUS',
  UPDATE_COUNTRY_LIST = 'ORG/UPDATE_COUNTRY_LIST',
  GET_USERGROUPS_LIST = 'ORG/GET_USERGROUPS_LIST',
  GET_USERGROUP_DETAILS = 'GET_USERGROUP_DETAILS',
  SET_USERGROUPS_API_STATUS = 'ORG/SET_USERGROUPS_API_STATUS',
  UPDATE_USERGROUPS_LIST = 'ORG/UPDATE_USERGROUPS_LIST',
  UPDATE_USERGROUP_DETAILS = 'UPDATE_USERGROUP_DETAILS',
  SET_USERGROUP_DETAILS_API_STATUS = 'SET_USERGROUP_DETAILS_API_STATUS',
  SET_REGISTRATION_STATUS = 'ORG/SET_REGISTRATION_STATUS',
  GET_PROVINCE_LIST = 'ORG/GET_PROVINCE_LIST',
  SET_PROVINCE_API_STATUS = 'ORG/SET_PROVINCE_API_STATUS',
  UPDATE_PROVINCE_MAP = 'ORG/UPDATE_PROVINCE_MAP',
  SET_CREATE_USERGROUP_API_STATUS = 'ORG/SET_CREATE_USERGROUP_API_STATUS',
  CREATE_USERGROUP = 'ORG/CREATE_USERGROUP',
  SET_UPDATE_USERGROUP_API_STATUS = 'ORG/SET_UPDATE_USERGROUP_API_STATUS',
  UPDATE_USERGROUP = 'ORG/UPDATE_USERGROUP',
  SET_DELETE_USERGROUP_API_STATUS = 'ORG/SET_DELETE_USERGROUP_API_STATUS',
  DELETE_USERGROUP = 'ORG/DELETE_USERGROUP',
  SET_LEAVE_USERGROUP_API_STATUS = 'ORG/SET_LEAVE_USERGROUP_API_STATUS',
  LEAVE_USERGROUP = 'ORG/LEAVE_USERGROUP',
  REGISTER = 'ORG/REGISTER',
  SET_ERROR = 'ORG/SET_ERROR',
  GET_ORGANIZATION = 'ORG/GET_ORGANIZATION',
  INVITE_USER = 'ORG/INVITE_USER',
  REMOVE_USER = 'ORG/REMOVE_USER',
  SET_INVITE_USER_EMAIL_INPUT = 'ORG/SET_INVITE_USER_EMAIL_INPUT',
  CLEAR_INVITE_USER_EMAIL_INPUT = 'ORG/CLEAR_INVITE_USER_EMAIL_INPUT',
  TOGGLE_USER_STATE = 'ORG/TOGGLE_USER_STATE',
  SET_TOGGLE_USER_STATE_API_STATUS = 'ORG/SET_TOGGLE_USER_STATE_API_STATUS',
  SET_LOCAL_NOTIFICATION = 'ORG/SET_LOCAL_NOTIFICATION',
  CLEAR_LOCAL_NOTIFICATION = 'ORG/CLEAR_LOCAL_NOTIFICATION',
}

export default OrgActionTypes;
