import { Disk, DiskSpecification, DiskStatus } from './types';

const bytesToGB = (bytes: number): number => parseFloat((bytes / 1024 ** 3).toFixed(6));

export const transformDiskDetailsData = (disk: DiskSpecification): Disk => {
  const data: Disk = {
    name: disk.metadata.name,
    region: disk.metadata.region,
    status: disk.status.status as DiskStatus,
    labels: disk.metadata.labels || {},
    capacity: disk.spec.capacity,
    createdAt: disk.metadata.createdAt,
    guid: disk.metadata.guid,
    internalDeploymentGUID: disk.status.diskBound?.deployment_guid,
    usedByDeploymentName: disk.status.diskBound?.deployment_name,
    capacityUsed: disk.status.capacityUsed && bytesToGB(disk.status.capacityUsed),
    capacityAvailable: disk.status.capacityAvailable,
    createdBy: disk.metadata.creatorGUID,
  };

  return data;
};

export const transformDisksList = (disks: DiskSpecification[]): Disk[] => {
  const data: Disk[] = disks ? disks.map((disk) => transformDiskDetailsData(disk)) : [];
  return data;
};
