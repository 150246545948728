enum ConfigurationsActionTypes {
  GET_LIST = 'CONFIGS/GET_LIST',
  SET_LIST_API_STATUS = 'CONFIGS/SET_LIST_API_STATUS',
  SET_LIST_API_DATA = 'CONFIGS/SET_LIST_API_DATA',

  RENAME_CONFIG = 'CONFIGS/RENAME_CONFIG',
  SET_RENAME_API_STATUS = 'CONFIGS/SET_RENAME_API_STATUS',

  CREATE_CONFIG = 'CONFIGS/CREATE_CONFIG',
  SET_CREATE_API_STATUS = 'CONFIGS/SET_CREATE_API_STATUS',

  DELETE_CONFIG = 'CONFIGS/DELETE_CONFIG',
  SET_DELETE_API_STATUS = 'CONFIGS/SET_DELETE_API_STATUS',

  CLONE_CONFIG = 'CONFIGS/CLONE_CONFIG',
  SET_CLONE_API_STATUS = 'CONFIGS/SET_CLONE_API_STATUS',
}

export default ConfigurationsActionTypes;
