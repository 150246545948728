import React from 'react';
import classNames from 'classnames';

import { ViewType } from './ToggleView.types';

const rootClassName = 'rio-toggle-view';

interface Props {
  selectedView: ViewType;
  onChange: (view: ViewType) => void;
}

const ToggleView: React.FC<Props> = (props) => {
  const handleViewChange = (view: ViewType) => () => props.onChange(view);

  return (
    <div className={rootClassName}>
      <div
        className={classNames(`${rootClassName}__option`, {
          [`${rootClassName}__option--selected`]: props.selectedView === ViewType.GRID,
        })}
        onClick={handleViewChange(ViewType.GRID)}
      >
        <img src={require('Assets/icons/grid_view.svg')} alt="grid" />
      </div>
      <div
        className={classNames(`${rootClassName}__option`, {
          [`${rootClassName}__option--selected`]: props.selectedView === ViewType.LIST,
        })}
        onClick={handleViewChange(ViewType.LIST)}
      >
        <img src={require('Assets/icons/list_view.svg')} alt="list" />
      </div>
    </div>
  );
};

export default ToggleView;
