import { LabeledValue } from 'antd/lib/select';
import { AxiosError } from 'axios';

export const getErrorMessage = (err: AxiosError): string =>
  err?.response?.data?.error || err?.response?.data?.response?.error || err.message;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor);
    });
};

export const makeSelectOptions = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arr: any[],
  labelKey = 'label',
  valueKey = 'value',
): LabeledValue[] => arr.map((o) => ({ label: o[labelKey] ?? o, value: o[valueKey] ?? o }));

export const isTrue = (val: boolean | string): boolean => [true, 'true', 'True'].includes(val);

export const isNil = (val: unknown): boolean => val === undefined || val === null;

export const showLength = (len = 0): string => (len > 0 && len < 10 ? `0${len}` : `${len}`);

export const diff = <T>(a: Array<T>, b: Array<T>): T[] => a.filter((x) => !b.includes(x));

export const getValueByKey = <T>(arr: Array<T>, key: keyof T): T[keyof T][] =>
  (arr ?? []).map((o) => o[key]);

export const filterFieldsByKeys = <T>(
  arr: Array<T>,
  selectors: Array<keyof T>,
  filterText: string,
): Array<T> => {
  const regex = new RegExp(filterText, 'i');
  return arr.filter((record) =>
    regex.test(selectors.reduce((acc, curr) => `${acc} ${record[curr]}`, '')),
  );
};
