import { mapObjValues } from 'Shared/utils/core/object';

import { DeploymentSpecification } from '../deployPackage/types';

import {
  APICompOverride,
  APIDepComponentContext,
  APIProvisionContext,
  CompOverride,
  DepComponentContext,
  Deployment,
  DeploymentHistory,
  DeploymentHistorySpecification,
  ProvisionContext,
} from './types';

export const mapAPICompOverrideToCompOverride = (compOverride: APICompOverride): CompOverride => ({
  restartPolicy: compOverride.restart_policy,
});

/* eslint-disable camelcase */
export const mapAPIRosBagJobDepsToRosBagJobDeps = ({
  ros_bag_job_defs,
  static_route_config,
  component_override,
}: APIDepComponentContext): DepComponentContext => ({
  rosBagJobDefs: ros_bag_job_defs,
  componentOverride: component_override
    ? mapAPICompOverrideToCompOverride(component_override)
    : null,
  staticRouteConfig: static_route_config,
});
const VPN_MANAGED_SERVICES_INSTANCE_NAME = 'rio-internal-headscale';
/* eslint-enable camelcase */
export const mapAPIProvisionContextToProvisionContext = ({
  // eslint-disable-next-line camelcase
  component_context = {},
  // eslint-disable-next-line camelcase
  network_topics_map,
  managedServices,
  ...rest
}: APIProvisionContext): ProvisionContext => ({
  networkTopicsMap: network_topics_map,
  componentContext: mapObjValues(
    component_context,
    (val) => val && mapAPIRosBagJobDepsToRosBagJobDeps(val),
  ),
  isVpnEnabled: Boolean(
    managedServices?.find((service) => service.instance === VPN_MANAGED_SERVICES_INSTANCE_NAME),
  ),
  ...rest,
});

export const transformDeploymentList = (data: DeploymentSpecification[]): Deployment[] =>
  data.map((deployment) => ({
    name: deployment.metadata.name,
    createdAt: deployment.metadata.createdAt,
    packageName: deployment.metadata.depends.nameOrGUID,
    packageVersion: deployment.metadata.depends.version,
    status: deployment.status.status,
    phase: deployment.status.phase,
    errorCodes: deployment.status.error_codes,
    runtime: deployment.spec.runtime,
    deploymentId: deployment.metadata.guid,
    deploymentDetails: deployment,
  }));

export const transformDeploymentHistory = (
  deploymentHistory: DeploymentHistorySpecification[],
): DeploymentHistory[] =>
  deploymentHistory.map(({ metadata, status }) => ({
    creatorGUID: metadata.creatorGUID,
    createdAt: metadata.createdAt,
    updatedAt: metadata.updatedAt,
    generation: metadata.generation,
    phase: status.phase,
    status: status.status,
    errorCode: status.error_codes,
  }));
