import { APICallStatus, RIOError } from 'Models/common/types';
import { Secret, SecretAction, SecretFormData } from 'Models/secrets/types';

import ActionTypes from './actionTypes';

export const toggleSecretAddModal = (): SecretAction => ({
  type: ActionTypes.SOURCE_SECRETS_ADD_MODAL_TOGGLE,
});

export const setSecretListInit = (): SecretAction => ({
  type: ActionTypes.SOURCE_SECRETS_GET_PENDING,
});

export const setSecretList = (data: Secret[]): SecretAction<Secret[]> => ({
  type: ActionTypes.SOURCE_SECRETS_GET_FULFILLED,
  payload: data,
});

export const setSecretsListFail = (errorMsg: string, status: number): SecretAction<RIOError> => ({
  type: ActionTypes.SOURCE_SECRETS_GET_REJECTED,
  payload: {
    status,
    message: errorMsg,
  },
});

export const setCreateSecretInit = (): SecretAction => ({
  type: ActionTypes.SOURCE_SECRETS_CREATE_PENDING,
});

export const setCreateSecretSuccess = (data: Secret): SecretAction<Secret> => ({
  type: ActionTypes.SOURCE_SECRETS_CREATE_FULFILLED,
  payload: data,
});

export const setCreateSecretFail = (): SecretAction => ({
  type: ActionTypes.SOURCE_SECRETS_CREATE_REJECTED,
});

export const setDeleteSecretSuccess = (name: string): SecretAction<string> => ({
  type: ActionTypes.SOURCE_SECRET_DELETE_FULFILLED,
  payload: name,
});

export const getSecrets = (): SecretAction => ({
  type: ActionTypes.SOURCE_SECRET_GET,
});

export const createSecret = (payload: SecretFormData): SecretAction<SecretFormData> => ({
  type: ActionTypes.SOURCE_SECRET_CREATE,
  payload,
});

export const deleteSecret = (payload: string): SecretAction<string> => ({
  type: ActionTypes.SOURCE_SECRET_DELETE,
  payload,
});

export const setSecretEditAPIStatus = (payload: APICallStatus): SecretAction<APICallStatus> => ({
  type: ActionTypes.SOURCE_SECRET_EDIT_API_STATUS,
  payload,
});

export const editSecret = (payload: SecretFormData): SecretAction<SecretFormData> => ({
  type: ActionTypes.SOURCE_SECRET_EDIT,
  payload,
});
