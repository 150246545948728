import Cookies from 'js-cookie';

import { EntityState, User, UserMap, UserRoles } from 'Models/common/types';
import { customSetCookie, getUrlParameter, replaceUrlParameters } from 'Shared/utils/common';

class StoreUtilAdapter {
  projectId = '';

  userToken?: string = Cookies.get('token');

  userMap: UserMap = {};

  userDetails: User = {
    guid: '',
    firstName: '',
    lastName: '',
    emailID: '',
    state: EntityState.ACTIVATED,
    roleInOrganization: UserRoles.ADMIN,
    projects: [],
    featuresEnabled: [],
    userStateInOrgs: [],
  };

  setSelectedProjectId(projectId: string) {
    this.projectId = projectId;
  }

  setUserDetails(userDetails: User) {
    this.userDetails = userDetails;
  }

  getSelectedProjectGUID() {
    const selectedOrgGuid = this.getSelectedOrgID();
    const stateInCurrentOrg = this.userDetails.userStateInOrgs.find(
      ({ organizationGUID }) => organizationGUID === selectedOrgGuid,
    )?.userState;

    if (
      stateInCurrentOrg &&
      stateInCurrentOrg !== EntityState.ACTIVATED &&
      stateInCurrentOrg !== EntityState.SUSPENDED
    ) {
      customSetCookie('project', '');
      replaceUrlParameters({ p: '' });
      return null;
    }
    const selectedProjectFromCookies = Cookies.get('project');
    const selectProjectFromParameter = getUrlParameter('p');
    const selectedProjectId =
      this.projectId ||
      selectProjectFromParameter ||
      (selectedProjectFromCookies?.length ? selectedProjectFromCookies : null);
    if (selectedProjectId !== selectProjectFromParameter) {
      replaceUrlParameters({ p: selectedProjectId });
    }

    return selectedProjectId === 'null' ? null : selectedProjectId;
  }

  getSelectedOrgID(): string {
    return getUrlParameter('org') ?? this.userDetails.organization?.guid ?? '';
  }

  getUserToken(): string {
    return this.userToken ?? '';
  }

  setUserMap(userMap: UserMap) {
    this.userMap = userMap;
  }

  getUsernameFromGUID = (userId: string): string => {
    const userDetails = this.userMap[userId];
    if (userDetails) {
      return `${userDetails.firstName} ${userDetails.lastName}`;
    }

    return userId;
  };

  getUserName = () => {
    const { userDetails } = this;

    return `${userDetails.firstName} ${userDetails.lastName}`;
  };
}

const storeUtilAdapterInst = new StoreUtilAdapter();

export default storeUtilAdapterInst;
