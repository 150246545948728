import {
  APIBlobRef,
  APIBlobRefList,
  APIConfigurationApplyDeviceId,
  APIConfigurationApplyResponse,
  BlobRef,
  BlobRefList,
  ConfigurationApplyDeviceId,
} from './types';

export const mapAPIBlobRefToBlobRef = ({
  CreatedAt: createdAt,
  DeletedAt: deletedAt,
  ID: id,
  UpdatedAt: updatedAt,
  ...rest
}: APIBlobRef): BlobRef => ({
  ...rest,
  createdAt,
  id,
  updatedAt,
  deletedAt,
});

export const mapAPIBlobRefListToBlobRefList = ({
  blobRefs,
  isComplete,
  files = [],
}: APIBlobRefList): BlobRefList => ({
  isComplete,
  files,
  blobRefs: blobRefs.map(mapAPIBlobRefToBlobRef),
});

export const mapAPIConfigApplyToConfigApply = ({
  device_id,
  success,
}: APIConfigurationApplyDeviceId): ConfigurationApplyDeviceId => ({
  deviceId: device_id,
  success,
});

export const configurationApplyResponseAxiosThen = (
  data: APIConfigurationApplyResponse,
): ConfigurationApplyDeviceId[] => {
  const innerData: APIConfigurationApplyDeviceId[] = data?.response?.data ?? [];
  return innerData?.map(mapAPIConfigApplyToConfigApply) ?? [];
};
