import { APICallStatus } from 'Models/common/types';
import { Usage, UsageAction, UsageActionPayload, UsageState } from 'Models/usage/types';

import UsageActionTypes from './actionTypes';

const initialState: UsageState = {
  apiStatus: APICallStatus.INITIAL,
  error: null,
  details: {
    additionalUsageBillAmountTillNow: 0,
    billMonth: '',
    currency: '',
    planDetails: {},
    subscriptionStartDate: '',
    unitOfMeasures: [],
  },
};

export default (
  state: UsageState = initialState,
  action: UsageAction<UsageActionPayload>,
): UsageState => {
  switch (action.type) {
    case UsageActionTypes.SET_USAGE: {
      return { ...state, details: action.payload as Usage, apiStatus: APICallStatus.LOADED };
    }

    case UsageActionTypes.FETCHING_USAGE: {
      return { ...state, apiStatus: APICallStatus.LOADING };
    }

    case UsageActionTypes.ERROR_USAGE: {
      return { ...state, apiStatus: APICallStatus.LOADED, error: 'Failed to fetch usage details.' };
    }
    default:
      return state;
  }
};
