import ActionTypes from 'Root/redux/packages/actionTypes';

import { APICallStatus, ROSVersion, RunTime } from '../common/types';
import { DeploymentPhase } from '../deployments/types';
import { Secret } from '../secrets/types';

export enum Arch {
  ARM32v7 = 'arm32v7',
  ARM64v8 = 'arm64v8',
  AMD64 = 'amd64',
}
export enum Restart {
  Always = 'always',
  Never = 'never',
  OnFailure = 'onfailure',
}

export enum ExecutablesType {
  Docker = 'docker',
  PreInstalled = 'preInstalled',
}

export enum ImagePullPolicy {
  Always = 'Always',
  IfNotPresent = 'IfNotPresent',
  Never = 'Never',
}

export enum LivenessType {
  HttpGet = 'httpGet',
  Exec = 'exec',
  TcpSocket = 'tcpSocket',
}

export enum ROSEndpointsType {
  Topic = 'topic',
  Service = 'service',
  Action = 'action',
}

export enum Qos {
  Low = 'low',
  Medium = 'med',
  High = 'hi',
  Maximum = 'max',
}

export enum Compression {
  BZ2 = 'bz2',
  LZ4 = 'lz4',
}

export enum UploadType {
  OnStop = 'OnStop',
  Continuous = 'Continuous',
  OnDemand = 'OnDemand',
}

export enum EndpointsType {
  ExternalHttp = 'external-http',
  ExternalHttps = 'external-https',
  ExternalTlsTcp = 'external-tls-tcp',
  InternalTcp = 'internal-tcp',
  InternalUdp = 'internal-udp',
  InternalTcpRange = 'internal-tcp-range',
  InternalUdpRange = 'internal-udp-range',
}
export interface ExecutableDocker {
  imagePullPolicy: ImagePullPolicy;
  image: string;
  privateImage?: boolean;
  pullSecret?: {
    depends: {
      nameOrGUID: string;
    };
  };
}
export interface Executables {
  name: string;
  type: ExecutablesType;
  runAsBash?: boolean;
  docker?: ExecutableDocker;
  command?: string | string[];
  limits?: {
    cpu?: number;
    memory?: number;
  };
  isLivenessProbe?: boolean;
  livenessProbe?: {
    livenessType?: LivenessType;
    httpGet?: {
      path: string;
      port: number;
      scheme?: string;
    };
    exec?: {
      command: string | string[];
    };
    tcpSocket?: {
      port: number;
    };

    initialDelaySeconds: number;
    timeoutSeconds: number;
    periodSeconds: number;
    successThreshold: number;
    failureThreshold: number;
  };
}

export interface EnvironmentVars {
  name: string;
  description: string;
  default: string;
  exposed?: boolean;
}

export interface Endpoints {
  name: string;
  type: EndpointsType;
  port?: number;
  targetPort?: number;
  portRange?: string;
}

export interface RosEndpoints {
  name: string;
  type: ROSEndpointsType;
  compression?: boolean;
  scoped?: boolean;
  targeted?: boolean;
  qos?: Qos;
  timeout?: number;
}

export interface RosbagRecordOptions {
  allTopics?: boolean;
  topics?: string[];
  topicIncludeRegex?: string[];
  topicExcludeRegex?: string;
  maxMessageCount?: number;
  node?: string;
  compression?: Compression;
  maxSplits?: number;
  maxSplitSize?: number;
  chunkSize?: number;
  prefix?: string;
  maxSplitDuration?: number;
}
export interface RosbagUploadOptions {
  maxUploadRate?: number;
  purgeAfter?: boolean;
  uploadType?: UploadType;
  onDemandOpts?: {
    timeRange: {
      from: number;
      to: number;
    };
  };
}
export interface RosbagTopicOverrideInfo {
  topicName: string;
  recordFrequency?: number;
  latched?: boolean;
}

export interface RosbagOverrideOptions {
  topicOverrideInfo?: RosbagTopicOverrideInfo[];
  excludeTopics?: string[];
}

export interface RosBagJobs {
  name: string;
  recordOptions: RosbagRecordOptions;
  uploadOptions: RosbagUploadOptions;
  overrideOptions?: RosbagOverrideOptions;
}
export interface PackageSpecification {
  metadata: {
    name: string;
    guid: string;
    projectGUID: string;
    creatorGUID: string;
    createdAt: string;
    updatedAt: string;
    version: string;
    description?: string;
  };
  spec: {
    runtime: RunTime;
    executables: Executables[];
    environmentVars?: EnvironmentVars[];
    ros: {
      enabled: boolean;
      version?: ROSVersion;
      rosEndpoints?: RosEndpoints[];
    };
    endpoints?: Endpoints[];
    rosBagJobs?: RosBagJobs[];
    device?: {
      arch?: Arch;
      restart?: Restart;
    };
    cloud?: { replicas?: number };
  };
}
export interface CreatePackageSpecification {
  metadata: Partial<PackageSpecification['metadata']>;
  spec: PackageSpecification['spec'];
}

export interface PackageList {
  name: string;
  version: string;
  createdAt: string;
  guid: string;
  isRos: boolean;
  packageDetails: PackageSpecification;
}

export interface ClonePackageDetails {
  name: string;
  version: string;
  isClone: boolean;
  destinationProject?: string;
  showSecretCloneModal: boolean;
}
export interface PackagesState {
  packagesList: PackageList[];
  listAPIStatus: APICallStatus;
  apiStatus: APICallStatus;
  importPackageModalVisibility: boolean;
  createPackageModalVisibility: boolean;
  packageDetailsAPIStatus: APICallStatus;
  packageDetails: PackageSpecification | null;
  clonePackageDetails: ClonePackageDetails;
  secretsList: Secret[] | null;
  secretsListAPICallStatus: APICallStatus;
  destinationSecretsList: Secret[] | null;
  destinationSecretsListAPICallStatus: APICallStatus;
  downloadManifestAPICallStatus: APICallStatus;
  packageDeploymentList: PackageDeploymentList[];
  packageDeploymentListAPICallStatus: APICallStatus;
}

export interface TransformedPackageList extends PackageList {
  versions: PackageList[];
}

interface PackagesActionTypes {
  type: ActionTypes;
}

export interface GetDeletePackagePayload {
  name: string;
  version: string;
}

export interface CreatePackagePayload {
  project: string;
  data: CreatePackageSpecification;
}

export type PackagesAction<P = undefined> = P extends undefined
  ? PackagesActionTypes
  : PackagesActionTypes & { payload: P };

export type PackagesPayload =
  | PackageList
  | string
  | boolean
  | GetDeletePackagePayload
  | PackageList[]
  | APICallStatus
  | PackageSpecification
  | ClonePackageDetails
  | Secret[]
  | PackageDeploymentList[]
  | null;

export interface FormData {
  metaData: {
    name: string;
    version: string;
    description?: string;
  };
  spec: {
    runtime: RunTime;
    ros: boolean;
    arch?: Arch;
    restart?: Restart;
    version?: ROSVersion;
    replicas?: number;
  };
  executables: Executables[];
  environmentVars?: EnvironmentVars[];
  endpoints?: Endpoints[];
  rosEndpoints?: RosEndpoints[];
  rosBagJobs?: RosBagJobs[];
}

export interface SpecInfo {
  runtime: RunTime.DEVICE | RunTime.CLOUD;
  ros: boolean;
}

export interface GetPackageDeploymentListPayload {
  packageName: string;
  packageVersion: string;
  phases: DeploymentPhase[];
}

export interface PackageDeploymentList {
  name: string;
  started: string;
  phase: DeploymentPhase;
  deploymentId: string;
}

export type RosEndpointsTypeNamesMap = Record<string, string[]>;
