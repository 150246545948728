import Raven from 'raven-js';
import { SENTRY_URL, UI_VERSION, IS_DEV_ENV } from '../config';

if (!IS_DEV_ENV) {
  Raven.config(SENTRY_URL, {
    autoBreadcrumbs: {
      console: false,
      release: UI_VERSION,
    },
  }).install();
}
