import { CLIManifest, CLIManifestAction, ManifestMeta } from 'Models/cliManifest/types';

import CLIManifestActionTypes from './actionTypes';

export const downloadYAML = <T = undefined, U = ManifestMeta>(
  payload: CLIManifest<T, U>,
): CLIManifestAction<CLIManifest<T, U>> => ({
  type: CLIManifestActionTypes.DOWNLOAD_YAML,
  payload,
});

export default {};
