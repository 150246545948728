import { APICallStatus, Limits, RunTime } from 'Models/common/types';
import { DeviceCondensed } from 'Models/devices/types';
import ActionTypes from 'Root/redux/network/actionTypes';

import { RegionType } from '../deployPackage/types';

export interface Network {
  name: string;
  createdAt: string;
  updatedAt?: string;
  region?: RegionType;
  guid: string;
  ownerProject: string;
  creator: string;
  runtime: RunTime;
  rosDistro: string;
  limits?: Limits;
  shared?: boolean;
  internalDeploymentStatus: InternalDeploymentStatus;
  depends?: { nameOrGUID?: string };
  networkInterface?: string;
  discoveryServer?: DiscoveryServer;
  type: NetworkTypes;
  restartPolicy?: string;
}

interface DiscoveryServer {
  serverId: number;
  serverPort: number;
}

interface NetworkSpec {
  runtime: RunTime;
  type: NetworkTypes;
  rosDistro: string;
  depends?: { nameOrGUID: string };
  networkInterface?: string;
  discoveryServer: DiscoveryServer;
  resourceLimits?: Limits;
  restartPolicy: string;
}
export interface NetworkSpecification {
  metadata: {
    name: string;
    guid: string;
    projectGUID: string;
    creatorGUID: string;
    createdAt: string;
    updatedAt: string;
    region?: RegionType;
  };
  spec: NetworkSpec;
  status: InternalDeploymentStatus;
}

export interface APIInternalDeploymentStatus {
  // eslint-disable-next-line camelcase
  error_code?: string[];
  phase: NetworkPhases;
  status?: NetworkStatus;
}

export interface InternalDeploymentStatus {
  phase: NetworkPhases;
  status?: NetworkStatus;
}

export interface CreateNetworkPayload {
  metadata: {
    name: string;
    region?: RegionType;
  };
  spec: NetworkSpec;
}

export interface GetNetworksPayload {
  phaseList: string[];
  deviceUID: string;
}

export interface CreateNetworkResponse {
  name: string;
  guid: string;
}

export enum NetworkStatus {
  RUNNING = 'Running',
  ERROR = 'Error',
  DEPLOYMENT_NOT_RUNNING = 'Deployment not running',
  PENDING = 'Pending',
  UNKNOWN = 'Unknown',
}

export interface DeleteNetworkParams {
  name: string;
  guid: string;
  type: string;
}

export interface FilterOptionsNetwork {
  phase: NetworkPhases[] | null;
  runtime: RunTime[] | null;
  search: string;
  type: NetworkTypes[] | null;
}

export enum NetworkPhases {
  IN_PROGRESS = 'InProgress',
  PROVISIONING = 'Provisioning',
  SUCCEEDED = 'Succeeded',
  START_FAILED = 'FailedToStart',
  UPDATE_FAILED = 'FailedToUpdate',
}

export enum NetworkTypes {
  ROUTED = 'routed',
  NATIVE = 'native',
}

export interface DetailItem {
  title?: string | JSX.Element;
  value?: string | number | JSX.Element;
}

export interface NetworkState {
  networkList: Network[];
  networkListAPIStatus: APICallStatus;
  addNetworkVisibility: boolean;
  addNetworkAPIStatus: APICallStatus;
  deviceList: DeviceCondensed[];
  deviceListAPIStatus: APICallStatus;
  networkDetails: Network | null;
  networkDetailsAPIStatus: APICallStatus;
  downloadNetworkManifestAPIStatus: APICallStatus;
}
interface NetworkActionTypes {
  type: ActionTypes;
}

export interface FilterOptions {
  phase: string[];
  runtime: string[];
  search: string;
  type: string[];
}
export type NetworkAction<P = undefined> = P extends undefined
  ? NetworkActionTypes
  : NetworkActionTypes & { payload: P };

export type NetworkPayload =
  | Network[]
  | string
  | string[]
  | boolean
  | APICallStatus
  | DeviceCondensed[]
  | Network
  | null;
