import { capitalize } from 'Root/shared/utils/core/string';
import { BROKER_RESOURCE_LIMITS, NATIVE_NETWORK_LIMITS } from 'Shared/config';
import { removeUrlParameters, replaceUrlParameters } from 'Shared/utils/common';

import { commonLengthVaildation } from './constants';

export const NETWORK_PHASE_FILTER_OPTIONS = {
  'In progress': 'In progress',
  Succeeded: 'Succeeded',
  Failed: 'Failed to start',
  'Partially depovisioned': 'Partially deprovisioned',
  Provisioning: 'Provisioning',
  DeploymentStopped: 'Deployment stopped',
};

export const NETWORK_STATUS_OPTIONS = {
  Running: 'Running',
  Error: 'Error',
  DeploymentNotRunning: 'Deployment not running',
  Pending: 'Pending',
  Unknown: 'Unknown',
};

export const ALL_NETWORK_TYPE = 'All';
export const NETWORK_TYPE = {
  routedType: 'routed',
  nativeType: 'native',
};

export const sortByCpu = (a, b) => {
  const { cpu: acpu } = a[1];
  const { cpu: bcpu } = b[1];

  return acpu - bcpu;
};

export const setParamsInUrl = (filters) => {
  Object.keys(filters).forEach((key) => {
    if (filters[key] === null) {
      removeUrlParameters([key]);
    } else {
      replaceUrlParameters({ [key]: encodeURIComponent(filters[key]) });
    }
  });
};

export const getOptionFromLimits = (key, { cpu, memory }) => ({
  label: `${capitalize(key)}: ${cpu} cpu core${cpu > 1 ? 's' : ''}, ${memory} GiB memory`,
  value: { cpu, memory: memory * 1024 },
});

export const routedNetworkCPUMemLimitOptions = Object.entries(BROKER_RESOURCE_LIMITS)
  .sort(sortByCpu)
  .map(([key, val]) => getOptionFromLimits(key, val));

export const nativeNetworkCPUMemLimits = Object.entries(NATIVE_NETWORK_LIMITS)
  .sort(sortByCpu)
  .map(([key, val]) => getOptionFromLimits(key, val));

export const componentNameValidation = (name) =>
  name.match(/^[a-zA-Z]+[a-zA-Z0-9_-]*$/) && commonLengthVaildation(name);
