import {
  CloudMetrics,
  CloudMetricsData,
  DeviceMetrics,
  DeviceMetricsData,
  MetricData,
  MetricsAction,
  MetricsActionPayload,
  MetricsState,
} from 'Models/metrics/types';

import ActionTypes from './actionTypes';

const initialDeviceMetricsState: Record<DeviceMetrics, MetricData | null> = {
  [DeviceMetrics.CPU]: null,
  [DeviceMetrics.MEMORY]: null,
  [DeviceMetrics.DISK_USAGE]: null,
  [DeviceMetrics.NETWORK]: null,
  [DeviceMetrics.DISK_IO]: null,
};

const initialCloudMetricsState: Record<CloudMetrics, MetricData | null> = {
  [CloudMetrics.CPU]: null,
  [CloudMetrics.MEMORY]: null,
  [CloudMetrics.NETWORK]: null,
  [CloudMetrics.CPU_THROTTLE]: null,
  [CloudMetrics.PACKETS]: null,
};

const initialState: MetricsState = {
  device: initialDeviceMetricsState,
  cloud: initialCloudMetricsState,
};

const metricsReducer = (
  state: MetricsState = initialState,
  action: MetricsAction<MetricsActionPayload>,
): MetricsState => {
  switch (action.type) {
    case ActionTypes.UPDATE_DEVICE_METRICS: {
      const payload = action.payload as DeviceMetricsData;

      return {
        ...state,
        device: {
          ...state.device,
          [payload.key]: payload.data,
        },
      };
    }

    case ActionTypes.RESET_DEVICE_METRICS: {
      return {
        ...state,
        device: initialDeviceMetricsState,
      };
    }

    case ActionTypes.UPDATE_CLOUD_METRICS: {
      const payload = action.payload as CloudMetricsData;

      return {
        ...state,
        cloud: {
          ...state.cloud,
          [payload.key]: payload.data,
        },
      };
    }

    case ActionTypes.RESET_CLOUD_METRICS: {
      return {
        ...state,
        cloud: initialCloudMetricsState,
      };
    }

    default:
      return state;
  }
};

export default metricsReducer;
