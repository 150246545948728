import SecretActionTypes from 'RioRedux/secrets/actionTypes';

import { APICallStatus, RIOError } from '../common/types';

export interface SecretState {
  list: Secret[] | null;
  error: RIOError | null;
  apiStatus: APICallStatus;
  addModalOpen: boolean;
  createPending: boolean;
  editAPIStatus: APICallStatus;
}

export enum RegistryTypes {
  DOCKERHUB = 'Dockerhub',
  QUAY = 'Quay',
  CUSTOM = 'Private registry',
}

export interface Secret {
  guid: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  creator: string;
  projectGUID: string;
  username: string;
  password: string;
  email: string;
  registry: string;
}

export interface SecretAction<P = undefined> {
  type: SecretActionTypes;
  payload?: P;
}

export type SecretActionPayload = Secret | Secret[] | RIOError | string | number | APICallStatus;

export interface SecretFormData {
  name: string;
  registryType: RegistryTypes;
  url?: string;
  username: string;
  password: string;
  email: string;
}

export interface DockerData {
  username: string;
  password: string;
  email: string;
  registry: string;
}
export interface CreateSecretAPISpec {
  docker: DockerData;
}

export interface CreateSecretAPIPayload {
  metadata: {
    name: string;
    labels?: Record<string, string>;
  };
  spec?: CreateSecretAPISpec;
}

export interface APISecret {
  metadata: {
    name: string;
    guid: string;
    projectGUID: string;
    creatorGUID: string;
    labels: Record<string, string>;
    createdAt: string;
    updatedAt: string;
  };
  spec: {
    docker: DockerData;
  };
}
