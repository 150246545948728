import ActionTypes from 'RioRedux/marketplace/actionTypes';

import { APICallStatus } from '../common/types';

export interface MarketplaceState {
  details: MplacePkgDetails | null;
  apiStatus: APICallStatus;
  showProjectSelectModal: boolean;
}

export interface ESWithSearchCtx {
  wasSearched: boolean;
  isLoading: boolean;
  totalResults: number;
  error: string;
  results: ESResult[];
}

export interface ESConfig {
  engineName: string;
  hostIdentifier: string;
  endpointBase: string;
  searchKey: string;
  resultFields: ESResultFields[];
  sortFields: SortFields[];
  facets: Facets[];
  titleField: Extract<ESResultFields, 'name'>;
  urlField?: string;
  querySuggestFields: QuerySuggestFields[];
  fields?: ESResultFields[];
  searchFields?: ESResultFields[];
}

/* eslint-disable camelcase */
export interface ESResult {
  brochure: ESResultItem<string>;
  rio_ros_support: ESResultItem<string>;
  is_featured: ESResultItem<string>;
  license_url: ESResultItem<string>;
  pricing: ESResultItem<string>;
  rrn: ESResultItem<string>;
  website: ESResultItem<string>;
  documentation_url: ESResultItem<string>;
  brochure_url: ESResultItem<string>;
  updated_at: ESResultItem<string>;
  image_url: ESResultItem<string>;
  support_email: ESResultItem<string>;
  name: ESResultItem<string>;
  tos: ESResultItem<string>;
  rio_ros_version: ESResultItem<string>;
  support_url: ESResultItem<string>;
  rio_runtime: ESResultItem<string>;
  created_at: ESResultItem<string>;
  slug: ESResultItem<string>;
  description: ESResultItem<string>;
  tos_url: ESResultItem<string>;
  pricing_url: ESResultItem<string>;
  id: ESResultItem<string>;
  publisher: ESResultItem<string>;
}

export interface APIMplacePkgDetails {
  categories: APIMplacePkgCategory[];
  publisher: APIMplacePublisher;
  images: [];
  name: string;
  rrn: string;
  slug: string;
  description: string;
  readme: string;
  website: string;
  support_email: string;
  documentation_url?: string;
  license_url?: string;
  support_url?: string;
  pricing_url?: string;
  brochure_url?: string;
  tos_url?: string;
  image_url: string;
  skus: APISKU[];
  rio_ros_version: string;
}

export interface APIMplacePkgCategory {
  category_name: string;
  category_icon: string;
}

export interface APIMplacePublisher {
  name: string;
  rrn_prefix: string;
  publisher_slug: string;
}

export interface APISKU {
  semver: string;
  tag: string;
  changelog: string;
  changelog_url: string;
  is_latest: boolean;
  slug: string;
  created: string;
  last_updated: string;
}

/* eslint-enable */

export type MplacePkgDetails = Omit<
  APIMplacePkgDetails,
  | 'categories'
  | 'publisher'
  | 'support_email'
  | 'documentation_url'
  | 'license_url'
  | 'support_url'
  | 'pricing_url'
  | 'brochure_url'
  | 'tos_url'
  | 'image_url'
  | 'skus'
  | 'rio_ros_version'
> & {
  categories: MplacePkgCategory[];
  publisher: MplacePublisher;
  supportEmail: string;
  documentationURL?: string;
  licenseURL?: string;
  supportURL?: string;
  pricingURL?: string;
  brochureURL?: string;
  tosURL?: string;
  imageURL: string;
  skus: SKU[];
  rioRosVersion: string;
};

export interface MplacePkgCategory {
  categoryName: string;
  categoryIcon: string;
}

export type MplacePublisher = Omit<APIMplacePublisher, 'rrn_prefix' | 'publisher_slug'> & {
  rrnPrefix: string;
  publisherSlug: string;
};

export type SKU = Omit<APISKU, 'changelog_url' | 'is_latest' | 'last_updated'> & {
  changelogURL: string;
  isLatest: boolean;
  lastUpdated: string;
};

export interface ESResultItem<T> {
  raw: T;
  snippet: T | null;
}

export type ESResultFields = keyof ESResult;
export type SortFields = Extract<ESResultFields, 'updated_at' | 'created_at' | 'name'>;
export type Facets = Extract<ESResultFields, 'rio_ros_support' | 'rio_runtime' | 'rio_ros_version'>;
export type QuerySuggestFields = Extract<ESResultFields, 'name' | 'rrn'>;

export interface InstallPkgActionPaylod {
  slug: string;
  version: string;
  projectID?: string;
}

interface BaseMarketplaceAction {
  type: ActionTypes;
}

export type MarketplaceAction<P = undefined> = P extends undefined
  ? BaseMarketplaceAction
  : BaseMarketplaceAction & { payload: P };

export type MarketplaceActionPayload = MplacePkgDetails | APICallStatus | boolean;

export enum MplaceQueryEnum {
  INSTALL = 'install',
  VERSION = 'version',
  NAME = 'name',
}

export type MplaceQueryParams = {
  [value in MplaceQueryEnum]?: string;
};

export enum ErrorMessages {
  PACKAGE_INSTALL = 'There is an error in installing this package! Please check the docs for more info.',
  PACKAGE_DETAILS = 'There is an error in fetching the details for this package! Please check the docs for more info.',
}
