import { SecretFormField } from './types';

export const NAME_REGEX = '^[a-z0-9]([-a-z0-9]*[a-z0-9])?$';

export const ROOT_CLASS_NAME = 'rio-secrets';

export const URL_REGEX =
  '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$';

export const TITLE = {
  [SecretFormField.NAME]: 'Name',
  [SecretFormField.REGISTRY]: 'Registry',
  [SecretFormField.REGISTRY_URL]: 'Registry Url',
  [SecretFormField.USERNAME]: 'Username',
  [SecretFormField.PASSWORD]: 'Password',
  [SecretFormField.EMAIL]: 'Email',
};

export const NON_EDITABLE_FIELDS = [SecretFormField.NAME];

export const PATTERN_ERROR_MSG = {
  [`.${SecretFormField.NAME}`]:
    'Name should be up to maximum length of 253 characters and must consist of lower case alphanumeric characters or - and must start and end with an alphanumeric character',
  [`.${SecretFormField.EMAIL}`]: 'Not a valid email address',
  [`.${SecretFormField.REGISTRY_URL}`]: 'Not a valid registry url',
};

export const HALF_WIDTH = 11;
export const FULL_WIDTH = 24;

export const MAX_NAME_LENGTH = 253;

export const FORM_ROOT_CLASS = 'rio-add-secret';

export const REGISTRY_DOMAIN_MAP = {
  DOCKERHUB: 'https://index.docker.io/v1/',
  QUAY: 'https://quay.io',
  CUSTOM: '',
};
