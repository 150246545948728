import { APICallStatus } from 'Models/common/types';
import {
  MarketplaceAction,
  MarketplaceActionPayload,
  MarketplaceState,
  MplacePkgDetails,
} from 'Models/marketplace/types';

import ActionTypes from './actionTypes';

const initialState: MarketplaceState = {
  details: null,
  apiStatus: APICallStatus.INITIAL,
  showProjectSelectModal: false,
};

const marketplaceReducer = (
  state: MarketplaceState = initialState,
  action: MarketplaceAction<MarketplaceActionPayload>,
): MarketplaceState => {
  switch (action.type) {
    case ActionTypes.SET_API_STATUS: {
      return {
        ...state,
        apiStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.UPDATE_PACKAGE_DETAILS: {
      return {
        ...state,
        details: action.payload as MplacePkgDetails,
      };
    }

    case ActionTypes.CLEAR_PACKAGE_DETAILS: {
      return {
        ...state,
        details: null,
      };
    }

    case ActionTypes.SET_SHOW_PROJECT_SELECT_MODAL: {
      return {
        ...state,
        showProjectSelectModal: action.payload as boolean,
      };
    }

    default:
      return state;
  }
};

export default marketplaceReducer;
