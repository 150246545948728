export const NOTIF_MSG = {
  GET: {
    FAIL: 'Error Getting Secrets',
  },
  DELETE: {
    SUCCESS: 'Successfully Deleted Secret',
    FAIL: 'Error Deleting Secret',
  },
  CREATE: {
    SUCCESS: 'Successfully Created Secret',
    FAIL: 'Error Creating Secret',
  },
  EDIT: {
    SUCCESS: 'Secret Updated Successfully',
    FAIL: 'Failed to Update Secret',
  },
};

export default {};
