enum ActionTypes {
  ERROR = 'NOTIFICATION/ERROR',
  SUCCESS = 'NOTIFICATION/SUCCESS',
  INFO = 'NOTIFICATION/INFO',
  DISMISS = 'NOTIFICATION/DISMISS',
  WARNING = 'NOTIFICATION/WARNING',
  HIDE = 'NOTIFICATION/HIDE',
}

export default ActionTypes;
