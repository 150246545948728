import { AxiosError } from 'axios';
import { call, delay, ForkEffect, put, takeLatest } from 'redux-saga/effects';

import { APICallStatus } from 'Models/common/types';
import { NotificationPayload } from 'Models/notifications/types';
import { mapStaticRouteToAPIStaticRoute } from 'Models/staticRoutes/dataFormatters';
import {
  createStaticRoute as createStaticRouteApi,
  deleteStaticRoute as deleteStaticRouteApi,
  getStaticRoutes as getStaticRoutesApi,
  updateRouteDetails as updateRouteDetailsApi,
} from 'Models/staticRoutes/service';
import { AddRoutePayload, StaticRoutesAction, UpdateRoutePayload } from 'Models/staticRoutes/types';
import { getMessageFromError } from 'Shared/utils/common';
import { CallReturnType } from 'Types/saga';

import { setNotificationFail, setNotificationSuccess } from '../notifications/action';

import {
  getStaticRoutesList,
  setAddRouteDrawerVisibility,
  setAPICallStatus,
  setCreateRouteAPIStatus,
  setCreateRouteError,
  updateStaticRoutes,
} from './actions';
import StaticRoutesActionTypes from './actionTypes';

function* getStaticRoutes() {
  try {
    yield put(setAPICallStatus(APICallStatus.LOADING));

    const routeDetails: CallReturnType<typeof getStaticRoutesApi> = yield call(getStaticRoutesApi);
    yield put(updateStaticRoutes(routeDetails));

    yield put(setAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setAPICallStatus(APICallStatus.ERROR));

    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: err?.message,
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* deleteStaticRoute(action: StaticRoutesAction<string>) {
  try {
    yield put(setAPICallStatus(APICallStatus.LOADING));

    yield call(deleteStaticRouteApi, action.payload);
    yield put(setAPICallStatus(APICallStatus.LOADED));

    const successPayload: NotificationPayload = {
      message: 'Static Route Deleted Successfully!',
    };
    yield put(setNotificationSuccess(successPayload));

    yield put(getStaticRoutesList());
  } catch (error) {
    yield put(setAPICallStatus(APICallStatus.ERROR));

    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Static Route Delete Failed!',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* createStaticRoute(action: StaticRoutesAction<AddRoutePayload>) {
  try {
    yield put(setCreateRouteAPIStatus(APICallStatus.LOADING));
    yield call(createStaticRouteApi, mapStaticRouteToAPIStaticRoute(action.payload));
    yield put(setCreateRouteAPIStatus(APICallStatus.LOADED));

    const successPayload: NotificationPayload = {
      message: 'Created Static Route Successfully!',
    };
    yield put(setNotificationSuccess(successPayload));

    yield put(setAddRouteDrawerVisibility(false));
    yield put(setCreateRouteAPIStatus(APICallStatus.INITIAL));
    yield put(setCreateRouteError(''));

    yield put(getStaticRoutesList());
  } catch (error) {
    yield put(setCreateRouteAPIStatus(APICallStatus.ERROR));

    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    yield put(setCreateRouteError(errorDesc));

    const errorPayload: NotificationPayload = {
      message: 'Error In Creating Static Route!',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}
function* updateStaticRoute(action: StaticRoutesAction<UpdateRoutePayload>) {
  try {
    yield call(updateRouteDetailsApi, action.payload);
    const successPayload: NotificationPayload = {
      message: 'Updated Route Details Successfully!',
    };
    yield put(setNotificationSuccess(successPayload));
    yield put(setAddRouteDrawerVisibility(false));
    yield put(getStaticRoutesList());
  } catch (error) {
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Error In Updating Route Details',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

export default function* staticRoutesSaga(): IterableIterator<ForkEffect<never>> {
  yield takeLatest(StaticRoutesActionTypes.GET_LIST, getStaticRoutes);
  yield takeLatest(StaticRoutesActionTypes.DELETE_ROUTE, deleteStaticRoute);
  yield takeLatest(StaticRoutesActionTypes.CREATE_ROUTE, createStaticRoute);
  yield takeLatest(StaticRoutesActionTypes.UPDATE_ROUTE_DETAILS, updateStaticRoute);
}
