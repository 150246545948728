import { AxiosPromise } from 'axios';

import {
  CONFIGURATION_NODE_TYPES,
  FILE_CONTENT_TYPE_MAP,
  NODE_FILE_TYPES,
} from 'Root/configurations/constants';
import { CONFIGURATION_SERVER_API_BASE_URL, DEVICE_MANAGER_ROOT_URL } from 'Shared/config';
import { trimBackSlash } from 'Shared/utils/core/string';
import { authAxios } from 'Shared/utils/user';

import {
  configurationApplyResponseAxiosThen,
  mapAPIBlobRefListToBlobRefList,
} from './dataFormatter';
import {
  APIConfigurationAdd,
  BlobRefList,
  CofigurationDetails,
  ConfigurationApplyDeviceId,
  ConfigurationList,
  ConfigurationNode,
  ConfigurationResponse,
} from './types';

export const getConfigurationsApi = (): AxiosPromise<ConfigurationList> =>
  authAxios({
    method: 'GET',
    url: `${CONFIGURATION_SERVER_API_BASE_URL}tree`,
  });

export const getConfigurationDetailsApi = (name: string): AxiosPromise<CofigurationDetails> =>
  authAxios({
    method: 'GET',
    url: `${CONFIGURATION_SERVER_API_BASE_URL}tree/${name}`,
  });

export const getBlobRefsApi = (treeNames: string[]): Promise<BlobRefList> => {
  const params = new URLSearchParams();
  treeNames.forEach((name) => params.append('treeNames', name));

  return authAxios({
    method: 'GET',
    url: `${CONFIGURATION_SERVER_API_BASE_URL}treeblobs`,
    params,
  }).then((response) => mapAPIBlobRefListToBlobRefList(response.data.data));
};

export const addConfigurationApi = (name: string): AxiosPromise<ConfigurationNode> =>
  authAxios({
    method: 'PUT',
    url: `${CONFIGURATION_SERVER_API_BASE_URL}tree/${name}`,
    data: {
      type: CONFIGURATION_NODE_TYPES.value, // Root node is always a ValueNode
    },
  });

export const addConfigurationNodeApi = ({
  path,
  name,
  treeName,
  type,
  fileType,
  formData,
  options = {},
}: APIConfigurationAdd): AxiosPromise<ConfigurationNode> => {
  const fileTypeParam = fileType === NODE_FILE_TYPES.BINARY ? 'filenode' : 'tree';
  const url = `${CONFIGURATION_SERVER_API_BASE_URL}${fileTypeParam}/${treeName}/${path}${name}`;
  const typeParam =
    type === CONFIGURATION_NODE_TYPES.file ? { contentType: FILE_CONTENT_TYPE_MAP[fileType] } : {};
  const data =
    fileType === NODE_FILE_TYPES.BINARY
      ? formData
      : {
          type,
          ...typeParam,
        };

  return authAxios({
    method: 'PUT',
    url,
    data,
    ...options,
  });
};

export const deleteConfigurationNodeApi = (
  path: string,
  version?: number,
): AxiosPromise<ConfigurationResponse> =>
  authAxios({
    method: 'DELETE',
    url: `${CONFIGURATION_SERVER_API_BASE_URL}${trimBackSlash(path)}`,
    data: { version },
  });

export const getTreeBlobsDetail = (labels: string[], treeNames?: string): Promise<BlobRefList> => {
  const params = new URLSearchParams();
  labels?.forEach((label) => params.append('labels', label));
  params.append('indices', 'false');
  if (treeNames) {
    params.append('treeNames', treeNames);
  }

  return authAxios({
    method: 'GET',
    url: `${CONFIGURATION_SERVER_API_BASE_URL}resolve/tree`,
    params,
  }).then((response) => mapAPIBlobRefListToBlobRefList(response.data.data));
};

// TODO: its used in device module decide if it should be moved to device services
export const applyDeviceConfigurationsApi = (
  deviceId: string,
): Promise<ConfigurationApplyDeviceId[]> =>
  authAxios({
    method: 'POST',
    url: `${DEVICE_MANAGER_ROOT_URL}parameters`,
    data: {
      device_list: [deviceId],
    },
  }).then((response) => configurationApplyResponseAxiosThen(response.data));

export const applyConfigurationTreeApi = (
  deviceList: string[],
  treeNames: string[],
): Promise<ConfigurationApplyDeviceId[]> =>
  authAxios({
    method: 'POST',
    url: `${DEVICE_MANAGER_ROOT_URL}parameters`,
    data: {
      device_list: deviceList,
      tree_names: treeNames,
    },
  }).then((response) => configurationApplyResponseAxiosThen(response.data));

export const saveConfigurationFileDataApi = (
  path: string,
  treeName: string,
  data: string,
): AxiosPromise<ConfigurationNode> =>
  authAxios({
    method: 'PUT',
    url: `${CONFIGURATION_SERVER_API_BASE_URL}tree/${treeName}/${path}`,
    data,
  });

export const cloneConfigurationApi = (
  treeName: string,
  newTreeName: string,
  destinationProject: string,
): AxiosPromise<void> =>
  authAxios({
    method: 'POST',
    url: `${CONFIGURATION_SERVER_API_BASE_URL}copy/tree/${treeName}`,
    data: {
      destinationProjectGUID: destinationProject,
      destinationTreeName: newTreeName,
    },
  });

export const renameConfigurationApi = (
  treeName: string,
  newTreeName: string,
): AxiosPromise<ConfigurationResponse> =>
  authAxios({
    method: 'POST',
    url: `${CONFIGURATION_SERVER_API_BASE_URL}move/tree/${treeName}`,
    data: {
      destinationTreeName: newTreeName,
    },
  });
