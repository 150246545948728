import { BillingAction, BillingActionPayload, BillingState, Plan } from 'Models/billing/types';
import { APICallStatus } from 'Models/common/types';

import BillingActionTypes from './actionTypes';

const initialState: BillingState = {
  plans: [],
  planDetailsApiStatus: APICallStatus.INITIAL,
};

export default (
  state: BillingState = initialState,
  action: BillingAction<BillingActionPayload>,
): BillingState => {
  switch (action.type) {
    case BillingActionTypes.SET_PLANS:
      return { ...state, plans: action.payload as Plan[] };

    case BillingActionTypes.FETCHING_PLAN_DETAILS: {
      return { ...state, planDetailsApiStatus: APICallStatus.LOADING };
    }

    case BillingActionTypes.PLAN_DETAILS_LOADED: {
      return { ...state, planDetailsApiStatus: APICallStatus.LOADED };
    }

    case BillingActionTypes.PLAN_DETAILS_ERROR: {
      return { ...state, planDetailsApiStatus: APICallStatus.ERROR };
    }

    default:
      return state;
  }
};
