import React, { lazy } from 'react';

import { Project } from 'Models/projects/types';

const NonAuthHeader = lazy(() => import('./NonAuthHeader'));
const AuthHeader = lazy(() => import('./AuthHeader'));

interface Props {
  userToken: string;
  disableNonAuthHeader: boolean;
  userFilteredProjects: Project[];
}

const HeaderRenderer: React.FC<Props> = (props) => {
  if (!props.userToken && !props.disableNonAuthHeader) {
    return <NonAuthHeader />;
  }

  if (props.userToken) {
    return <AuthHeader userFilteredProjects={props.userFilteredProjects} />;
  }

  return null;
};

export default HeaderRenderer;
