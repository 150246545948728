enum NetworkActionTypes {
  GET_NETWORK_LIST = 'GET_NETWORK_LIST',
  SET_NETWORK_LIST = 'SET_NETWORK_LIST',
  SET_NETWORK_LIST_API_STATUS = 'SET_NETWORK_LIST_API_STATUS',
  ADD_NETWORK = 'ADD_NETWORK',
  SET_ADD_NETWORK_VISIBILITY = 'SET_ADD_NETWORK_VISIBILITY',
  SET_ADD_NETWORK_API_STATUS = 'SET_ADD_NETWORK_API_STATUS',
  GET_DEVICES_LIST = 'GET_DEVICES_LIST',
  SET_DEVICES_LIST = 'SET_DEVICES_LIST',
  SET_DEVICES_LIST_API_STATUS = 'SET_DEVICES_LIST_API_STATUS',
  GET_NETWORK_DETAILS = 'GET_NETWORK_DETAILS',
  SET_NETWORK_DETAILS = 'SET_NETWORK_DETAILS',
  SET_NETWORK_DETAILS_API_STATUS = 'SET_NETWORK_DETAILS_API_STATUS',
  DELETE_NETWORK = 'DELETE_NETWORK',
  DOWNLOAD_NETWORK_MANIFEST = 'DOWNLOAD_NETWORK_MANIFEST',
  SET_DOWNLOAD_NETWORK_MANIFEST_API_STATUS = 'SET_DOWNLOAD_NETWORK_MANIFEST_API_STATUS',
}

export default NetworkActionTypes;
