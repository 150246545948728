import { AxiosError } from 'axios';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';

import { APICallStatus } from 'Models/common/types';
import { ConfigVars, DeviceRuntimes, FilterDevicesReq } from 'Models/devices/types';
import { NotificationPayload } from 'Models/notifications/types';
import { getManifest, networkToCLINetworkSpec } from 'Root/models/cliManifest/dataFormatters';
import { ManifestKinds } from 'Root/models/cliManifest/types';
import { getArchDeviceMap } from 'Root/models/devices/service';
import {
  createNetworkResource,
  deleteNetworkApi,
  getNetworkDetailsApi,
  getNetworkListApi,
} from 'Root/models/networks/service';
import { CreateNetworkPayload, NetworkAction, NetworkPayload } from 'Root/models/networks/types';
import { getMessageFromError } from 'Shared/utils/common';
import { CallReturnType } from 'Types/saga';

import { downloadYAML } from '../cliManifest/actions';
import { setNotificationFail, setNotificationSuccess } from '../notifications/action';

import {
  getNetworkList as getNetworkListAction,
  setAddNetworkAPICallStatus,
  setAddNetworkVisibility,
  setDeviceList,
  setDeviceListAPICallStatus,
  setDownloadNetworkManifestAPICallStatus,
  setNetworkDetails,
  setNetworkDetailsAPICallStatus,
  setNetworkList,
  setNetworkListAPICallStatus,
} from './actions';
import NetworkActionTypes from './actionTypes';

function* getNetworkList(action: NetworkAction<NetworkPayload>) {
  try {
    yield put(setNetworkListAPICallStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getNetworkListApi> = yield call(
      getNetworkListApi,
      action.payload as string[],
    );
    yield put(setNetworkList(data));

    yield put(setNetworkListAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setNetworkListAPICallStatus(APICallStatus.ERROR));

    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: err?.message,
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* addNework(action: NetworkAction<CreateNetworkPayload>) {
  try {
    yield put(setAddNetworkAPICallStatus(APICallStatus.LOADING));
    yield call(createNetworkResource, action.payload);
    const successPayload: NotificationPayload = {
      message: 'Created Network Successfully!',
    };

    yield put(setNotificationSuccess(successPayload));
    yield put(setAddNetworkAPICallStatus(APICallStatus.LOADED));
    yield put(setAddNetworkVisibility(false));

    yield put(getNetworkListAction([]));
  } catch (error) {
    yield put(setAddNetworkAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Error Creating Network',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* getDevicesList() {
  try {
    yield put(setDeviceListAPICallStatus(APICallStatus.LOADING));
    const query: FilterDevicesReq = {
      operator: '$or',
      specs: {
        operator: '$and',
        args: [
          {
            operator: '$or',
            args: [
              {
                operator: '$eq',
                args: ['cpuarch', 'amd64'],
              },
              {
                operator: '$eq',
                args: ['cpuarch', 'x86_64'],
              },
            ],
          },
          {
            operator: '$or',
            args: [
              {
                operator: '$eq',
                args: [ConfigVars.RUNTIME, DeviceRuntimes.DOCKER_COMPOSE],
              },
              {
                operator: '$eq',
                args: [ConfigVars.DOCKER, true],
              },
              {
                operator: '$eq',
                args: [ConfigVars.DOCKER, 'True'],
              },
            ],
          },
        ],
      },
    };
    const data: CallReturnType<typeof getArchDeviceMap> = yield call(getArchDeviceMap, query);
    yield put(setDeviceList(data));
    yield put(setDeviceListAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setDeviceListAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Error Getting Device List',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* getNetworkDetails(action: NetworkAction<string>) {
  try {
    yield put(setNetworkDetailsAPICallStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getNetworkDetailsApi> = yield call(
      getNetworkDetailsApi,
      action.payload,
    );
    yield put(setNetworkDetails(data));
    yield put(setNetworkDetailsAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setNetworkDetailsAPICallStatus(APICallStatus.ERROR));

    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Error Getting Network Details',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* downloadNetworkManifest(action: NetworkAction<string>) {
  try {
    yield put(setDownloadNetworkManifestAPICallStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getNetworkDetailsApi> = yield call(
      getNetworkDetailsApi,
      action.payload,
    );
    const networkSpec = networkToCLINetworkSpec(data);
    const manifest = getManifest(ManifestKinds.NETWORK, { name: data.name }, networkSpec);

    yield put(downloadYAML(manifest));

    yield put(setDownloadNetworkManifestAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setDownloadNetworkManifestAPICallStatus(APICallStatus.ERROR));
  }
}

function* deleteNetwork(action: NetworkAction<string>) {
  try {
    yield put(setNetworkListAPICallStatus(APICallStatus.LOADING));
    yield call(deleteNetworkApi, action.payload as string);
    const successPayload: NotificationPayload = {
      message: 'Network Deleted Successfully!',
    };

    yield put(setNotificationSuccess(successPayload));
    yield put(setNetworkListAPICallStatus(APICallStatus.LOADED));
    yield put(getNetworkListAction([]));
  } catch (error) {
    yield put(setNetworkListAPICallStatus(APICallStatus.ERROR));

    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Network Delete Failed!',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

export default function* networkSaga(): IterableIterator<ForkEffect<never>> {
  yield takeLatest(NetworkActionTypes.GET_NETWORK_LIST, getNetworkList);
  yield takeLatest(NetworkActionTypes.ADD_NETWORK, addNework);
  yield takeLatest(NetworkActionTypes.GET_DEVICES_LIST, getDevicesList);
  yield takeLatest(NetworkActionTypes.GET_NETWORK_DETAILS, getNetworkDetails);
  yield takeLatest(NetworkActionTypes.DELETE_NETWORK, deleteNetwork);
  yield takeLatest(NetworkActionTypes.DOWNLOAD_NETWORK_MANIFEST, downloadNetworkManifest);
}
