import {
  NotificationAction,
  NotificationState,
  NotificationType,
} from 'Models/notifications/types';

import ActionTypes from './actionTypes';

const initialState: NotificationState = {
  showNotification: false,
  type: NotificationType.INFO,
  message: '',
  description: '',
  key: '',
  buttonText: '',
};
const notification = (
  state = initialState,
  action: NotificationAction<NotificationState>,
): NotificationState => {
  const { payload } = action;

  switch (action.type) {
    case ActionTypes.ERROR: {
      return {
        ...state,
        ...payload,
      };
    }

    case ActionTypes.SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }

    case ActionTypes.INFO: {
      return {
        ...state,
        ...payload,
      };
    }

    case ActionTypes.WARNING: {
      return {
        ...state,
        ...payload,
      };
    }

    case ActionTypes.HIDE: {
      return {
        ...state,
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default notification;
