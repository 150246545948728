export type Modify<T, R> = Omit<T, keyof R> & R;

export enum URLParamsEnum {
  PROJECT = 'p',
  EMBED = 'embed',
  SELECTOR = 'selector',
}

export type EmbedParameters = {
  [URLParamsEnum.EMBED]: string | null;
  [URLParamsEnum.SELECTOR]: string[] | null;
};

export enum PathParamsEnum {
  DEVICE_ID = 'device_id',
}
