import { DEPLOYMENT_URL } from 'Root/shared/config';
import { authAxios } from 'Root/shared/utils/user';

import { DeploymentPhase } from '../deployments/types';

import { transformDeploymentNameList } from './dataFormatters';
import { DeploymentSpecification, DeployPackagePayloadData } from './types';

export const getDeploymentNameList = (): Promise<string[]> => {
  const params = new URLSearchParams();
  const phases = [
    DeploymentPhase.SUCCEEDED,
    DeploymentPhase.IN_PROGRESS,
    DeploymentPhase.PROVISIONING,
  ];
  phases?.forEach((phase) => params.append('phases', phase));
  return authAxios(
    {
      method: 'GET',
      url: DEPLOYMENT_URL,
      params,
    },
    true,
    true,
  ).then((response) => transformDeploymentNameList(response.data?.items || []));
};

export const createDeploymentAPI = (
  data: DeployPackagePayloadData,
): Promise<DeploymentSpecification> =>
  authAxios(
    {
      method: 'POST',
      url: DEPLOYMENT_URL,
      data,
    },
    true,
    true,
  ).then((response) => response.data);
