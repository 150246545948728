export const isEmptyObj = <V>(obj?: V): boolean => Object.keys(obj ?? {}).length === 0;

export const omitEmpty = <V extends Record<string, any>>(obj: V): Partial<V> =>
  Object.entries(obj).reduce<Partial<V>>((acc, [key, value]) => {
    if (value) {
      acc[key as keyof V] = value;
    }
    return acc;
  }, {});

export const objLength = <V>(obj: V): number => Object.keys(obj || {}).length;

export const omitByKeys = <V>(obj: V, keys: (keyof V)[]): Partial<V> =>
  Object.entries(obj).reduce((acc: Partial<V>, [key, value]) => {
    const typedKey = key as keyof V;

    if (!keys.includes(typedKey)) {
      acc[typedKey] = value;
    }
    return acc;
  }, {});

export const mapObjValues = <V, MV>(
  obj: Record<string, V>,
  valMapper: (arg: V) => MV,
): Record<string, MV> =>
  Object.entries(obj).reduce((acc: Record<string, MV>, [key, value]) => {
    acc[key] = valMapper(value);
    return acc;
  }, {});

export const hasKey = <V>(obj: V, key: keyof V): boolean =>
  Object.prototype.hasOwnProperty.call(obj, key);

/* eslint-disable no-restricted-syntax, no-prototype-builtins, no-continue */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const isEqual = (x: any, y: any): boolean => {
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (const p in x) {
    if (!x.hasOwnProperty(p)) continue;
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof x[p] !== 'object') return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!isEqual(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (const p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
  // allows x[ p ] to be set to undefined

  return true;
};

/* eslint-enable */

export const cloneDeep = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const pickBy = <T>(object: T): Partial<T> => {
  const obj: Partial<T> = {};
  Object.keys(object).forEach((key) => {
    const typedKey = key as keyof T;
    if (object[typedKey]) {
      obj[typedKey] = object[typedKey];
    }
  });
  return obj;
};
