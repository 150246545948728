enum PackagesActionTypes {
  GET_PACKAGES_LIST = 'GET_PACKAGES_LIST',
  SET_PACKAGES_LIST = 'SET_PACKAGES_LIST',
  SET_PACKAGES_LIST_API_STATUS = 'SET_PACKAGES_LIST_API_STATUS',
  DELETE_PACKAGE = 'DELETE_PACKAGE',
  IMPORT_PACKAGE_MODAL_VISIBILITY = 'IMPORT_PACKAGE_MODAL_VISIBILITY',
  SET_CREATE_PACKAGE_API_STATUS = 'SET_CREATE_PACKAGE_API_STATUS',
  CREATE_PACKAGE = 'CREATE_PACKAGE',
  CREATE_PACKAGE_MODAL_VISIBILITY = 'CREATE_PACKAGE_MODAL_VISIBILITY',
  GET_PACKAGE_DETAILS = 'GET_PACKAGE_DETAILS',
  SET_PACKAGE_DETAILS = 'SET_PACKAGE_DETAILS',
  SET_PACKAGE_DETAILS_API_STATUS = 'SET_PACKAGE_DETAILS_API_STATUS',
  SET_CLONE_PACKAGE_DETAILS = 'SET_CLONE_PACKAGE_DETAILS',
  GET_SECRETS_LIST = 'GET_SECRETS_LIST',
  SET_SECRETS_LIST = 'SET_SECRETS_LIST',
  SET_SECRETS_LIST_API_STATUS = 'SET_SECRETS_LIST_API_STATUS',
  GET_DESTINATION_SECRETS_LIST = 'GET_DESTINATION_SECRETS_LIST',
  SET_DESTINATION_SECRETS_LIST = 'SET_DESTINATION_SECRETS_LIST',
  SET_DESTINATION_SECRETS_LIST_API_STATUS = 'SET_DESTINATION_SECRETS_LIST_API_STATUS',
  DOWNLOAD_PACKAGE_MANIFEST = 'DOWNLOAD_PACKAGE_MANIFEST',
  DOWNLOAD_PACKAGE_MANIFEST_API_STATUS = 'DOWNLOAD_PACKAGE_MANIFEST_API_STATUS',
  GET_PACKAGE_DEPLOYMENT_LIST = 'GET_PACKAGE_DEPLOYMENT_LIST',
  SET_PACKAGE_DEPLOYMENT_LIST = 'SET_PACKAGE_DEPLOYMENT_LIST',
  SET_PACKAGE_DEPLOYMENT_LIST_API_STATUS = 'SET_PACKAGE_DEPLOYMENT_LIST_API_STATUS',
}

export default PackagesActionTypes;
