import React from 'react';
import { Button } from 'antd';
import { Store } from 'redux';

import { AppAction, AppState } from 'Models/common/types';

import './styles.scss';

/* Passing store as prop to this component as antd renders the notification outside the app <Provider>
 ** Error: Could not find "store" in the context of "Connect(NotificationCTAButton)"
 */
interface Props {
  buttonText: string | undefined;
  store: Store<AppState, AppAction>;
  closeNotification: VoidFunction;
  buttonAction?: () => AppAction;
}

const NotificationCTAButton: React.FC<Props> = (props) => {
  const onClickHandler = () => {
    if (props.buttonAction) {
      props.store.dispatch(props.buttonAction());
    }
    props.closeNotification();
  };

  return (
    <Button type="primary" size="middle" className="cta-button" onClick={onClickHandler}>
      {props.buttonText}
    </Button>
  );
};

export default NotificationCTAButton;
