import { AxiosPromise, AxiosRequestConfig } from 'axios';

import { SECRETS_ROOT_URL } from 'Shared/config';
import { authAxios } from 'Shared/utils/user';

import { mapAPISecretListToSecretList } from './dataFormatters';
import { CreateSecretAPIPayload, Secret } from './types';

export const getSecretsApi = (options: Partial<AxiosRequestConfig> = {}): AxiosPromise<Secret[]> =>
  authAxios(
    {
      method: 'GET',
      url: SECRETS_ROOT_URL,
      headers: options.headers || {},
    },
    true,
    true,
  ).then((response) => ({
    ...response,
    data: mapAPISecretListToSecretList(response.data.items || []),
  }));

export const addSecretApi = (data: CreateSecretAPIPayload): AxiosPromise<Secret> =>
  authAxios(
    {
      method: 'POST',
      url: SECRETS_ROOT_URL,
      data,
    },
    true,
    true,
  );

export const deleteSecretApi = (secretName: string): AxiosPromise<void> =>
  authAxios(
    {
      method: 'DELETE',
      url: `${SECRETS_ROOT_URL}${secretName}/`,
    },
    true,
    true,
  );

export const editSecretApi = (data: CreateSecretAPIPayload, name: string): AxiosPromise<Secret> =>
  authAxios(
    {
      method: 'PUT',
      url: `${SECRETS_ROOT_URL}${name}/`,
      data,
    },
    true,
    true,
  );
