import { AxiosPromise } from 'axios';

import { DISKS_API_BASE_URL } from 'Shared/config';
import { authAxios } from 'Shared/utils/user';

import { transformDiskDetailsData, transformDisksList } from './dataFormatter';
import { CreateDiskReq, Disk, DiskSpecification } from './types';

export const getDisksListApi = (depIds: string[] = []): Promise<Disk[]> => {
  const params = new URLSearchParams();
  depIds.forEach((depId) => params.append('deployment_guid', depId));
  return authAxios(
    {
      method: 'GET',
      url: DISKS_API_BASE_URL,
      params,
    },
    true,
    true,
  ).then((res) => transformDisksList(res.data?.items as DiskSpecification[]) || []);
};

export const getDiskDetailsApi = (name: string): Promise<Disk> =>
  authAxios(
    {
      url: `${DISKS_API_BASE_URL}/${name}/`,
    },
    true,
    true,
  ).then((res) => transformDiskDetailsData(res.data) || {});

export const createDiskApi = (data: CreateDiskReq): AxiosPromise<void> =>
  authAxios(
    {
      method: 'POST',
      url: DISKS_API_BASE_URL,
      data,
    },
    true,
    true,
  );

export const deleteDiskApi = (name: string): AxiosPromise<void> =>
  authAxios(
    {
      method: 'DELETE',
      url: `${DISKS_API_BASE_URL}/${name}/`,
    },
    true,
    true,
  );
