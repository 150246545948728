import axios, { AxiosPromise } from 'axios';

import { CATALOG_ROOT_URL } from 'Shared/config';
import storeUtilAdapterInst from 'Shared/utils/storeUtilsAdapter';
import { authAxios } from 'Shared/utils/user';

import { mapAPIMplacePkgDetailsToMplacePkgDetails } from './dataFormatters';
import { MplacePkgDetails } from './types';

const MPLACE_ROOT_URL = `${CATALOG_ROOT_URL}marketplace`;

export const getMplacePkgDetails = (slug: string): Promise<MplacePkgDetails> => {
  const userToken: string = storeUtilAdapterInst.getUserToken();
  const apiAdapter = userToken ? authAxios : axios;
  return apiAdapter({
    url: `${MPLACE_ROOT_URL}/product/${slug}/detail`,
  }).then((response) => mapAPIMplacePkgDetailsToMplacePkgDetails(response.data));
};
export const installMplacePackage = (
  slug: string,
  version: string,
  project?: string,
): AxiosPromise<void> =>
  authAxios({
    url: `${MPLACE_ROOT_URL}/product/${slug}/install/${version}`,
    headers: project ? { project } : {},
  });
