import React, { lazy, Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import classNames from 'classnames';

import EmbedDeviceSelector from 'Components/embedDeviceSelector';
import AppLoader from 'Components/loader';
import ToastNotification from 'Components/toastNotifications';
import { AUTH_LANDING_PAGE_ROUTE, LAYOUT_ROOT_CLASS, LAYOUT_SCROLLER_ID } from 'Constants/layout';
import { APICallStatus, AppState, EntityState, User, UserRoles } from 'Models/common/types';
import { Organization } from 'Models/organization/types';
import { Project } from 'Models/projects/types';
import { getUserDetails, initPendo } from 'RioRedux/common/actions';
import { getOrgDetails } from 'RioRedux/organization/actions';
import { listProjects } from 'RioRedux/projects/actions';
import useEmbedParams from 'Shared/hooks/useEmbedParams';

import ContentRenderer from './ContentRenderer';
import HeaderRenderer from './HeaderRenderer';
import { NON_AUTH_ROUTES } from './Layout.constants';

const AuthSidebar = lazy(() => import('./AuthSidebar'));

interface Props {
  isNewUser: boolean;
  orgDetails: Organization;
  projects: Project[];
  projectAPIStatus: APICallStatus;
  userDetails: User;
  userToken: string;
  showBanner: boolean;
  selectedOrgGUID: string;
  getUserDetails: typeof getUserDetails;
  listProjects: (projectId: string) => void;
  getOrgDetails: typeof getOrgDetails;
  initPendo: typeof initPendo;
}

const rootClassName = LAYOUT_ROOT_CLASS;
const rootContentCName = `${LAYOUT_ROOT_CLASS}__inner`;

const AppLayout: React.FC<Props> = (props) => {
  const { userToken, userDetails, selectedOrgGUID, projects } = props;
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const location = useLocation();
  const params = useEmbedParams();
  const embed = params.embed === 'true';
  const hasSelectors = Boolean(params.selector?.length);
  const isUserDeactivatedInOrg =
    userDetails.userStateInOrgs.find(({ organizationGUID }) => organizationGUID === selectedOrgGUID)
      ?.userState === EntityState.DEACTIVATED;

  useEffect(() => {
    if (userToken) {
      props.getUserDetails();
    }
  }, [userToken]);

  useEffect(() => {
    if (userDetails.guid) {
      props.initPendo(userDetails.guid);
    }
  }, [userDetails.guid]);

  useEffect(() => {
    if (!isUserDeactivatedInOrg) {
      if (userDetails.guid) {
        props.listProjects(userDetails.guid);
      }

      if (userDetails.organization?.guid) {
        props.getOrgDetails(selectedOrgGUID);
      }
    }
  }, [userDetails.guid, userDetails.organization?.guid]);

  const runTour =
    props.projectAPIStatus === APICallStatus.LOADED &&
    projects?.length === 0 &&
    location.pathname === AUTH_LANDING_PAGE_ROUTE;
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const shouldDisableLayoutElements = !NON_AUTH_ROUTES.includes(location.pathname);

  useEffect(() => {
    setCollapsed(!runTour);
  }, [runTour]);

  const stateInCurrentOrg = userDetails.userStateInOrgs.find(
    ({ organizationGUID }) => organizationGUID === selectedOrgGUID,
  )?.userState;
  const isSelectedOrgPrimary = selectedOrgGUID === userDetails.organization?.guid;
  const defaultView = stateInCurrentOrg === EntityState.ACTIVATED;
  const suspendedView = stateInCurrentOrg === EntityState.SUSPENDED;
  const ownerView = userDetails.roleInOrganization === UserRoles.ADMIN && isSelectedOrgPrimary;
  const leftbarState = [EntityState.ACTIVATED, EntityState.SUSPENDED].includes(
    props.orgDetails.state ?? EntityState.SUSPENDED,
  );
  const leftBarDisabled =
    (userToken && props.isNewUser) || !leftbarState || !defaultView || suspendedView;

  return (
    <Suspense fallback={<AppLoader />}>
      <Layout
        className={classNames(rootClassName, 'antd-override', {
          [`${rootClassName}--has-embed`]: embed,
        })}
      >
        {userToken && !embed && !isUserDeactivatedInOrg && (
          <AuthSidebar
            disabled={leftBarDisabled}
            collapsed={collapsed}
            isOwnerView={ownerView}
            selectedKeys={selectedKeys}
            setCollapsed={setCollapsed}
            setSelectedKeys={setSelectedKeys}
          />
        )}
        <Layout
          id={LAYOUT_SCROLLER_ID}
          className={classNames(rootContentCName, 'antd-override', {
            [`${rootContentCName}--has-embed`]: embed,
            [`${rootContentCName}--has-selectors`]: hasSelectors,
            [`${rootContentCName}--non-auth`]: !userToken,
            [`${rootContentCName}--has-header`]: userToken,
            [`${rootContentCName}--has-banner`]: props.showBanner,
          })}
        >
          {!embed && (
            <HeaderRenderer
              disableNonAuthHeader={shouldDisableLayoutElements}
              userToken={userToken}
              userFilteredProjects={projects}
            />
          )}
          {embed && hasSelectors && (
            <div className={`${rootClassName}__embed-selectors`}>
              <EmbedDeviceSelector />
            </div>
          )}
          <ContentRenderer disableFooter={shouldDisableLayoutElements} />
        </Layout>
      </Layout>

      <ToastNotification />
    </Suspense>
  );
};

const mapStateToProps = ({ common, projects, organization }: AppState) => ({
  userToken: common.userToken,
  isNewUser: common.isNewUser,
  userDetails: common.userDetails,
  orgDetails: organization.details,
  projects: projects.list,
  projectAPIStatus: projects.apiStatus,
  showBanner: common.showBanner,
  selectedOrgGUID: common.selectedOrgGUID,
});

const actionCreators = {
  getUserDetails,
  getOrgDetails,
  listProjects,
  initPendo,
};

export default connect(mapStateToProps, actionCreators)(AppLayout);
