export enum VPN {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

// Todo- Use common ros-version constants
export enum RosVersions {
  MELODIC = 'melodic',
  KINETIC = 'kinetic',
}

export enum StrategyType {
  SOURCE = 'Source',
  DOCKER = 'Docker',
}
export enum Architectures {
  ARM_32_V7 = 'arm32v7',
  ARM_64_V8 = 'arm64v8',
  AMD_64 = 'amd64',
}

export enum USERROLE {
  ADMIN = 'admin',
  VIEWER = 'viewer',
}
