import { AxiosPromise, AxiosRequestConfig } from 'axios';

import {
  transformPackgeDeploymentListData,
  transformPackgesList,
} from 'Root/models/packages/dataFormatters';
import { DEPLOYMENT_URL, PACKAGES_LIST_URL_V2 } from 'Shared/config';
import { authAxios } from 'Shared/utils/user';

import {
  CreatePackagePayload,
  GetDeletePackagePayload,
  GetPackageDeploymentListPayload,
  PackageDeploymentList,
  PackageList,
  PackageSpecification,
} from './types';

const TIMEOUT_DELAY = 1_80_000;

export const createPackage = ({
  project,
  data,
}: CreatePackagePayload): Promise<PackageSpecification> =>
  authAxios(
    {
      method: 'POST',
      url: PACKAGES_LIST_URL_V2,
      timeout: TIMEOUT_DELAY,
      data,
      headers: {
        project,
      },
    },
    true,
    true,
  ).then((response) => response.data);

export const getPackages = (options: AxiosRequestConfig = {}): Promise<PackageList[]> =>
  authAxios(
    {
      method: 'GET',
      url: PACKAGES_LIST_URL_V2,
      ...options,
    },
    true,
    true,
  ).then((response) => transformPackgesList(response.data?.items || []));

export const deletePackage = ({ name, version }: GetDeletePackagePayload): AxiosPromise<void> =>
  authAxios(
    {
      method: 'DELETE',
      url: `${PACKAGES_LIST_URL_V2}${name}/`,
      timeout: TIMEOUT_DELAY,
      params: {
        version,
      },
    },
    true,
    true,
  );

export const getPackageDetails = ({
  name,
  version,
}: GetDeletePackagePayload): Promise<PackageSpecification> =>
  authAxios(
    {
      method: 'GET',
      url: `${PACKAGES_LIST_URL_V2}${name}/`,
      timeout: TIMEOUT_DELAY,
      params: {
        version,
      },
    },
    true,
    true,
  ).then((response) => response.data);

export const getPackageDeploymentList = (
  data: GetPackageDeploymentListPayload,
): Promise<PackageDeploymentList[]> => {
  const { packageName, packageVersion, phases } = data;
  const params = new URLSearchParams();
  params.append('packageName', packageName);
  params.append('packageVersion', packageVersion);
  phases?.forEach((phase) => params.append('phases', phase));
  return authAxios(
    {
      method: 'GET',
      url: DEPLOYMENT_URL,
      params,
    },
    true,
    true,
  ).then((response) => transformPackgeDeploymentListData(response.data.items ?? []));
};
