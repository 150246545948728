import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface Props {
  className?: string;
}

const rootClassName = 'rio-app-loader';

const Loader: React.FC<Props> = (props) => (
  <div className={classNames(rootClassName, props.className)}>
    <div className={`${rootClassName}__spinner`}>Loading...</div>
  </div>
);

export default Loader;
