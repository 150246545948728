import { mapAPIOrgToOrg } from '../organization/dataFormatters';
import { mapUserAPIProjectsListToProjectsList } from '../projects/dataFormatters';

import { APIPlan, APIPlanMetaData, APIUser, Plan, PlanMetaData, UserAPIReturn } from './types';

export const mapAPIPlanToPlan = (apiPlan: APIPlan): Plan => ({
  id: apiPlan.ID,
  createdAt: apiPlan.CreatedAt,
  updatedAt: apiPlan.UpdatedAt,
  deletedAt: apiPlan.DeletedAt,
  name: apiPlan.name,
  metadata: apiPlan.metadata,
  planType: apiPlan.planType,
  isActive: apiPlan.isActive,
  displayName: apiPlan.displayName,
});

export const mapAPIPlanMetaToPlanMeta = (apiPlanMeta: APIPlanMetaData): PlanMetaData => ({
  id: apiPlanMeta.ID,
  createdAt: apiPlanMeta.CreatedAt,
  updatedAt: apiPlanMeta.UpdatedAt,
  deletedAt: apiPlanMeta.DeletedAt,
  organizationID: apiPlanMeta.organizationID,
  trialPeriodEndDate: apiPlanMeta.trialPeriodEndDate,
  pricingInfo: apiPlanMeta.pricingInfo,
  planID: apiPlanMeta.planID,
  plan: mapAPIPlanToPlan(apiPlanMeta.plan),
});

export const mapAPIUserToUser = (apiUser: APIUser): UserAPIReturn => ({
  guid: apiUser.guid,
  firstName: apiUser.firstName,
  lastName: apiUser.lastName,
  emailID: apiUser.emailID,
  state: apiUser.state,
  roleInOrganization: apiUser.roleInOrganization,
  featuresEnabled: apiUser.featuresEnabled,
  projects: mapUserAPIProjectsListToProjectsList(apiUser.projects),
  organization: apiUser.organization && mapAPIOrgToOrg(apiUser.organization),
  organizations: apiUser.organizations?.map(mapAPIOrgToOrg),
  userStateInOrgs: apiUser.userStateInOrgs,
});
