import { notification } from 'antd';
import { ForkEffect, takeLatest } from 'redux-saga/effects';

import { NotificationAction } from 'Models/notifications/types';

import ActionTypes from './actionTypes';

function dismissNotification(action: NotificationAction<string>) {
  notification.close(action.payload as string);
}

export default function* notificationSaga(): IterableIterator<ForkEffect<never>> {
  yield takeLatest(ActionTypes.DISMISS, dismissNotification);
}
