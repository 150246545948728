import { AxiosPromise } from 'axios';
import qs from 'qs';

import {
  CATALOG_ROOT_URL,
  COMMAND_API_URL,
  DASHBOARD_ENDPOINT,
  DEVICE_CONFIG_VARIABLES_URL,
  DEVICE_LABELS_URL,
  DEVICE_LOGS_ROOT_URL,
  DEVICE_MANAGER_API_BASE_URL,
  DEVICE_MANAGER_ARCH_DEVICE_MAP_URL,
  DEVICE_MANAGER_KEYS_URL,
  DEVICE_MANAGER_ROOT_URL,
} from 'Shared/config';
import { mapTableConfigToApiQuery } from 'Shared/utils/common';
import { authAxios } from 'Shared/utils/user';

import { TableQuery } from '../common/types';

import {
  mapAddDeviceToAPIAddDevice,
  mapAPIAddDeviceResponseToAddDeviceResponse,
  mapAPIDeviceDetailToDeviceDetail,
  mapAPIDeviceListToDeviceList,
  mapAPIDeviceLogToDeviceLog,
  mapAPIDeviceLogUrlToDeviceLogUrl,
  mapAPILogDetailToLogDetail,
  mapAPIMetricErrorLogToMetricErrorLog,
  mapAPIMetricListToMetricList,
  mapAPIProgressToProgress,
  mapAPIRosbagBlobToRosbagBlob,
  mapLogTableKeysToReq,
} from './dataFormatters';
import {
  AddDevice,
  AddDeviceResponse,
  APIMetric,
  APIReqSubscribeTopic,
  ApiResDevices,
  APIResRosbagBlobs,
  ConfigVariable,
  Device,
  DeviceCondensed,
  DeviceDetails,
  DeviceLogUrl,
  FilterDevicesReq,
  Label,
  LogDetail,
  Metric,
  MetricErrorLog,
  MigrateDevicePayload,
  Progress,
  ReqCmd,
  ReqDeviceLogList,
  ReqDownloadDeviceLog,
  ReqMetricLog,
  ReqUploadLog,
  ResDeviceLog,
  SaveVPNDetailsPayload,
  TerminalData,
} from './types';

const deviceConfigVarEndpoint = `${DEVICE_CONFIG_VARIABLES_URL}device/`;

export const getDevicesApi = (): AxiosPromise<Device[]> =>
  authAxios({
    method: 'GET',
    url: DEVICE_MANAGER_API_BASE_URL,
  }).then((res) => {
    res.data = mapAPIDeviceListToDeviceList(res.data.response.data);
    return res;
  });

export const getDeviceDetailsApi = (deviceId: string): AxiosPromise<ApiResDevices<DeviceDetails>> =>
  authAxios({
    method: 'GET',
    url: `${DEVICE_MANAGER_API_BASE_URL}${deviceId}`,
  }).then((res) => ({
    ...res,
    data: {
      data: mapAPIDeviceDetailToDeviceDetail(res.data.response.data),
      status: res.data.status,
      error: res.data.response.error,
    },
  }));

export const getAuthCredsForDeviceApi = (
  data: AddDevice,
): AxiosPromise<ApiResDevices<AddDeviceResponse>> =>
  authAxios({
    method: 'POST',
    url: `${DEVICE_MANAGER_KEYS_URL}`,
    params: { download_type: 'script' },
    data: mapAddDeviceToAPIAddDevice(data),
  }).then((res) => ({
    ...res,
    data: {
      data: mapAPIAddDeviceResponseToAddDeviceResponse(res.data.response),
      status: res.data.status,
      error: res.data.response.error,
    },
  }));

export const getArchDeviceMap = (
  query: Partial<FilterDevicesReq> = {},
): Promise<DeviceCondensed[]> =>
  authAxios({
    method: 'POST',
    url: DEVICE_MANAGER_ARCH_DEVICE_MAP_URL,
    data: query,
  }).then((res) => res.data.response.data);

export const getDeviceInitDetails = (deviceId: string): AxiosPromise<ApiResDevices<Progress>> =>
  authAxios({
    method: 'GET',
    url: `${DEVICE_MANAGER_API_BASE_URL}${deviceId}/progress`,
  }).then((res) => ({
    ...res,
    data: {
      data: mapAPIProgressToProgress(res.data.response.data),
      status: res.data.status,
      error: res.data.response.error,
    },
  }));

export const saveVPNDetailsApi = (
  deviceId: string,
  deviceVPNData: SaveVPNDetailsPayload,
): AxiosPromise<ApiResDevices<void>> =>
  authAxios({
    method: 'PATCH',
    url: `${DEVICE_MANAGER_API_BASE_URL}${deviceId}/daemons`,
    data: deviceVPNData,
  });

export const getDeviceMetricsApi = (deviceId: string): AxiosPromise<ApiResDevices<Metric[]>> =>
  authAxios({
    method: 'GET',
    url: `${DEVICE_MANAGER_ROOT_URL}metrics/${deviceId}`,
  }).then((res) => ({
    ...res,
    data: {
      data: mapAPIMetricListToMetricList(res.data.response.data),
      status: res.data.status,
      error: res.data.response.error,
    },
  }));

export const subscribeMetricApi = (
  deviceId: string,
  name: string,
  qos: number,
): AxiosPromise<ApiResDevices<void>> =>
  authAxios({
    method: 'POST',
    url: `${DEVICE_MANAGER_ROOT_URL}metrics/${deviceId}`,
    data: { name, config: { qos } },
  });

export const unsubscribeMetricApi = (
  deviceId: string,
  name: string,
): AxiosPromise<ApiResDevices<void>> =>
  authAxios({
    method: 'DELETE',
    url: `${DEVICE_MANAGER_ROOT_URL}metrics/${deviceId}/${name}`,
  });

export const getDeviceTopicsApi = (
  deviceId: string,
): AxiosPromise<ApiResDevices<[number, string]>> =>
  authAxios({
    method: 'GET',
    url: `${DEVICE_MANAGER_ROOT_URL}topics/${deviceId}/status`,
  }).then((res) => ({
    ...res,
    data: {
      data: res.data.response.data,
      status: res.data.status,
      error: res.data.response.error,
    },
  }));

export const getDeviceMetricErrorLogs = ({
  deviceId,
  options,
}: {
  deviceId: string;
  options: ReqMetricLog;
}): AxiosPromise<MetricErrorLog> =>
  authAxios({
    method: 'GET',
    url: `${DASHBOARD_ENDPOINT}api/metrics/${deviceId}/logs?${qs.stringify(options)}`,
  }).then((res) => {
    if (res.data.error) {
      res.data = {
        results: [],
        count: 0,
        currentPage: 0,
        pageSize: 0,
      };
    } else {
      res.data = mapAPIMetricErrorLogToMetricErrorLog(res.data);
    }
    return res;
  });

export const addConfigVariable = (
  deviceId: string,
  key: string,
  value: string,
): AxiosPromise<ApiResDevices<ConfigVariable>> =>
  authAxios({
    method: 'POST',
    url: `${deviceConfigVarEndpoint}${deviceId}`,
    data: { key, value },
  });

export const updateConfigVariable = (
  variableId: number,
  key: string,
  value: string,
): AxiosPromise<ApiResDevices<ConfigVariable>> =>
  authAxios({
    method: 'PUT',
    url: `${DEVICE_CONFIG_VARIABLES_URL}${variableId}`,
    data: { key, value },
  });

export const deleteConfigVariable = (variableId: number): AxiosPromise<ApiResDevices<void>> =>
  authAxios({
    method: 'DELETE',
    url: `${DEVICE_CONFIG_VARIABLES_URL}${variableId}`,
  });

export const getDevicesFilteredByLabelsApi = (
  deviceCriteria: FilterDevicesReq,
): AxiosPromise<ApiResDevices<Device[]>> =>
  authAxios({
    method: 'POST',
    url: `${DEVICE_MANAGER_ARCH_DEVICE_MAP_URL}`,
    data: deviceCriteria,
  });

export const getDeviceLogsList = ({
  deviceId,
  filter,
  page = { number: 1 },
  sort = '-createdAt',
}: ReqDeviceLogList): AxiosPromise<ResDeviceLog> => {
  const reqSort = sort.charAt(0) + mapLogTableKeysToReq(sort.slice(1));
  const params = { filter, page, sort: reqSort };
  return authAxios({
    method: 'GET',
    url: `${DEVICE_LOGS_ROOT_URL}${deviceId}/list`,
    params,
  }).then((res) => ({
    ...res,
    data: {
      meta: res.data.meta,
      data: res.data.response.data.map(mapAPIDeviceLogToDeviceLog),
    },
  }));
};

export const getDeviceLogDetails = (
  deviceId: string,
  uuid: string,
): AxiosPromise<ApiResDevices<LogDetail>> =>
  authAxios({
    method: 'GET',
    url: `${DEVICE_LOGS_ROOT_URL}${deviceId}/status/${uuid}`,
  }).then((res) => ({
    ...res,
    data: {
      data: mapAPILogDetailToLogDetail(res.data.response.data),
      status: res.data.status,
    },
  }));

export const uploadDeviceLog = (
  deviceId: string,
  data: ReqUploadLog,
): AxiosPromise<Pick<LogDetail, 'requestUuid' | 'status'>> =>
  authAxios({
    method: 'POST',
    url: `${DEVICE_LOGS_ROOT_URL}${deviceId}/upload`,
    data,
  }).then((res) => ({
    ...res,
    data: {
      requestUuid: res.data.response.data.request_uuid,
      status: res.data.status,
    },
  }));

export const downloadDeviceLog = ({
  deviceId,
  uuid,
}: ReqDownloadDeviceLog): AxiosPromise<ApiResDevices<DeviceLogUrl>> =>
  authAxios({
    method: 'GET',
    url: `${DEVICE_LOGS_ROOT_URL}${deviceId}/${uuid}`,
  }).then((res) => ({
    ...res,
    data: {
      data: mapAPIDeviceLogUrlToDeviceLogUrl(res.data.response.data),
      status: res.data.status,
    },
  }));

export const deleteDeviceLog = ({
  deviceId,
  uuid,
}: ReqDownloadDeviceLog): AxiosPromise<ApiResDevices<void>> =>
  authAxios({
    method: 'DELETE',
    url: `${DEVICE_LOGS_ROOT_URL}${deviceId}/${uuid}`,
  });

export const cancelDeviceLogUpload = ({
  deviceId,
  uuid,
}: ReqDownloadDeviceLog): AxiosPromise<ApiResDevices<void>> =>
  authAxios({
    method: 'PUT',
    url: `${DEVICE_LOGS_ROOT_URL}${deviceId}/${uuid}/cancel`,
  });

export const createPublicLink = (
  logUUID: string,
  expiryTime: number,
): AxiosPromise<ApiResDevices<string>> =>
  authAxios({
    method: 'POST',
    url: `${DEVICE_LOGS_ROOT_URL}${logUUID}/shared_urls`,
    data: {
      expiry_time: expiryTime,
    },
  }).then((res) => ({
    ...res,
    data: {
      data: res.data.response.data.url_uuid,
      status: res.data.status,
    },
  }));

export const getRosbagBlobListByDeviceId = (
  deviceId: string,
  config: TableQuery,
): Promise<APIResRosbagBlobs> => {
  const updatedFilters = { ...config.filters, 'ros_bag_blobs.device_id': [deviceId] };
  const updatedConfig = { ...config, filters: updatedFilters };

  const searchFields = [
    'ros_bag_blobs.device_id',
    'ros_bag_jobs.name',
    'ros_bag_jobs.deployment_name',
    'filename',
  ];

  const params = mapTableConfigToApiQuery(updatedConfig, searchFields);
  return authAxios({
    method: 'GET',
    url: `${CATALOG_ROOT_URL}rosbag-blobs/list`,
    params,
  }).then((res) => {
    const data = {
      ...res.data,
      data: res.data.data.map(mapAPIRosbagBlobToRosbagBlob),
    };
    return data;
  });
};

export const getBulkConfigVariableDetails = ({
  deviceId,
}: {
  deviceId: string;
}): AxiosPromise<ApiResDevices<ConfigVariable[]>> =>
  authAxios({
    method: 'GET',
    params: { config_type: 'log' },
    url: `${deviceConfigVarEndpoint}${deviceId}`,
  });

export const updateBulkConfigVariableDetails = ({
  deviceId,
  data,
}: {
  deviceId: string;
  data: Record<string, string>;
}): AxiosPromise<ConfigVariable[]> =>
  authAxios({
    method: 'PUT',
    url: `${deviceConfigVarEndpoint}${deviceId}/bulk`,
    data,
  });

export const subscribeTopicApi = (
  deviceId: string,
  data: APIReqSubscribeTopic,
): AxiosPromise<ApiResDevices<[number, string]>> =>
  authAxios({
    method: 'POST',
    url: `${DEVICE_MANAGER_ROOT_URL}topics/${deviceId}/subscribe`,
    data,
  }).then((res) => ({
    ...res,
    data: {
      data: res.data.response.data,
      status: res.data.status,
      error: res.data.response.error,
    },
  }));

export const unsubscribeTopicApi = (
  deviceId: string,
  { name, kind }: Pick<APIMetric, 'name' | 'kind'>,
): AxiosPromise<ApiResDevices<[number, string]>> =>
  authAxios({
    method: 'POST',
    url: `${DEVICE_MANAGER_ROOT_URL}topics/${deviceId}/unsubscribed`,
    data: {
      topics: [name],
      kind,
    },
  }).then((res) => ({
    ...res,
    data: {
      data: res.data.response.data,
      status: res.data.status,
      error: res.data.response.error,
    },
  }));

export const addDeviceLabelApi = (
  deviceId: string,
  key: string,
  value: string,
): AxiosPromise<ApiResDevices<Label[]>> =>
  authAxios({
    method: 'POST',
    url: `${DEVICE_LABELS_URL}${deviceId}`,
    data: {
      [key]: value,
    },
  }).then((res) => ({
    ...res,
    data: {
      data: res.data.response.data,
      status: res.data.status,
      error: res.data.response.error,
    },
  }));

export const sshStartApi = (deviceId: string): AxiosPromise<ApiResDevices<void>> =>
  authAxios({
    method: 'GET',
    url: `${DEVICE_MANAGER_ROOT_URL}terminal/${deviceId}/start`,
  });

export const getTokenApi = (deviceId: string): AxiosPromise<ApiResDevices<AddDeviceResponse>> =>
  authAxios({
    method: 'GET',
    url: `${DEVICE_MANAGER_KEYS_URL}${deviceId}/token`,
  }).then((res) => ({
    ...res,
    data: {
      data: mapAPIAddDeviceResponseToAddDeviceResponse(res.data.response),
      status: res.data.status,
      error: res.data.response.error,
    },
  }));

export const deleteDeviceApi = (deviceId: string): AxiosPromise<ApiResDevices<void>> =>
  authAxios({
    method: 'DELETE',
    url: `${DEVICE_MANAGER_API_BASE_URL}${deviceId}`,
  });

export const updateDeviceLabelApi = (
  labelId: number,
  key: string,
  value: string,
): AxiosPromise<ApiResDevices<void>> =>
  authAxios({
    method: 'PUT',
    url: `${DEVICE_LABELS_URL}${labelId}`,
    data: { key, value },
  });

export const deleteDeviceLabelApi = (labelId: number): AxiosPromise<ApiResDevices<void>> =>
  authAxios({
    method: 'DELETE',
    url: `${DEVICE_LABELS_URL}${labelId}`,
  });

export const getUnameApi = (
  deviceId: string,
): AxiosPromise<ApiResDevices<Record<string, string>>> =>
  authAxios({
    method: 'POST',
    url: COMMAND_API_URL,
    data: {
      shell: '/bin/bash',
      bg: false,
      env: {},
      runas: 'root',
      cmd: 'uname -a',
      device_ids: [deviceId],
    },
  }).then((res) => ({
    ...res,
    data: {
      data: res.data.response.data,
      status: res.data.status,
      error: res.data.response.error,
    },
  }));

export const executeCommandApi = (data: ReqCmd): AxiosPromise<ApiResDevices<TerminalData>> =>
  authAxios({
    method: 'POST',
    url: COMMAND_API_URL,
    data,
  }).then((res) => ({
    ...res,
    data: {
      data: res.data.response.data,
      status: res.data.status,
      error: res.data.response.error,
    },
  }));

export const updateNameApi = (
  deviceId: string,
  deviceName: string,
): AxiosPromise<ApiResDevices<void>> =>
  authAxios({
    method: 'PUT',
    url: DEVICE_MANAGER_API_BASE_URL,
    data: {
      device_id: deviceId,
      name: deviceName,
    },
  });

export const updateDeviceStatusApi = (
  deviceId: string,
  status: string,
): AxiosPromise<ApiResDevices<void>> =>
  authAxios({
    method: 'PUT',
    url: DEVICE_MANAGER_API_BASE_URL,
    data: {
      device_id: deviceId,
      status,
    },
  });

export const updateDeviceDescriptionApi = (
  deviceId: string,
  description: string,
): AxiosPromise<ApiResDevices<void>> =>
  authAxios({
    method: 'PUT',
    url: DEVICE_MANAGER_API_BASE_URL,
    data: {
      device_id: deviceId,
      description,
    },
  });

export const upgradeDevice = (deviceId: string): AxiosPromise<ApiResDevices<void>> =>
  authAxios({
    method: 'PUT',
    url: `${DEVICE_MANAGER_API_BASE_URL}${deviceId}/upgrade`,
  });

export const uploadDebugLogsApi = (deviceId: string): AxiosPromise<void> =>
  authAxios({
    method: 'POST',
    url: `${DEVICE_MANAGER_ROOT_URL}error_handler/upload_debug_logs/${deviceId}`,
  });

export const migrateDeviceApi = ({
  deviceId,
  projectId,
}: MigrateDevicePayload): AxiosPromise<ApiResDevices<void>> =>
  authAxios({
    method: 'PUT',
    url: `${DEVICE_MANAGER_API_BASE_URL}${deviceId}/migrate`,
    data: {
      project: projectId,
    },
  });
