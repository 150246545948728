enum MarketplaceActionTypes {
  SET_API_STATUS = 'MPLACE/SET_API_STATUS',
  GET_PACKAGE_DETAILS = 'MPLACE/GET_PACKAGE_DETAILS',
  UPDATE_PACKAGE_DETAILS = 'MPLACE/UPDATE_PACKAGE_DETAILS',
  INSTALL_PACKAGE = 'MPLACE/INSTALL_PACKAGE',
  CLEAR_PACKAGE_DETAILS = 'MPLACE/CLEAR_PACKAGE_DETAILS',
  SET_SHOW_PROJECT_SELECT_MODAL = 'MPLACE/SET_SHOW_PROJECT_SELECT_MODAL',
}

export default MarketplaceActionTypes;
