import { APICallStatus } from 'Models/common/types';
import {
  DependencyGraphData,
  Deployment,
  DeploymentAction,
  DeploymentActionPayload,
  DeploymentHistory,
  DeploymentState,
} from 'Models/deployments/types';
import { DeploymentSpecification } from 'Root/models/deployPackage/types';
import { PackageSpecification } from 'Root/models/packages/types';

import DeploymentActionTypes from './actionTypes';

export const initialState: DeploymentState = {
  deploymentList: [],
  deploymentHistory: [],
  deploymentHistoryApiStatus: APICallStatus.INITIAL,
  deploymentListApiStatus: APICallStatus.INITIAL,
  downloadDeploymentManifestApiStatus: APICallStatus.INITIAL,

  deploymentDetails: null,
  deploymentDetailsApiStatus: APICallStatus.INITIAL,

  packageDetails: null,
  packageDetailsDetailsApiStatus: APICallStatus.INITIAL,

  dependencyGraphData: null,

  collectRosbagsStatus: APICallStatus.INITIAL,
  updateRosbagJobStatus: APICallStatus.INITIAL,
};

export default (
  state: DeploymentState = initialState,
  action: DeploymentAction<DeploymentActionPayload>,
): DeploymentState => {
  switch (action.type) {
    case DeploymentActionTypes.SET_COLLECT_ROSBAGS_STATUS: {
      return {
        ...state,
        collectRosbagsStatus: action.payload as APICallStatus,
      };
    }

    case DeploymentActionTypes.SET_UPDATE_ROSBAG_JOB_STATUS: {
      return {
        ...state,
        updateRosbagJobStatus: action.payload as APICallStatus,
      };
    }

    case DeploymentActionTypes.SET_DEPLOYMENT_LIST: {
      return {
        ...state,
        deploymentList: action.payload as Deployment[],
      };
    }
    case DeploymentActionTypes.SET_DEPLOYMENT_LIST_API_STATUS: {
      return {
        ...state,
        deploymentListApiStatus: action.payload as APICallStatus,
      };
    }

    case DeploymentActionTypes.SET_DEPLOYMENT_DETAILS: {
      return {
        ...state,
        deploymentDetails: action.payload as DeploymentSpecification,
      };
    }
    case DeploymentActionTypes.SET_DEPLOYMENT_DETAILS_API_STATUS: {
      return {
        ...state,
        deploymentDetailsApiStatus: action.payload as APICallStatus,
      };
    }
    case DeploymentActionTypes.SET_DOWNLOAD_DEPLOYMENT_MANIFEST_API_STATUS: {
      return {
        ...state,
        downloadDeploymentManifestApiStatus: action.payload as APICallStatus,
      };
    }
    case DeploymentActionTypes.SET_DEPLOYMENT_HISTORY: {
      return {
        ...state,
        deploymentHistory: action.payload as DeploymentHistory[],
      };
    }
    case DeploymentActionTypes.SET_DEPLOYMENT_HISTORY_API_STATUS: {
      return {
        ...state,
        deploymentHistoryApiStatus: action.payload as APICallStatus,
      };
    }
    case DeploymentActionTypes.SET_PACKAGE_DETAILS: {
      return {
        ...state,
        packageDetails: action.payload as PackageSpecification,
      };
    }
    case DeploymentActionTypes.SET_PACKAGE_DETAILS_API_STATUS: {
      return {
        ...state,
        packageDetailsDetailsApiStatus: action.payload as APICallStatus,
      };
    }
    case DeploymentActionTypes.SET_DEPENDENCY_GRAPH_DATA: {
      return {
        ...state,
        dependencyGraphData: action.payload as DependencyGraphData,
      };
    }

    default:
      return state;
  }
};
