import { AxiosPromise } from 'axios';

import {
  COUNTRY_ROOT_URL,
  ORG_REGISTER_URL,
  ORG_ROOT_URL,
  TOGGLE_USER_STATE_URL,
} from 'Shared/config';
import { authAxios } from 'Shared/utils/user';

import {
  mapAPICountryToCountry,
  mapAPIOrgToOrg,
  mapProvincesToProvinceOption,
} from './dataFormatters';
import {
  Country,
  Organization,
  PaymentInfoParams,
  ProvinceOption,
  ToggleUserStatePayload,
} from './types';

export const getOrganizationDetails = (orgGUID: string): Promise<Organization> =>
  authAxios({
    method: 'GET',
    url: `${ORG_ROOT_URL}${orgGUID}/get`,
  }).then((response) => mapAPIOrgToOrg(response.data));

export const getCountryList = (): Promise<Country[]> =>
  authAxios({
    method: 'GET',
    url: COUNTRY_ROOT_URL,
  }).then((response) => response.data.map(mapAPICountryToCountry));

export const getProvinceList = async (countryID: number): Promise<ProvinceOption[]> =>
  authAxios({
    method: 'GET',
    url: `${COUNTRY_ROOT_URL}/${countryID}/provinces`,
  }).then((response) => mapProvincesToProvinceOption(response.data));

export const register = (organization: Partial<Organization>): Promise<Organization> =>
  authAxios({
    method: 'POST',
    url: ORG_REGISTER_URL,
    data: organization,
  }).then((response) => mapAPIOrgToOrg(response.data));

export const submitPaymentInfo = ({
  orgGUID,
  paymentReferenceID,
  planID,
}: PaymentInfoParams): AxiosPromise<void> =>
  authAxios({
    method: 'PUT',
    url: `${ORG_ROOT_URL}${orgGUID}/update`,
    data: {
      planID,
      paymentReferenceID,
    },
  });

export const toggleUserState = (data: ToggleUserStatePayload): AxiosPromise<void> =>
  authAxios({
    method: 'PUT',
    url: TOGGLE_USER_STATE_URL,
    data,
  });
