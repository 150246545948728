import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';

import { APICallStatus } from 'Models/common/types';
import { NotificationPayload } from 'Models/notifications/types';
import { DEFAULT_PHASE_VALUES } from 'Root/deployments/DeploymentsList/DeploymentList.contant';
import { getManifest } from 'Root/models/cliManifest/dataFormatters';
import { ManifestKinds } from 'Root/models/cliManifest/types';
import {
  deleteDeployment as deleteDeploymentAPI,
  getDependencyGraph as getDependencyGraphAPI,
  getDeploymentDetails as getDeploymentDetailsAPI,
  getDeploymentHistory as getDeploymentHistoryAPI,
  getDeploymentList as getDeploymentListAPI,
  restartDeployment as restartDeploymentAPI,
} from 'Root/models/deployments/service';
import {
  DeploymentAction,
  DeploymentPhase,
  GetDeploymentDetailsPayload,
} from 'Root/models/deployments/types';
import { getPackageDetails as getPackageDetailsAPI } from 'Root/models/packages/service';
import { GetDeletePackagePayload } from 'Root/models/packages/types';
import { getMessageFromError } from 'Shared/utils/common';
import { CallReturnType } from 'Types/saga';

import { downloadYAML } from '../cliManifest/actions';
import { setNotificationFail, setNotificationSuccess } from '../notifications/action';

import {
  getDeploymentList as getDeploymentListAction,
  setDependencyGraphData,
  setDependencyGraphDataAPICallStatus,
  setDeploymentDetails,
  setDeploymentDetailsAPICallStatus,
  setDeploymentHistory,
  setDeploymentHistoryAPICallStatus,
  setDeploymentList,
  setDeploymentListAPICallStatus,
  setDownloadDeploymentManifestAPICallStatus,
  setPackageDetails,
  setPackageDetailsAPICallStatus,
} from './actions';
import DeploymentActionTypes from './actionTypes';

function* getDeploymentList(action: DeploymentAction<DeploymentPhase[]>) {
  try {
    yield put(setDeploymentListAPICallStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getDeploymentListAPI> = yield call(
      getDeploymentListAPI,
      action.payload,
    );
    yield put(setDeploymentList(data));

    yield put(setDeploymentListAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setDeploymentListAPICallStatus(APICallStatus.ERROR));

    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Error Getting Deployment List',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* deleteDeployment(action: DeploymentAction<string>) {
  try {
    yield put(setDeploymentListAPICallStatus(APICallStatus.LOADING));
    yield call(deleteDeploymentAPI, action.payload);

    const successPayload: NotificationPayload = {
      message: 'Successfully Stopped Deployment.',
    };
    yield put(setNotificationSuccess(successPayload));
    yield put(setDeploymentListAPICallStatus(APICallStatus.LOADED));
    yield put(getDeploymentListAction(DEFAULT_PHASE_VALUES));
  } catch (error) {
    // Set the status to loaded because it is for the list page
    yield put(setDeploymentListAPICallStatus(APICallStatus.LOADED));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Error Stopping Deployment',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* getDeploymentDetails(action: DeploymentAction<GetDeploymentDetailsPayload>) {
  try {
    yield put(setDeploymentDetailsAPICallStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getDeploymentDetailsAPI> = yield call(
      getDeploymentDetailsAPI,
      action.payload,
    );
    yield put(setDeploymentDetails(data));

    yield put(setDeploymentDetailsAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setDeploymentDetailsAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Failed to get the Deployment details',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
    yield put(push(`/deployments/`));
  }
}

function* getPackageDetails(action: DeploymentAction<GetDeletePackagePayload>) {
  try {
    yield put(setPackageDetailsAPICallStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getPackageDetailsAPI> = yield call(
      getPackageDetailsAPI,
      action.payload,
    );
    yield put(setPackageDetails(data));

    yield put(setPackageDetailsAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setPackageDetailsAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Failed to get the Package details',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* restartDeployment(action: DeploymentAction<string>) {
  try {
    yield call(restartDeploymentAPI, action.payload);
    const successPayload: NotificationPayload = {
      message: 'Successfully Restarted Deployment.',
    };
    yield put(setNotificationSuccess(successPayload));
  } catch (error) {
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Deployment Restart Failed',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* downloadDeploymentManifest(action: DeploymentAction<GetDeploymentDetailsPayload>) {
  try {
    yield put(setDownloadDeploymentManifestAPICallStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getDeploymentDetailsAPI> = yield call(
      getDeploymentDetailsAPI,
      action.payload,
    );
    const manifest = getManifest(ManifestKinds.DEPLOYMENT, data.metadata, data.spec);

    yield put(downloadYAML(manifest));

    yield put(setDownloadDeploymentManifestAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    const errorPayload: NotificationPayload = {
      message: 'Download Failed',
    };
    yield put(setNotificationFail(errorPayload));
    yield put(setDownloadDeploymentManifestAPICallStatus(APICallStatus.ERROR));
  }
}

function* getDeploymentHistory(action: DeploymentAction<GetDeploymentDetailsPayload>) {
  try {
    yield put(setDeploymentHistoryAPICallStatus(APICallStatus.LOADING));

    const data: CallReturnType<typeof getDeploymentHistoryAPI> = yield call(
      getDeploymentHistoryAPI,
      action.payload,
    );
    yield put(setDeploymentHistory(data));
    yield put(setDeploymentHistoryAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setDeploymentHistoryAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Failed to get the Deployment History',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* getDependencyGraphData(action: DeploymentAction<string>) {
  try {
    yield put(setDependencyGraphDataAPICallStatus(APICallStatus.LOADING));

    const data: CallReturnType<typeof getDependencyGraphAPI> = yield call(
      getDependencyGraphAPI,
      action.payload,
    );
    yield put(setDependencyGraphData(data));
    yield put(setDependencyGraphDataAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setDependencyGraphDataAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Failed to get the Dependency Graph data',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

export default function* deploymentSaga(): IterableIterator<ForkEffect<never>> {
  yield takeLatest(DeploymentActionTypes.GET_DEPLOYMENT_LIST, getDeploymentList);
  yield takeLatest(DeploymentActionTypes.DELETE_DEPLOYMENT, deleteDeployment);
  yield takeLatest(DeploymentActionTypes.GET_DEPLOYMENT_DETAILS, getDeploymentDetails);
  yield takeLatest(DeploymentActionTypes.RESTART_DEPLOYMENT, restartDeployment);
  yield takeLatest(DeploymentActionTypes.DOWNLOAD_DEPLOYMENT_MANIFEST, downloadDeploymentManifest);
  yield takeLatest(DeploymentActionTypes.GET_DEPLOYMENT_HISTORY, getDeploymentHistory);
  yield takeLatest(DeploymentActionTypes.GET_PACKAGE_DETAILS, getPackageDetails);
  yield takeLatest(DeploymentActionTypes.GET_DEPENDENCY_GRAPH_DATA, getDependencyGraphData);
}
