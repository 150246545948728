export const groupBy = (xs, key) =>
  xs.reduce((rv, x) => {
    const k = key instanceof Function ? key(x) : x[key];
    // eslint-disable-next-line no-param-reassign
    (rv[k] = rv[k] || []).push(x);
    return rv;
  }, {});

export const count = (arr) =>
  arr.reduce((acc, value) => {
    if (!acc[value]) {
      acc[value] = 1;
    } else {
      acc[value] += 1;
    }

    return acc;
  }, {});

export const countBy = (arr, key) =>
  arr.reduce((acc, value) => {
    if (!acc[value[key]]) {
      acc[value[key]] = 1;
    } else {
      acc[value[key]] += 1;
    }

    return acc;
  }, {});

export const uniq = (arr) => [...new Set(arr)];

export const union = (arrA, arrB) => uniq([...arrA, ...arrB]);

export const uniqBy = (arr, key) => {
  const seen = {};

  // eslint-disable-next-line no-return-assign
  return arr.filter((el) =>
    // eslint-disable-next-line no-prototype-builtins
    seen.hasOwnProperty(el[key]) ? false : (seen[el[key]] = true),
  );
};

export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

export const defaultSortCompare = (a, b) => {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
};

export const sortBy = (arr, iteratees) => {
  const isArray = Array.isArray(iteratees);
  if (isArray) {
    return arr.sort((a, b) => {
      for (let i = 0; i < iteratees.length; i += 1) {
        const compareResult = defaultSortCompare(a[iteratees[i]], b[iteratees[i]]);
        if (compareResult) return compareResult;
      }
      return 0;
    });
  }
  return arr.sort((a, b) => defaultSortCompare(a[iteratees], b[iteratees]));
};

export const sortByCb = (arr, cbIteratees) => {
  const isArray = Array.isArray(cbIteratees);
  if (isArray) {
    return arr.sort((a, b) => {
      for (let i = 0; i < cbIteratees.length; i += 1) {
        const compareResult = defaultSortCompare(cbIteratees[i](a), cbIteratees[i](b));
        if (compareResult) return compareResult;
      }
      return 0;
    });
  }
  return arr.sort((a, b) => defaultSortCompare(cbIteratees(a), cbIteratees(b)));
};

export const size = (arr) => arr?.length ?? 0;

export const xor = (arr1, arr2) => {
  const unique1 = arr1.filter((o) => !arr2.includes(o));
  const unique2 = arr2.filter((o) => !arr1.includes(o));
  const unique = unique1.concat(unique2);
  return unique;
};

export const keyBy = (arr, key) => (arr || []).reduce((rv, x) => ({ ...rv, [x[key]]: x }), {});

export const updateObjInArr = (arr, index, key, value) => [
  ...arr.slice(0, index),
  { ...arr[index], [key]: value },
  ...arr.slice(index + 1),
];
