import { APICallStatus, RIOError } from 'Models/common/types';
import {
  CreateProjectPayload,
  DeleteProjectPayload,
  EditProjectPayload,
  Project,
  ProjectLocalNotifPayload,
  ProjectRejectedPayload,
  ProjectsAction,
  UpdateProjectOwnerPayload,
} from 'Models/projects/types';

import ActionTypes from './actionTypes';

export const setProjectsLoader = (): ProjectsAction => ({
  type: ActionTypes.PROJECTS_GET_PENDING,
});

export const updateProjectsList = (payload: Project[]): ProjectsAction<Project[]> => ({
  type: ActionTypes.PROJECTS_GET_FULFILLED,
  payload,
});

export const setProjectGetError = (): ProjectsAction => ({
  type: ActionTypes.PROJECTS_GET_REJECTED,
});

export const setProjectsAPIStatus = (payload: APICallStatus): ProjectsAction<APICallStatus> => ({
  type: ActionTypes.SET_PROJECTS_API_STATUS,
  payload,
});

export const setCreateProjectFulfilled = (): ProjectsAction<void> => ({
  type: ActionTypes.CREATE_PROJECT_FULFILLED,
});

export const setCreateProjectError = (payload: RIOError): ProjectsAction<RIOError> => ({
  type: ActionTypes.CREATE_PROJECT_REJECTED,
  payload,
});

export const setEditProjectFulfilled = (payload: string): ProjectsAction<string> => ({
  type: ActionTypes.EDIT_PROJECT_FULFILLED,
  payload,
});

export const setEditProjectError = (payload: RIOError): ProjectsAction<RIOError> => ({
  type: ActionTypes.EDIT_PROJECT_REJECTED,
  payload,
});

export const deleteProjectSuccess = (payload: string): ProjectsAction<string> => ({
  type: ActionTypes.DELETE_PROJECT_FULFILLED,
  payload,
});

export const setProjectDetails = (payload: Project): ProjectsAction<Project> => ({
  type: ActionTypes.PROJECT_DETAILS_GET_FULFILLED,
  payload,
});

export const setProjectDetailsError = (
  projectID: string,
  error: RIOError,
): ProjectsAction<ProjectRejectedPayload> => ({
  type: ActionTypes.PROJECT_DETAILS_GET_REJECTED,
  payload: {
    projectID,
    error,
  },
});

export const listProjects = (loggedinGuid: string): ProjectsAction<string> => ({
  type: ActionTypes.GET_PROJECTS,
  payload: loggedinGuid,
});

export const createProject = (
  payload: CreateProjectPayload,
): ProjectsAction<CreateProjectPayload> => ({
  type: ActionTypes.CREATE_PROJECT,
  payload,
});

export const editProject = (payload: EditProjectPayload): ProjectsAction<EditProjectPayload> => ({
  type: ActionTypes.EDIT_PROJECT,
  payload,
});

export const deleteProject = (name: string, id: string): ProjectsAction<DeleteProjectPayload> => ({
  type: ActionTypes.DELETE_PROJECT,
  payload: { name, id },
});

export const getProjectDetails = (projectID: string): ProjectsAction<string> => ({
  type: ActionTypes.GET_PROJECT_DETAILS,
  payload: projectID,
});

export const clearProjectDetails = (): ProjectsAction<void> => ({
  type: ActionTypes.CLEAR_DETAILS,
});

export const setLocalNotification = (
  payload: ProjectLocalNotifPayload,
): ProjectsAction<ProjectLocalNotifPayload> => ({
  type: ActionTypes.SET_LOCAL_NOTIFICATION,
  payload,
});

export const clearLocalNotification = (id: string): ProjectsAction<string> => ({
  type: ActionTypes.CLEAR_LOCAL_NOTIFICATION,
  payload: id,
});

export const resetAPIStatus = (): ProjectsAction => ({
  type: ActionTypes.RESET_API_STATUS,
});

export const updateProjectOwner = (
  payload: UpdateProjectOwnerPayload,
): ProjectsAction<UpdateProjectOwnerPayload> => ({
  type: ActionTypes.UPDATE_PROJECT_OWNER,
  payload,
});
