import storeUtilAdapterInst from './storeUtilsAdapter';

const { _paq: piwikInstance } = window;

let username;
if (storeUtilAdapterInst.getUserName()) {
  username = storeUtilAdapterInst.getUserName();
}
if (piwikInstance && username) {
  piwikInstance.push(['setUserId', username]);
}

export const trackEvent = (category, action, label, value) => {
  if (!piwikInstance) {
    return;
  }
  piwikInstance.push(['trackEvent', category, action, label, value]);
};

export const trackTiming = (category, variable, value, label) => {
  if (!piwikInstance) {
    return;
  }
  piwikInstance.push(['trackEvent', `API${category}`, variable, label, value]);
};

export const trackPage = (page) => {
  if (!piwikInstance) {
    return;
  }
  if (username !== storeUtilAdapterInst.getUserName()) {
    username = storeUtilAdapterInst.getUserName();
    piwikInstance.push(['setUserId', username]);
  }
  piwikInstance.push(['setCustomUrl', page]);
  piwikInstance.push(['trackPageView']);
};
