import React, { ReactNode, useEffect } from 'react';
import { connect, useStore } from 'react-redux';
import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';

import { AppAction, AppState } from 'Models/common/types';
import { DefaultMessages, NotificationType } from 'Models/notifications/types';
import { hideNotification } from 'RioRedux/notifications/action';

import NotificationCTAButton from './NotificationCTAButton';

import './styles.scss';

const rootClassName = 'rio-toast-notification';

interface Props {
  showNotification: boolean;
  type: NotificationType;
  message?: string;
  description?: string;
  notificationKey?: string;
  buttonText?: string;
  buttonAction?: () => AppAction;
  clearNotification: typeof hideNotification;
}

const ToastNotification: React.FC<Props> = ({
  showNotification,
  type,
  message,
  description,
  notificationKey,
  buttonText,
  buttonAction,
  clearNotification,
}) => {
  const store = useStore();
  let defaultMessage: ReactNode = '';
  let defaultDescription: ReactNode = '';
  let defaultDuration: number | null = 4.5;

  if (type === NotificationType.ERROR) {
    defaultMessage = message?.length ? message : DefaultMessages.ERROR_HEADING;
    defaultDescription = description?.length ? description : DefaultMessages.ERROR_DESCRIPTION;

    if (buttonText) {
      const withButton = (
        <div className={`${rootClassName}__description-with-btn`}>
          <span className={`${rootClassName}__description`}>
            {description ?? DefaultMessages.ERROR_DESCRIPTION}
          </span>
          <NotificationCTAButton
            buttonText={buttonText}
            buttonAction={buttonAction}
            store={store}
            closeNotification={() => notification.close(notificationKey as string)}
          />
        </div>
      );

      defaultDescription = withButton;
      defaultDuration = null;
    }
  } else {
    defaultMessage = message;
    defaultDescription = description;
  }

  useEffect(() => {
    const notificationConfig: ArgsProps = {
      className: `${rootClassName} ${rootClassName}--${type}`,
      message: defaultMessage,
      description: defaultDescription,
      key: notificationKey,
      // eslint-disable-next-line import/no-dynamic-require
      icon: <img src={require(`Assets/img/${type}_icon.svg`)} alt={type} />,
      duration: defaultDuration,
      closeIcon: <img alt="media-upload-icon" src={require('Assets/img/close.svg')} />,
    };

    if (showNotification) {
      notification.open(notificationConfig);
      /* If showNotification is true, the subsequent trigger to open notification would not be called
       ** hence clearing the notification
       */
      clearNotification();
    }
  }, [showNotification]);

  return <></>;
};

const mapStateToProps = ({ notification: notificationInfo }: AppState) => ({
  showNotification: notificationInfo.showNotification,
  type: notificationInfo.type,
  message: notificationInfo.message,
  description: notificationInfo.description,
  notificationKey: notificationInfo.key,
  buttonText: notificationInfo.buttonText,
  buttonAction: notificationInfo?.buttonAction,
});

const mapDispatchToProps = {
  clearNotification: hideNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastNotification);
