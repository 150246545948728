import ActionTypes from 'RioRedux/metrics/actionTypes';

export interface MetricsState {
  device: Record<DeviceMetrics, MetricData | null>;
  cloud: Record<CloudMetrics, MetricData | null>;
}

export interface BaseMetricsAction {
  type: ActionTypes;
}

export type MetricsAction<P = undefined> = P extends undefined
  ? BaseMetricsAction
  : BaseMetricsAction & { payload: P };

export type MetricsActionPayload = DeviceMetricsData | CloudMetricsData;

export interface RefreshOption {
  label: string;
  value: number;
}

export interface LimitOption {
  label: string;
  value: string;
  lowerBound: string | number | null;
  upperBound: string | number | null;
  defaultGroupBy: GroupByRanges;
}

export interface MetricQuery {
  title: string;
  select: string[];
  from: string;
  groupBy?: string[];
  where?: string[];
  unit: MetricUnit;
}

export enum DeviceMetrics {
  CPU = 'cpu',
  MEMORY = 'memory',
  DISK_USAGE = 'diskUsage',
  NETWORK = 'network',
  DISK_IO = 'diskIO',
}

export enum CloudMetrics {
  CPU = 'cpu',
  CPU_THROTTLE = 'cpuThrottle',
  NETWORK = 'network',
  PACKETS = 'packets',
  MEMORY = 'memory',
}

export enum MetricUnit {
  GB = 'GB',
  PERCENT = 'Percent',
  BYTES = 'Bytes/s',
  MB = 'MB',
  NONE = '',
}

export interface GetMetricsParams {
  type: DeviceMetrics | CloudMetrics;
  queryOption: MetricQuery;
  limitOption: LimitOption;
}

export interface GetMetricParams {
  resourceID: string;
  queryOption: MetricQuery;
  limitOption: LimitOption;
}

export enum MetricType {
  DEVICE = 'device',
  CLOUD = 'cloud',
}

export type Metrics = DeviceMetrics | CloudMetrics;

export type GetMetricsMethod = (params: GetMetricsParams) => void;

export enum QueryJoiners {
  SELECT = ', ',
  WHERE = ' AND ',
  GROUP_BY = ', ',
}

export enum GroupByRanges {
  THIRTY_SECONDS = '30s',
  ONE_MINUTE = '1m',
  FIVE_MINUTES = '5m',
  TEN_MINUTES = '10m',
  THIRTY_MINUTES = '30m',
  ONE_HOUR = '1h',
}

export interface MetricData {
  columns: string[];
  data: number[][];
  index: number[];
}

export interface APIMetricResponse {
  response: {
    data: MetricData;
  };
}

export interface DeviceMetricsData {
  key: DeviceMetrics;
  data: MetricData;
}

export interface CloudMetricsData {
  key: CloudMetrics;
  data: MetricData;
}

export interface GetMetricsPayload {
  key: Metrics;
  params: GetMetricParams;
}

export type DateValue = Date | null;
export type DateRange = [DateValue, DateValue];
