import { AxiosError } from 'axios';
import { call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects';

import { mapResourceIDsToNames } from 'Models/metrics/dataFormatters';
import { getMetrics } from 'Models/metrics/service';
import {
  CloudMetrics,
  DeviceMetrics,
  GetMetricsPayload,
  MetricsAction,
  MetricType,
} from 'Models/metrics/types';
import { DefaultMessages, NotificationPayload } from 'Models/notifications/types';
import { getErrorMessage } from 'Shared/utils/core';
import { CallReturnType } from 'Types/saga';

import { selectDeploymentDetails } from '../deployments/selectors';
import { setNotificationFail } from '../notifications/action';

import { updateCloudMetricsData, updateDeviceMetricsData } from './actions';
import ActionTypes from './actionTypes';

function* handleMetricError(error: AxiosError) {
  const err = error as AxiosError;
  const description = getErrorMessage(err) ?? DefaultMessages.ERROR_DESCRIPTION;

  const errorPayload: NotificationPayload = {
    message: err?.message ?? DefaultMessages.ERROR_HEADING,
    description,
  };
  yield put(setNotificationFail(errorPayload));
}

function* getDeviceMetrics(action: MetricsAction<GetMetricsPayload>) {
  try {
    const data: CallReturnType<typeof getMetrics> = yield call(
      getMetrics,
      MetricType.DEVICE,
      action.payload.params,
    );

    yield put(updateDeviceMetricsData({ key: action.payload.key as DeviceMetrics, data }));
  } catch (error) {
    handleMetricError(error as AxiosError);
  }
}

function* getCloudMetrics(action: MetricsAction<GetMetricsPayload>) {
  try {
    const metricData: CallReturnType<typeof getMetrics> = yield call(
      getMetrics,
      MetricType.CLOUD,
      action.payload.params,
    );

    const deploymentDetails: ReturnType<typeof selectDeploymentDetails> = yield select(
      selectDeploymentDetails,
    );
    const data = mapResourceIDsToNames(metricData, deploymentDetails);

    yield put(updateCloudMetricsData({ key: action.payload.key as CloudMetrics, data }));
  } catch (error) {
    handleMetricError(error as AxiosError);
  }
}

export default function* marketplaceSaga(): IterableIterator<ForkEffect<never>> {
  yield takeEvery(ActionTypes.GET_DEVICE_METRICS, getDeviceMetrics);
  yield takeEvery(ActionTypes.GET_CLOUD_METRICS, getCloudMetrics);
}
