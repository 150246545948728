import { NetworkPhases, NetworkTypes } from 'Models/networks/types';
import { RunTime } from 'Root/models/common/types';
import { size } from 'Root/shared/utils/core/array';
import { BROKER_RESOURCE_LIMITS, NATIVE_NETWORK_LIMITS } from 'Shared/config';

export const DEFAULT_PHASE_QUERY_PARAMS = [
  NetworkPhases.IN_PROGRESS,
  NetworkPhases.PROVISIONING,
  NetworkPhases.SUCCEEDED,
  NetworkPhases.START_FAILED,
  NetworkPhases.UPDATE_FAILED,
];

export const DEFAULT_RUNTIME_QUERY_PARAMS = [RunTime.CLOUD, RunTime.DEVICE];

export const DEFAULT_TYPE_QUERY_PARAMS = [NetworkTypes.NATIVE, NetworkTypes.ROUTED];

export const NetworkPhaseLabels: Record<NetworkPhases, string> = {
  [NetworkPhases.IN_PROGRESS]: 'In progress',
  [NetworkPhases.SUCCEEDED]: 'Succeeded',
  [NetworkPhases.PROVISIONING]: 'Provisioning',
  [NetworkPhases.START_FAILED]: 'Failed to start',
  [NetworkPhases.UPDATE_FAILED]: 'Failed to update',
};

export const SERVER_ID_RANGE = {
  MIN: 0,
  MAX: 255,
};

export const SERVER_PORT_RANGE = {
  MIN: 11000,
  MAX: 20000,
};

export const DEFAULT_LIMIT = (networkType: NetworkTypes): Record<string, number> =>
  networkType === NetworkTypes.NATIVE
    ? { ...NATIVE_NETWORK_LIMITS['x-small'] }
    : { ...BROKER_RESOURCE_LIMITS.small };

export const PHASE_INDICATOR_CASES = {
  PROGRESS: [NetworkPhases.IN_PROGRESS, NetworkPhases.PROVISIONING],
  FAILED: [NetworkPhases.START_FAILED, NetworkPhases.UPDATE_FAILED],
  RUNNING: [NetworkPhases.SUCCEEDED],
};

export const commonLengthVaildation = (name: string): boolean =>
  size(name) >= 3 && size(name) <= 75;
