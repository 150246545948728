import { RunTime } from '../common/types';
import { PackageSpecification } from '../packages/types';

import { DeploymentSpecification, DeployPackagePayloadData, FormData } from './types';

export const transformDeploymentNameList = (data: DeploymentSpecification[]): string[] => {
  const deploymentNameList = data.map((deployment) => deployment.metadata.name);
  return deploymentNameList;
};

export const transformFormData = (
  formData: FormData,
  packageDetails: PackageSpecification,
  replicaCount: number,
  ros2Values: string[],
): DeployPackagePayloadData => {
  const { spec, envArgs, rosBagJobs, rosNetworks, staticRoutes, volumes } = formData;
  const data: DeployPackagePayloadData = {
    metadata: {
      depends: {
        nameOrGUID: packageDetails?.metadata.name as string,
        version: packageDetails?.metadata.version as string,
      },
      ...formData.metadata,
      labels: formData?.metadata.labels?.reduce(
        (result, { key, value }) => ({ ...result, [key]: value ?? '' }),
        {},
      ),
    },
    spec: {
      runtime: packageDetails.spec.runtime,
      features: {
        params: {
          trees: spec.features.trees,
          enabled: spec.features.params,
        },
        ...(packageDetails.spec.runtime === RunTime.CLOUD && {
          vpn: {
            enabled: spec.features.vpn,
          },
        }),
      },
      ...(packageDetails.spec.runtime === RunTime.DEVICE && {
        device: {
          depends: {
            nameOrGUID: spec.device?.depends as string,
          },
        },
      }),
      depends: spec.depends.map((name) => ({ nameOrGUID: name as string })),
      envArgs,
      ...(packageDetails.spec.runtime === RunTime.DEVICE && {
        restart: spec.device?.restartPolicy,
      }),
      ...(packageDetails?.spec.ros.enabled && { rosBagJobs: rosBagJobs || [] }),
      ...(packageDetails.spec.runtime === RunTime.CLOUD && {
        staticRoutes: staticRoutes
          ? staticRoutes.map((route) => ({
              depends: {
                // We only need to send the name of the route. The appended guid is not needed
                nameOrGUID: route.depends.substring(0, route.depends.lastIndexOf('-')) as string,
              },
              name: route.name,
            }))
          : [],
      }),
      ...(packageDetails.spec.runtime === RunTime.CLOUD &&
        replicaCount === 1 && {
          volumes: volumes
            ? volumes.map((volume) => ({
                depends: {
                  nameOrGUID: volume.depends as string,
                },
                execName: volume.execName,
                mountPath: volume.mountPath,
                subPath: volume.subPath,
              }))
            : [],
        }),
      ...(packageDetails.spec.runtime === RunTime.DEVICE && {
        volumes: volumes
          ? volumes.map((volume) => {
              const { depends, ...otherData } = volume;
              return {
                ...otherData,
              };
            })
          : [],
      }),
      ...(packageDetails?.spec.ros.enabled && {
        rosNetworks: rosNetworks
          ? rosNetworks.map((network) => ({
              depends: {
                nameOrGUID: network.depends as string,
              },
              topics: network.topics || [],
              ...(packageDetails.spec.runtime === RunTime.DEVICE && {
                interface: network.interface,
              }),
              ...(ros2Values.includes(network.depends) && {
                domainID: network.domainID || 0,
              }),
            }))
          : [],
      }),
    },
  };
  return data;
};
