enum ActionTypes {
  PROJECTS_GET_PENDING = 'PROJECTS_GET_PENDING',
  PROJECTS_GET_FULFILLED = 'PROJECTS_GET_FULFILLED',
  SET_PROJECTS_API_STATUS = 'SET_PROJECTS_API_STATUS',
  GET_PROJECTS = 'GET_PROJECTS',
  PROJECTS_GET_REJECTED = 'PROJECTS_GET_REJECTED',
  DELETE_PROJECT = 'DELETE_PROJECT',
  DELETE_PROJECT_FULFILLED = 'DELETE_PROJECT_FULFILLED',
  DELETE_PROJECT_REJECTED = 'DELETE_PROJECT_REJECTED',
  CREATE_PROJECT = 'CREATE_PROJECT',
  CREATE_PROJECT_FULFILLED = 'CREATE_PROJECT_FULFILLED',
  CREATE_PROJECT_REJECTED = 'CREATE_PROJECT_REJECTED',
  EDIT_PROJECT = 'EDIT_PROJECT',
  EDIT_PROJECT_FULFILLED = 'EDIT_PROJECT_FULFILLED',
  EDIT_PROJECT_REJECTED = 'EDIT_PROJECT_REJECTED',
  GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS',
  PROJECT_DETAILS_GET_PENDING = 'PROJECT_DETAILS_GET_PENDING',
  PROJECT_DETAILS_GET_FULFILLED = 'PROJECT_DETAILS_GET_FULFILLED',
  PROJECT_DETAILS_GET_REJECTED = 'PROJECT_DETAILS_GET_REJECTED',
  CLEAR_DETAILS = 'PROJECT/CLEAR_DETAILS',
  SET_LOCAL_NOTIFICATION = 'PROJECT/SET_LOCAL_NOTIFICATION',
  CLEAR_LOCAL_NOTIFICATION = 'PROJECT/CLEAR_LOCAL_NOTIFICATION',
  RESET_API_STATUS = 'PROJECT/RESET_API_STATUS',
  UPDATE_PROJECT_OWNER = 'UPDATE_PROJECT_OWNER',
}

export default ActionTypes;
