import { AxiosError } from 'axios';
import { ForkEffect, put, takeLatest } from 'redux-saga/effects';

import { CLIManifest, CLIManifestAction } from 'Models/cliManifest/types';
import { setNotificationFail } from 'RioRedux/notifications/action';
import { getMessageFromError } from 'Shared/utils/common';
import { createYAMLFileFromObj, downloadBlob } from 'Shared/utils/files';

import CLIManifestActionTypes from './actionTypes';

function* downloadYAML(action: CLIManifestAction<CLIManifest>) {
  try {
    const { payload } = action;
    const blob = createYAMLFileFromObj(payload);
    downloadBlob(blob, `${payload.metadata.name}.yaml`);
  } catch (error) {
    const err = error as AxiosError;

    yield put(
      setNotificationFail({
        message: err?.message,
        description: getMessageFromError(err),
      }),
    );
  }
}

export default function* cliManifestSaga(): IterableIterator<ForkEffect<never>> {
  yield takeLatest(CLIManifestActionTypes.DOWNLOAD_YAML, downloadYAML);
}
