import { AppState, EntityState, User, UserMap } from 'Models/common/types';
import { UserGroup } from 'Models/userGroups/types';

export const selectUserDetails = (state: AppState): User => state.common.userDetails;

export const selectUserMap = (state: AppState): UserMap => state.common.userMap;

export const selectCurrentOrgGUID = (state: AppState): string => state.common.selectedOrgGUID;

export const selectStateInCurrentOrg = (state: AppState): EntityState =>
  state.common.userDetails.userStateInOrgs.find(
    (userStateInOrg) => state.common.selectedOrgGUID === userStateInOrg.organizationGUID,
  )?.userState as EntityState;

export const selectUserGroups = (state: AppState): UserGroup[] => state.organization.userGroups;

export const selectCurrentProjectGUID = (state: AppState): string =>
  state.common.selectedProjectGUID;
