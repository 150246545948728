import { AxiosPromise, AxiosResponse } from 'axios';

import { USER_GROUP_ROOT_URL } from 'Shared/config';
import { authAxiosWithOrganizationHeader } from 'Shared/utils/user';

import {
  mapAPIUserGroupDetailsToUserGroup,
  mapAPIUserGroupsListToUserGroupsList,
} from './dataFormatters';
import {
  APIResponse,
  CreateUserGroupReq,
  UpdateUserGroupReq,
  UserGroup,
  UserGroupRes,
  UserGroupWithProjectRole,
} from './types';

const dataSelector = (response: AxiosResponse) => response.data;

export const createUserGroup = (payload: CreateUserGroupReq): AxiosPromise<UserGroupRes> =>
  authAxiosWithOrganizationHeader({
    method: 'POST',
    url: `${USER_GROUP_ROOT_URL}/create`,
    data: payload,
  }).then(dataSelector);

export const deleteUserGroup = (guid: string): AxiosPromise<APIResponse> =>
  authAxiosWithOrganizationHeader({
    method: 'DELETE',
    url: `${USER_GROUP_ROOT_URL}/delete`,
    data: { guid },
  }).then(dataSelector);

export const leaveUserGroup = (guid: string): AxiosPromise<APIResponse> =>
  authAxiosWithOrganizationHeader({
    method: 'DELETE',
    url: `${USER_GROUP_ROOT_URL}/${guid}/leave`,
  }).then(dataSelector);

export const updateUserGroup = (payload: UpdateUserGroupReq): AxiosPromise<UserGroupRes> =>
  authAxiosWithOrganizationHeader({
    method: 'PUT',
    url: `${USER_GROUP_ROOT_URL}/${payload.guid}/update`,
    data: payload,
  }).then(dataSelector);

export const getUserGroupsList = (): Promise<UserGroup[]> =>
  authAxiosWithOrganizationHeader({
    method: 'GET',
    url: `${USER_GROUP_ROOT_URL}/list`,
  }).then((response) => mapAPIUserGroupsListToUserGroupsList(response.data));

export const getUserGroupDetails = (groupGUID: string): Promise<UserGroupWithProjectRole> =>
  authAxiosWithOrganizationHeader({
    method: 'GET',
    url: `${USER_GROUP_ROOT_URL}/${groupGUID}/get`,
  }).then((response) => mapAPIUserGroupDetailsToUserGroup(response.data));
