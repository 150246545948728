import { APICallStatus, LocalNotificationPayload } from 'Models/common/types';
import {
  Country,
  OrgAction,
  Organization,
  OrgRegistrationStatus,
  ToggleUserStatePayload,
  UpdateProvinceMapPayload,
} from 'Models/organization/types';
import {
  CreateUserGroupData,
  UpdateUserGroupData,
  UserGroup,
  UserGroupWithProjectRole,
} from 'Models/userGroups/types';

import ActionTypes from './actionTypes';

export const getOrgDetails = (payload: string): OrgAction<string> => ({
  type: ActionTypes.GET_DETAILS,
  payload,
});

export const getUserGroups = (): OrgAction => ({
  type: ActionTypes.GET_USERGROUPS_LIST,
});

export const getUserGroupDetails = (payload: string): OrgAction<string> => ({
  type: ActionTypes.GET_USERGROUP_DETAILS,
  payload,
});

export const setUserGroupDetailsAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_USERGROUP_DETAILS_API_STATUS,
  payload,
});

export const createUserGroup = (payload: CreateUserGroupData): OrgAction<CreateUserGroupData> => ({
  type: ActionTypes.CREATE_USERGROUP,
  payload,
});

export const updateUserGroup = (payload: UpdateUserGroupData): OrgAction<UpdateUserGroupData> => ({
  type: ActionTypes.UPDATE_USERGROUP,
  payload,
});

export const deleteUserGroup = (payload: UserGroup): OrgAction<UserGroup> => ({
  type: ActionTypes.DELETE_USERGROUP,
  payload,
});

export const leaveUserGroup = (payload: UserGroup): OrgAction<UserGroup> => ({
  type: ActionTypes.LEAVE_USERGROUP,
  payload,
});

export const setCreateUserGroupAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_CREATE_USERGROUP_API_STATUS,
  payload,
});

export const setUpdateUserGroupAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_UPDATE_USERGROUP_API_STATUS,
  payload,
});

export const setDeleteUserGroupAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_DELETE_USERGROUP_API_STATUS,
  payload,
});

export const setLeaveUserGroupAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_LEAVE_USERGROUP_API_STATUS,
  payload,
});

export const saveOrgDetails = (payload: Organization): OrgAction<Organization> => ({
  type: ActionTypes.UPDATE_DETAILS,
  payload,
});

export const setDetailsLoader = (): OrgAction => ({
  type: ActionTypes.SET_DETAILS_LOADING,
});

export const setDetailsError = (): OrgAction => ({
  type: ActionTypes.SET_DETAILS_ERROR,
});

export const setRegistrationStatus = (
  payload: OrgRegistrationStatus,
): OrgAction<OrgRegistrationStatus> => ({
  type: ActionTypes.SET_REGISTRATION_STATUS,
  payload,
});

export const getCountryList = (): OrgAction => ({
  type: ActionTypes.GET_COUNTRY_LIST,
});

export const setCountryAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_COUNTRY_API_STATUS,
  payload,
});

export const setUserGroupsAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_USERGROUPS_API_STATUS,
  payload,
});

export const updateCountryList = (payload: Country[]): OrgAction<Country[]> => ({
  type: ActionTypes.UPDATE_COUNTRY_LIST,
  payload,
});

export const updateUserGroupsList = (payload: UserGroup[]): OrgAction<UserGroup[]> => ({
  type: ActionTypes.UPDATE_USERGROUPS_LIST,
  payload,
});

export const updateUserGroupDetails = (
  payload: UserGroupWithProjectRole,
): OrgAction<UserGroupWithProjectRole> => ({
  type: ActionTypes.UPDATE_USERGROUP_DETAILS,
  payload,
});

export const getProvinceList = (payload: number): OrgAction<number> => ({
  type: ActionTypes.GET_PROVINCE_LIST,
  payload,
});

export const setProvinceAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_PROVINCE_API_STATUS,
  payload,
});

export const updateProvinceMap = (
  payload: UpdateProvinceMapPayload,
): OrgAction<UpdateProvinceMapPayload> => ({
  type: ActionTypes.UPDATE_PROVINCE_MAP,
  payload,
});

export const register = (payload: Partial<Organization>): OrgAction<Partial<Organization>> => ({
  type: ActionTypes.REGISTER,
  payload,
});

export const setError = (payload: string): OrgAction<string> => ({
  type: ActionTypes.SET_ERROR,
  payload,
});

export const getOrganization = (payload: string): OrgAction<string> => ({
  type: ActionTypes.GET_ORGANIZATION,
  payload,
});

export const inviteUser = (payload: string): OrgAction<string> => ({
  type: ActionTypes.INVITE_USER,
  payload,
});

export const removeUser = (payload: string): OrgAction<string> => ({
  type: ActionTypes.REMOVE_USER,
  payload,
});

export const setInviteUserEmail = (payload: string): OrgAction<string> => ({
  type: ActionTypes.SET_INVITE_USER_EMAIL_INPUT,
  payload,
});

export const clearInviteUserEmail = (): OrgAction => ({
  type: ActionTypes.CLEAR_INVITE_USER_EMAIL_INPUT,
});

export const toggleUserState = (
  payload: ToggleUserStatePayload,
): OrgAction<ToggleUserStatePayload> => ({
  type: ActionTypes.TOGGLE_USER_STATE,
  payload,
});

export const setToggleUserStateAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_TOGGLE_USER_STATE_API_STATUS,
  payload,
});

export const setLocalNotification = (
  payload: LocalNotificationPayload,
): OrgAction<LocalNotificationPayload> => ({
  type: ActionTypes.SET_LOCAL_NOTIFICATION,
  payload,
});

export const clearLocalNotification = (id: string): OrgAction<string> => ({
  type: ActionTypes.CLEAR_LOCAL_NOTIFICATION,
  payload: id,
});
