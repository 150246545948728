import React from 'react';

import { hardLogout } from '../utils/user';

const InitialLoader = (props) => {
  const { error, overrideText, tryAgain } = props;

  return (
    <div id="layout-container">
      <div className="loading flex alignCenter" id="contentsWrapper">
        {error ? (
          <div className="initialLoadingWrapper">
            <div id="centeredErrorBlock">
              <p>
                Error occurred!
                <br />
                {error}. <button onClick={tryAgain}>Try again</button> or{' '}
                <button onClick={hardLogout}>logout</button>
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className="circleLoading" />
            <p className="padding11">{overrideText ?? 'Fetching details...'}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default InitialLoader;
