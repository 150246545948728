import React from 'react';

class ExternalRedirect extends React.Component {
  constructor(props) {
    super(props);
    window.location.href = props.location;
  }

  render() {
    return null;
  }
}

export default ExternalRedirect;
