import { createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { AppAction, AppState } from 'Models/common/types';
import history from 'Shared/utils/history';

import middleware from './middleware';
import reducer from './reducers';
import rootSaga from './rootSaga';

const createHydratedStore = (state: Partial<AppState> = {}): Store<AppState, AppAction> => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer(history), state as any, middleware(history, sagaMiddleware));

  sagaMiddleware.run(rootSaga);

  return store;
};

export default createHydratedStore;
